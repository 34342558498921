import React from 'react';
import { Tooltip } from 'react-tooltip';
import config from '../config';
import formatDate from '../helpers/formatDate';

const CapturesTable = ({
	fields,
	captures,
	qcCat,
	currentCaptureIndex,
	tableHeight,
	autoQCProps,
	toggleAutoQCColourCode,
	removeEntry,
	moveToManual,
	moveToAuto
}) => {
	const getCellStyle = (capture, field, currentCaptureIndex) => {
		let classname = '';

		if (!toggleAutoQCColourCode) {
			if (qcCat === 'smelters' && field.data === 'state') {
				if (capture.state === 0) {
					classname = 'fw-bold inactiveCap';
				} else if (capture.state === 1) {
					classname = 'fw-bold activeCap';
				} else {
					classname = 'fw-bold invalidCap';
				}
			}

			if (qcCat === 'smelters' && field.data === 'autoQCState') {
				if (capture.autoQCState === 0) {
					classname = 'fw-bold inactiveCap';
				} else if (capture.autoQCState === 1) {
					classname = 'fw-bold activeCap';
				} else {
					classname = 'fw-bold invalidCap';
				}
			}

			if (qcCat === 'smelters' && field.data === 'initial_state') {
				if (capture.initial_state === 0) {
					classname = 'fw-bold inactiveCap';
				} else if (capture.initial_state === 1) {
					classname = 'fw-bold activeCap';
				} else {
					classname = 'fw-bold invalidCap';
				}
			}
		}

		if (qcCat === 'stockpiles' && field.data === 'state') {
			if (capture.state === 0) {
				classname = 'fw-bold text-success';
			} else if (capture.state === 1) {
				classname = 'fw-bold text-danger';
			} else if (capture.state === null) {
				classname = '';
			}
		}

		if (field.hasOwnProperty('link')) {
			classname = 'text-decoration-underline';
		}

		if (currentCaptureIndex) {
			classname = `fw-bold ${classname}`;
		}

		if (autoQCProps) {
			if (
				capture.hasOwnProperty('autoQCState') &&
				capture.hasOwnProperty('autoQCReason')
			) {
				// swath rule
				if (
					capture.autoQCReason === 1 &&
					field.data === 'invalid_data_frac' &&
					capture.invalid_data_frac > autoQCProps.invalidPercentageThreshold
				) {
					classname = 'fw-bold';
				}

				// shutdown rule
				if (
					capture.autoQCReason === 2 &&
					(field.data === 'initial_state' || field.data === 'working') &&
					capture.initial_state !== 1
				) {
					classname = 'fw-bold';
				}

				// invalid by band and cloud ratio
				if (
					capture.autoQCReason === 3 &&
					field.data === 'max_band_ratio' &&
					capture.initial_state === -1 &&
					capture.max_band_ratio < autoQCProps.invalidBandRatioThreshold &&
					capture.cloud_frac > autoQCProps.invalidCloudFractionThreshold
				) {
					classname = 'fw-bold';
				}
				if (
					capture.autoQCReason === 3 &&
					field.data === 'cloud_frac' &&
					capture.initial_state === -1 &&
					capture.max_band_ratio < autoQCProps.invalidBandRatioThreshold &&
					capture.cloud_frac > autoQCProps.invalidCloudFractionThreshold
				) {
					classname = 'fw-bold';
				}

				// active by band ratio
				if (
					capture.autoQCReason === 4 &&
					(field.data === 'max_band_ratio' || field.data === 'hotspots') &&
					capture.initial_state === 1 &&
					capture.max_band_ratio > autoQCProps.activeBandsRatioThreshold &&
					capture.hotspots <= capture.hotspot_thresh
				) {
					classname = 'fw-bold';
				}

				// inactive by band and cloud ratio
				if (
					capture.autoQCReason === 5 &&
					field.data === 'max_band_ratio' &&
					capture.initial_state === 0 &&
					capture.max_band_ratio < autoQCProps.inactiveBandRatioThreshold &&
					capture.cloud_frac < autoQCProps.inactiveCloudFractionThreshold
				) {
					classname = 'fw-bold';
				}
				if (
					capture.autoQCReason === 5 &&
					field.data === 'cloud_frac' &&
					capture.initial_state === 0 &&
					capture.max_band_ratio < autoQCProps.inactiveBandRatioThreshold &&
					capture.cloud_frac < autoQCProps.inactiveCloudFractionThreshold
				) {
					classname = 'fw-bold';
				}
			}
		}

		return classname;
	};

	const getRowStyle = (capture) => {
		let classname = '';

		if (autoQCProps && toggleAutoQCColourCode) {
			if (capture.hasOwnProperty('autoQCState') && capture.autoQCState === 1) {
				classname = 'activeCapRow';
			}

			if (capture.hasOwnProperty('autoQCState') && capture.autoQCState === 0) {
				classname = 'inactiveCapRow';
			}
			if (capture.hasOwnProperty('autoQCState') && capture.autoQCState === -1) {
				classname = 'invalidCapRow';
			}
		}

		return classname;
	};

	const getCellContent = (capture, field, currentCaptureIndex) => {
		const fieldData = capture[field.data] ?? '';

		if (qcCat === 'smelters' && field.data === 'state') {
			return config.qcStates.filter(
				(qstate) => qstate.stateInt === capture.state
			)[0].stateString;
		}

		if (qcCat === 'smelters' && field.data === 'initial_state') {
			return config.qcStates.filter(
				(qstate) => qstate.stateInt === capture.initial_state
			)[0].stateString;
		}

		if (qcCat === 'smelters' && capture.hasOwnProperty('autoQCState') && field.data === 'autoQCState') {
			return config.qcStates.filter(
				(qstate) => qstate.stateInt === capture.autoQCState
			)[0].stateString;
		}

		if (qcCat === 'stockpiles' && field.data === 'state') {
			return capture.state === 1
				? 'Accepted'
				: capture.state === 0
				? 'Rejected'
				: '';
		}

		if (field.data === 'qc_state') {
			return capture.qc_state === 3 ? (
				<i class="fa-regular fa-circle-xmark"></i>
			) : capture.qc_state === 4 ? (
				<i class="fa-regular fa-circle-xmark"></i>
			) : (
				<i class="fa-regular fa-circle-check"></i>
			);
		}

		if (
			field.data === 'post_qc' ||
			field.data === 'upload_to_dev' ||
			field.data === 'upload_to_prod'
		) {
			return <i class="fa-regular fa-circle-xmark"></i>;
		}

		if (field.data === 'captured' && field.header === 'Capture Date') {
			try {
				let date = formatDate(capture.captured.slice(0, 10));
				return date;
			} catch {
				return '-';
			}
		}

		// ISSUE HERE
		if (field.data === 'captured' && field.header === 'Capture Time') {
			return capture.captured.slice(11, 16);
		}

		if (
			field.data === 'cloud_frac' &&
			field.header === 'Cloud Fraction' &&
			field.data !== null
		) {
			return Number.isInteger(capture.cloud_frac)
				? capture.cloud_frac
				: capture.cloud_frac.toFixed(3);
		}

		if (
			field.data === 'invalid_data_frac' &&
			field.header === 'Invalid Data Fraction' &&
			capture.invalid_data_frac !== null
		) {
			return capture.invalid_data_frac !== 0
				? capture.invalid_data_frac.toFixed(3)
				: capture.invalid_data_frac;
		}

		if (
			field.data === 'max_band_ratio' &&
			field.header === 'Max Band Ratio' &&
			capture.max_band_ratio !== null
		) {
			return capture.max_band_ratio !== 0
				? capture.max_band_ratio.toFixed(3)
				: capture.max_band_ratio;
		}

		if (field.data === 'qc_user' && capture.hasOwnProperty('autoQCState')) {
			return 'auto qc';
		}

		if (field.Data === null) {
			return '-';
		}

		if (fieldData.length > 15) {
			return fieldData.slice(0, 15) + '...';
		} else {
			return fieldData;
		}

		// AUTO QC

		// }
	};

	const getTitle = (field, capture) => {
		const fieldData = capture[field.data] ?? '';

		if (fieldData.length > 15) {
			return fieldData;
		}
	};

	const getHeaderContent = (field) => {
		if (field.header === 'ID' && field.hasOwnProperty('link')) {
			return (
				<>
					{field.header} <i className="ps-2 fa-solid fa-arrow-pointer"></i>
				</>
			);
		} else {
			return field.header;
		}
	};

	const getCellLink = (field) => {
		if (field.hasOwnProperty('link')) {
			return field.link;
		}
	};

	const getCursor = (field) => {
		if (field.hasOwnProperty('link')) {
			return 'pointer';
		}
	};

	const getModalState = (field) => {
		if (field.hasOwnProperty('link')) {
			return 'modal';
		}
	};

	const getClickableTooltip = (field) => {
		if (field.header === 'ID' && field.hasOwnProperty('link')) {
			return 'clickableTooltip';
		} else {
			return undefined;
		}
	};

	const openThisCapture = (e, idx) => {
		console.log(idx);
	};

	return (
		<>
			<div
				className="table-responsive"
				style={{ maxHeight: tableHeight ? tableHeight : null }}
			>
				<table className="captureTable">
					<thead className="thead-dark">
						<tr>
							<th
								style={{
									width: '30px',
									position: 'sticky',
									top: '0',
									zIndex: '1020'
								}}
							></th>
							
							{fields.map((field, idx) => (
								<th
									style={{
										position: 'sticky',
										top: '0',
										zIndex: '1020'
									}}
									key={idx}
									scope="col"
									data-tooltip-id={getClickableTooltip(field)}
								>
									{getHeaderContent(field)}
								</th>
							))}
							

							<th style={{
									width: '50px',
									position: 'sticky',
									top: '0',
									zIndex: '1020'
								}}></th>
						</tr>
					</thead>
					<tbody className="table-group-divider">
						{captures && captures.length > 0 ? (
							<>
								{captures.map((capture, idx) => (
									<tr
										data-tooltip-id={
											currentCaptureIndex === idx && 'currentCaptureTT'
										}
										key={idx}
										className={getRowStyle(capture)}
									>
										
										<td style={{ width: '30px' }}>
											{currentCaptureIndex === idx && (
												<div className="captureTableCurrentCapDot"></div>
											)}
										</td>
										{fields.map((field, fIdx) => (
											<>
												<td
													className={getCellStyle(capture, field)}
													data-bs-dismiss={getModalState(field)}
													title={getTitle(field, capture)}
													key={fIdx}
													href={getCellLink(field)}
													style={{ cursor: getCursor(field), width: '150px' }}
													onClick={
														field?.link
															? (e) => openThisCapture(e, idx)
															: undefined
													}
												>
													{getCellContent(
														capture,
														field,
														currentCaptureIndex === idx ? true : false
													)}
												</td>
											</>
										))}
										{autoQCProps && (
											<td title="Move capture to manual QC">
												<a onClick={(e) => moveToManual(capture)}><i className="fa-regular fa-circle-up"></i></a>
											</td>
										)}
										{!autoQCProps && (
											<>
											{capture.hasOwnProperty('movedToManual') ? <td title="Move capture to auto QC">
												<a onClick={(e) => moveToAuto(capture)}><i className="fa-regular fa-circle-down"></i></a>
											</td> : <td >
												
											</td>}</>
											
										)}
									</tr>
								))}
							</>
						) : (
							<tr>
								<td style={{ width: '200px' }}>-</td>
								{fields.map((field, fIdx) => (
									<td style={{ width: '200px' }} key={fIdx}>
										-
									</td>
								))}
							</tr>
						)}
					</tbody>
				</table>
				<Tooltip id="currentCaptureTT">Current capture</Tooltip>
				<Tooltip id="clickableTooltip">
					Click on the capture ID to navigate to capture in QC
				</Tooltip>
			</div>
		</>
	);
};

export default CapturesTable;
