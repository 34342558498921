import React, { useEffect, useState, useContext } from 'react';
import config from '../../../config';
import { Row, Col } from 'reactstrap';
import formatDate from '../../../helpers/formatDate';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../../../contexts/pipelineContext';
import exportConfig from '../../../config';

const QCFormDaily = ({
	handleSubmit,
	formValue,
	setFormValue,
	formValid,
	setFormValid,
	loading,
	error,
	setCapturesUrl,
	user
}) => {
	const [todayDate, setTodayDate] = useState();
	const pipelineContextQCFormDaily = useContext(pipelineContext);

	let today = new Date();

	useEffect(() => {
		let today = new Date();
		let dd = new Date(formValue.formEndDate);

		setFormValue({
			...formValue,
			formLookback: 1,
			formEndDateNow: today.toLocaleTimeString(),
			formStartDate: new Date(dd.setDate(dd.getDate() - 1))
				.toISOString()
				.substring(0, 10)
		});
		setTodayDate(today.toISOString().substring(0, 10));
	}, []);

	useEffect(() => {
		validateForm();

		// 08/10/24 LC - this should always be assign_captures, but the stockpiles EP for this is not
		// ready so we have to use the captures EP
		if (pipelineContextQCFormDaily.comm === 'smelters') {
			setCapturesUrl(
				`assign_captures?startDate=${formValue.formStartDate}
				&endDate=${formValue.formEndDate}&sensors=${formValue.formSensors.join(
					','
				)}&commodityIds=${formValue.formCommodities.join(
					','
				)}&prodStates=${formValue.formProdStates.join(
					','
				)}&qcUser=${user.givenName.toLowerCase()}.${user.familyName.toLowerCase()}&qcStates=0&expirationTime=240`
			);
		} else {
			setCapturesUrl(
				`captures?&siteTypeIds=3&startDate=${formValue.formStartDate}&endDate=${
					formValue.formEndDate
				}&sensors=${formValue.formSensors.join(
					','
				)}&commodities=${formValue.formCommodities.join(
					','
				)}&prodStates=${formValue.formProdStates.join(
					','
				)}&qcUser=${user.givenName.toLowerCase()}.${user.familyName.toLowerCase()}`
			);
		}
	}, [formValue]);

	const validateForm = () => {
		if (
			formValue.formCommodities.length > 0 &&
			formValue.formProdStates.length > 0 &&
			formValue.formSensors.length > 0 &&
			!parseInt(formValue.formLookback).isNaN
		) {
			setFormValid(true);
		} else {
			setFormValid(false);
		}
	};

	const calcDate = (e) => {
		let eDate, sDate, sDate2;

		if (e.target.name === 'formLookback') {
			eDate = new Date(formValue.formEndDate);
			sDate = eDate.setDate(eDate.getDate() - e.target.value);
			sDate2 = new Date(sDate).toISOString().substring(0, 10);
			setFormValue({
				...formValue,
				formStartDate: sDate2,
				formLookback: e.target.value
			});
		}

		if (e.target.name === 'formEndDate') {
			eDate = new Date(e.target.value);
			sDate = eDate.setDate(eDate.getDate() - formValue.formLookback);
			console.log(sDate);
			console.log(eDate);
			sDate2 = new Date(sDate).toISOString().substring(0, 10);

			setFormValue({
				...formValue,
				formEndDate: e.target.value,
				formStartDate: sDate2
			});
		}
	};

	const handleOnChange = (event, category) => {
		const { value, checked } = event.target;

		const finalValue = isNaN(parseInt(value)) ? value : parseInt(value);
		console.log(typeof finalValue);
		console.log(finalValue);

		setFormValue((prevFormValue) => {
			const updatedCategory = checked
				? [...prevFormValue[category], finalValue] // Add value if checked
				: prevFormValue[category].filter((item) => item !== finalValue); // Remove value if unchecked

			return {
				...prevFormValue,
				[category]: updatedCategory
			};
		});
	};

	const handleBackButton = (e) => {
		e.preventDefault();
		setFormValue({
			formSiteId: '',
			formStartDate: '',
			formEndDate: new Date().toISOString().substring(0, 10),
			formAutoQC: true,
			formEndDateNow: '',
			fullHistory: true,
			formSensors: ['sentinel-2-l1c', 'landsat-8-l1tp'],
			formCommodities: [3],
			formProdStates: [0, 1],
			formLookback: 1,
			manuallySelectSites: true,
		});

		pipelineContextQCFormDaily.setProgressBar({
			width: 2 * (100 / 7),
			title: 'QC Type'
		});
		pipelineContextQCFormDaily.setQCType(undefined);
	};


	return (
		<>
			<hr></hr>
			<form onSubmit={handleSubmit} className="px-3 mx-3">
				<Row>
					<Col className="d-flex justify-content-start align-items-center">
						<button
							className="btn interactButtonSecondary backButton"
							onClick={handleBackButton}
							style={{ width: '200px' }}
						>
							<i class="fa-solid fa-left-long pe-3"></i>
							Change QC type
						</button>
						<h1 className="ms-5 mt-2">QC</h1>
					</Col>
					<Col></Col>
					<Col className="d-flex flex-column justify-content-center align-items-end">
						{formValid && !loading ? (
							<>
								<button
									type="submit"
									className="btn interactButton mt-2 mx-0"
									style={{ maxHeight: '40px', width: '200px' }}
									data-tooltip-id="view-captures-TT"
								>
									View captures <i class="fa-solid fa-right-long ps-2"></i>
								</button>

								{/* <p className="fst-italic">fetch captures from database and view list</p> */}
							</>
						) : (
							<button
								disabled
								className="btn btn-secondary mt-2 mx-0"
								style={{ maxHeight: '40px', width: '200px' }}
								data-tooltip-id="view-captures-TT"
							>
								View captures <i class="fa-solid fa-right-long ps-2"></i>
							</button>
						)}
					</Col>
				</Row>

				<Tooltip id="view-captures-TT" place="bottom" effect="solid">
					Fetch captures from database and view list before QCing
				</Tooltip>

				<h4 className="py-3">
					Fill in the captures criteria for{' '}
					<span className="fw-bold">daily QC</span>
				</h4>

				<Row className="mt-5">
					<Col className="col-lg-2 col-6">
						<div className="form-group">
							<h5 className="card-title">Commodity</h5>
							<p className="fst-italic">Select one</p>

							<br></br>
							{config.commodities
								.filter(
									(comm) => comm.siteTypeStr === pipelineContextQCFormDaily.comm
								)
								.map((comm, idx) => (
									<>
										<input
											className="form-check-input form-commodity"
											key={idx}
											type="radio"
											id={comm.id}
											name="daily-qc-comms"
											value={comm.index}
											onClick={(e) =>
												setFormValue({
													...formValue,
													formCommodities: [comm.index]
												})
											}
											checked={formValue.formCommodities.includes(comm.index)}
										></input>
										<label className="px-2" for={comm.id}>
											{comm.commodity}
										</label>
										<br></br>
									</>
								))}
						</div>
						<br></br>

						{formValue.formCommodities.length === 0 && (
							<p id="comm-err" className="text-warning">
								<i class="fa-solid fa-triangle-exclamation"></i> Select at least
								one commodity
							</p>
						)}
					</Col>

					<Col className="col-lg-3 col-12">
						<h5 className="card-title">Production State</h5>
						<p className="fst-italic">Select at least one</p>

						<br></br>
						{config.production_states.map(
							({ state, descript, formText }, idx) => (
								<>
									<input
										className="form-check-input form-prod-state"
										type="checkbox"
										id={state}
										name="daily-qc-prods"
										value={state}
										checked={formValue.formProdStates.includes(state)}
										onChange={(e) => handleOnChange(e, 'formProdStates')}
									></input>
									<label
										className="px-2"
										for={state}
										data-tooltip-id={`prodStatToolTip${state}`}
									>
										{`${state} - ${formText}`}
									</label>{' '}
									<Tooltip
										id={`prodStatToolTip${state}`}
										place="top"
										effect="solid"
									>
										{descript}
									</Tooltip>
									<br></br>
								</>
							)
						)}
						{formValue.formProdStates.length === 0 && (
							<p id="prod-state-err" className="text-warning pt-3">
								<i class="fa-solid fa-triangle-exclamation"></i> Select at least
								one production state
							</p>
						)}
					</Col>

					<Col className="col-lg-2 col-6">
						<div className="form-group">
							<h5 className="card-title">Sensor</h5>
							<p className="fst-italic">Select at least one</p> <br></br>
							{exportConfig.sensors.map((sensor, idx) => (
								<div key={idx}>
									<input
										className="form-check-input form-sensor"
										type="checkbox"
										id={sensor}
										name="formSensors"
										value={sensor}
										onChange={(e) => handleOnChange(e, 'formSensors')}
										checked={formValue.formSensors.includes(sensor)}
									></input>
									<label className="px-2" for={sensor}>
										{sensor}
									</label>
								</div>
							))}
							{formValue.formSensors.length === 0 && (
								<p id="sensor-err" className="text-warning pt-3">
									<i class="fa-solid fa-triangle-exclamation"></i> Select at
									least one sensor
								</p>
							)}
						</div>
					</Col>

					<Col className="col-lg-3 col-12">
						<div className="form-group">
							<h5 className="card-title">Interval</h5>
							<p className="fst-italic">
								Adjust lookback days or end data to adjust interval
							</p>{' '}
							<br></br>
							<label for="num-lookback-days">
								Days lookback: <strong>{formValue.formLookback}</strong>
							</label>
							<input
								type="range"
								className="form-range"
								id="lookback-range"
								name="formLookback"
								min={1}
								max={10}
								step={1}
								value={parseInt(formValue.formLookback)}
								onChange={(e) => calcDate(e)}
							></input>
							<Row className="my-3">
								<Col className="col-6 pe-1">
									<label for="start_date">Start date</label> <br></br>
									<input
										className="form-control text-sm"
										id="start_date"
										name="start_date"
										title="start date of interval"
										type="date"
										value={formValue.formStartDate}
										disabled
									></input>
									<br></br>
								</Col>
								<Col className="col-6 ps-1">
									<label for="end_date">End date</label>
									<br></br>
									<input
										className="form-control text-sm"
										id="end_date"
										name="formEndDate"
										title="End date of interval"
										type="date"
										max={todayDate}
										value={formValue.formEndDate}
										onChange={(e) => calcDate(e)}
									></input>
									<br></br>
								</Col>
							</Row>
							<div className="row">
								<h6>Selecting captures between</h6>

								<div className="col-5 d-flex justify-content-evenly">
									<span className="fw-bold">
										{formatDate(formValue.formStartDate)}
									</span>
									<p> at </p>
									<span className="fw-bold"> 00:00</span>
								</div>
								<div className="col-2 d-flex justify-content-center">and </div>
								<div className="col-5 d-flex justify-content-evenly">
									<span className="fw-bold">
										{formatDate(formValue.formEndDate)}
									</span>{' '}
									at{' '}
									<span className="fw-bold">
										{formValue.formEndDateNow.slice(0, 5)}
									</span>
								</div>
							</div>
						</div>

						{/* <div className="form-group mt-5">
							<Row>
								<Col className="d-flex justify-content-end">
									<label
										title="Disabled for now to focus on fully working manual QC pipeline"
										className="px-2 text-decoration-line-through"
										for="set_auto_qc"
									>
										set parameters for Auto QC
									</label>
									<input
										className="form-check-input"
										type="checkbox"
										id="set_auto_qc"
										name="set_auto_qc"
										disabled
										value={formValue.formAutoQC}
										defaultChecked={formValue.formAutoQC}
										onClick={() =>
											setFormValue({
												...formValue,
												formAutoQC: !formValue.formAutoQC
											})
										}
									></input>
								</Col>
							</Row>
						</div> */}
					</Col>

					<Col className="col-lg-2 col-12">
						<div className="form-group">
							<h5 className="card-title">Captures limit</h5>
							<p className="fst-italic">
								Limit number of captures assigned
							</p>{' '}
							<br></br>
							<label for="caps_limit">Count limit</label>
							<input
								type="number"
								value={
									formValue.capsCountLimit !== null
										? formValue.capsCountLimit
										: ''
								}
								className="form-control text-sm"
										id="caps_limit"
										name="formEndDate"
										title="Captures limit"
										min={1}
										max={100}
										
										onChange={(e) => setFormValue({...formValue, capsCountLimit: parseInt(e.target.value)})}
							></input>
						</div>
					</Col>
				</Row>
			</form>
		</>
	);
};

export default QCFormDaily;
