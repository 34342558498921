import React, { useEffect, useState, useContext } from 'react';
import config from '../../../config';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import formatDate from '../../../helpers/formatDate';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../../../contexts/pipelineContext';
import exportConfig from '../../../config';
import Skeleton from 'react-loading-skeleton';

const QCFormUpload = ({
	handleSubmit,
	formValue,
	setFormValue,
	formValid,
	setFormValid,
	loading,
	fetchSites
	// error,
	// setCapturesUrl,
	// user
}) => {
	const [todayDate, setTodayDate] = useState();
	const [siteOptions, setSiteOptions] = useState([]);
	const [selectedCommForSites, setSelectedCommForSites] = useState(null);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const pipelineContextQCFormUpload = useContext(pipelineContext);

	let today = new Date();

	useEffect(() => {
		pipelineContextQCFormUpload.setStraightToUpload(true);
	}, []);

	useEffect(() => {
		if (formValue.manuallySelectSites) {
			console.log("here 1")
			setFormValue(prev => ({
				...prev,
				formSiteId: ''
			}));
		} else {
			console.log("here 2")
			let today = new Date();
			let dd = new Date(formValue.formEndDate);
			setFormValue(prev => ({
				...prev,
				formSiteId: '',
				formLookback: 1,
				formEndDateNow: today.toLocaleTimeString(),
				formStartDate: new Date(dd.setDate(dd.getDate() - 1))
					.toISOString()
					.substring(0, 10)
			}));
	
			setTodayDate(today.toISOString().substring(0, 10));
		}
	}, [formValue.manuallySelectSites]);

	// useEffect(() => {
		
	// 	if (formValue.manuallySelectSites) {
	// 		// historic
	// 		setFormValue({
	// 			...formValue,
	// 			formSiteId: ''
	// 		});
	// 	} else {
	// 		// daily
	// 		let today = new Date();
	// 		let dd = new Date(formValue.formEndDate);
	// 		setFormValue({
	// 			...formValue,
	// 			formSiteId: '',
	// 			formLookback: 1,
	// 			formEndDateNow: today.toLocaleTimeString(),
	// 			formStartDate: new Date(dd.setDate(dd.getDate() - 1))
	// 				.toISOString()
	// 				.substring(0, 10)
	// 		});

	// 		setTodayDate(today.toISOString().substring(0, 10));
	// 	}
	// }, [formValue.manuallySelectSites]);

	useEffect(() => {
		if (pipelineContextQCFormUpload.comm === 'smelters') {
			setSelectedCommForSites(3);
		} else {
			setSelectedCommForSites(4);
		}
	}, [pipelineContextQCFormUpload.comm]);

	const handleOnChange = (event, category) => {
		const { value, checked } = event.target;

		const finalValue = isNaN(parseInt(value)) ? value : parseInt(value);
		console.log(typeof finalValue);
		console.log(finalValue);

		setFormValue((prevFormValue) => {
			const updatedCategory = checked
				? [...prevFormValue[category], finalValue] // Add value if checked
				: prevFormValue[category].filter((item) => item !== finalValue); // Remove value if unchecked

			return {
				...prevFormValue,
				[category]: updatedCategory
			};
		});
	};

	useEffect(() => {
		if (fetchSites?.data) {
			var options;
			options = fetchSites.data.data
				.filter((site) => site.commodity_id === selectedCommForSites)
				.map(({ id, name }) => ({
					value: id,
					label: `${id} - ${name}`
				}))
				.sort((a, b) => a.label.localeCompare(b.label));

			setSelectedOptions([]);
			setSiteOptions(options);
		}
		setFormValue({
			...formValue,
			formCommodities: [selectedCommForSites]
		});
	}, [fetchSites.data, selectedCommForSites]);

	useEffect(() => {
		validateForm();
	}, [formValue]);

	const handleBackButton = (e) => {
		e.preventDefault();
		setFormValue({
			formSiteId: '',
			formStartDate: '',
			formEndDate: new Date().toISOString().substring(0, 10),
			formAutoQC: true,
			formEndDateNow: '',
			fullHistory: true,
			formSensors: ['sentinel-2-l1c', 'landsat-8-l1tp'],
			formCommodities: [3],
			formProdStates: [0, 1],
			manuallySelectSites: true,
			...formValue
		});

		pipelineContextQCFormUpload.setQCType(undefined);
		pipelineContextQCFormUpload.setStraightToUpload(false);

		pipelineContextQCFormUpload.setProgressBar({
			width: 2 * (100 / 7),
			title: 'QC Type'
		});
	};

	const calcDate = (e) => {
		let eDate, sDate, sDate2;

		if (e.target.name === 'formLookback') {
			eDate = new Date(formValue.formEndDate);
			sDate = eDate.setDate(eDate.getDate() - e.target.value);
			sDate2 = new Date(sDate).toISOString().substring(0, 10);
			setFormValue({
				...formValue,
				formStartDate: sDate2,
				formLookback: e.target.value
			});
		}

		if (e.target.name === 'formEndDate') {
			eDate = new Date(e.target.value);
			sDate = eDate.setDate(eDate.getDate() - formValue.formLookback);
			console.log(sDate);
			console.log(eDate);
			sDate2 = new Date(sDate).toISOString().substring(0, 10);

			setFormValue({
				...formValue,
				formEndDate: e.target.value,
				formStartDate: sDate2
			});
		}
	};

	const handleChange = (selectedValues) => {
		console.log(selectedValues);
		//setSelectedOptions(selectedValues);
		setSelectedOptions(selectedValues);
		setFormValue({
			...formValue,
			formSiteId: selectedValues.map((value) => value.value)
		});
	};

	const reloadSites = () => {
		fetchSites.makeRequest(
			`${config.baseUrl}/${pipelineContextQCFormUpload.comm}/sites?hasCaptures=true`,
			'GET',
			null,
			'',
			1000
		);
	};

	const moveStraightToUpload = (event) => {
		if (formValue.formStartDate === '') {
			setFormValue({ ...formValue, formStartDate: '2016-01-01' });
		}

		event.preventDefault();
		pipelineContextQCFormUpload.setProgressBar({
			width: 6 * (100 / 7),
			title: 'Upload Captures'
		});
	};

	const validateForm = () => {
		if (formValue.manuallySelectSites) {
			if (
				formValue.fullHistory &&
				formValue.formEndDate !== '' &&
				Array.isArray(formValue.formSiteId) &&
				formValue.formSiteId.length > 0 &&
				formValue.formEndDate > formValue.formStartDate
			) {
				console.log('here 1');
				setFormValid(true);
			} else if (
				formValue.formEndDate !== '' &&
				formValue.formStartDate !== '' &&
				Array.isArray(formValue.formSiteId) &&
				formValue.formSiteId.length > 0 &&
				formValue.formEndDate > formValue.formStartDate
			) {
				console.log('here 2');
				setFormValid(true);
			} else {
				console.log('here 3');
				setFormValid(false);
			}
		} else {
			if (
				formValue.formCommodities.length > 0 &&
				formValue.formProdStates.length > 0 &&
				formValue.formSensors.length > 0 &&
				!parseInt(formValue.formLookback).isNaN
			) {
				setFormValid(true);
			} else {
				setFormValid(false);
			}
		}
	};

	console.log(formValue);

	return (
		<>
			<hr></hr>
			<form onSubmit={(e) => moveStraightToUpload(e)} className="px-3 mx-3">
				<Row>
					<Col className="d-flex justify-content-start align-items-center">
						<button
							className="btn interactButtonSecondary backButton"
							onClick={handleBackButton}
							style={{ width: '200px' }}
						>
							<i class="fa-solid fa-left-long pe-3"></i>
							Change QC type
						</button>
						<h1 className="ms-5 mt-2">QC</h1>
					</Col>
					<Col></Col>
					<Col className="d-flex flex-column justify-content-center align-items-end">
						{formValid && !loading ? (
							<>
								<button
									type="submit"
									className="btn interactButton mt-2 mx-0"
									style={{ maxHeight: '40px', width: '200px' }}
									data-tooltip-id="move-to-upload-TT"
								>
									Move to Upload <i class="fa-solid fa-right-long ps-2"></i>
								</button>
							</>
						) : (
							<button
								disabled
								className="btn btn-secondary mt-2 mx-0"
								style={{ maxHeight: '40px', width: '200px' }}
								data-tooltip-id="move-to-upload-TT"
							>
								Move to Upload <i class="fa-solid fa-right-long ps-2"></i>
							</button>
						)}
					</Col>
				</Row>

				<Tooltip id="move-to-upload-TT" place="bottom" effect="solid">
					Move straight to upload based on form criteria
				</Tooltip>

				<h4 className="py-3">Fill in the upload criteria</h4>

				<Row className="mt-5">
					<div className="form-group" style={{ width: '90%' }}>
						<h5 className="card-title">Manually choose sites</h5>
						<br />
						{/* <p className="fst-italic">Select one site</p> <br></br> */}
						<div className="form-group">
							<div className="d-flex flex-row align-items-center mb-3">
								<>
									<input
										id="upload-form-manually-select-sites-yes"
										type="radio"
										name="upload-form-manually-select-sites"
										value={true}
										className="form-check-input"
										checked={formValue.manuallySelectSites}
										onClick={(e) =>
											setFormValue({
												...formValue,
												manuallySelectSites: true
											})
										}
									></input>
									<label
										for="upload-form-manually-select-sites-yes"
										className="form-label ms-2 me-5 my-0"
									>
										Yes
									</label>
								</>

								<>
									<input
										id="upload-form-manually-select-sites-no"
										type="radio"
										name="upload-form-manually-select-sites"
										value={false}
										className="form-check-input"
										checked={!formValue.manuallySelectSites}
										onClick={(e) =>
											setFormValue({
												...formValue,
												manuallySelectSites: false
											})
										}
									></input>
									<label
										for="upload-form-manually-select-sites-no"
										className="form-label ms-2 me-4 my-0"
									>
										No
									</label>
								</>
							</div>
						</div>
					</div>
				</Row>

				{formValue.manuallySelectSites ? (
					<Row className="mt-2">
						<div className="col-12 col-lg-6 col-xl-5 py-3 py-md-3 px-2 pe-lg-5">
							<div className="form-group" style={{ width: '90%' }}>
								<h5 className="card-title">Site</h5>
								<p className="fst-italic">Select one site</p> <br></br>
								<div className="form-group">
									<div className="d-flex flex-row align-items-center mb-3">
										{exportConfig.commodities
											.filter(
												(comm) =>
													comm.siteTypeStr === pipelineContextQCFormUpload.comm
											)
											.map((comm, idx) => (
												<>
													<input
														id={`id${comm.index}`}
														type="radio"
														name="historic-form-site-comms-filter"
														key={idx}
														value={comm.index}
														className="form-check-input"
														checked={selectedCommForSites === comm.index}
														onClick={() => setSelectedCommForSites(comm.index)}
													></input>
													<label
														for={`id${comm.index}`}
														className="form-label ms-1 me-4 my-0"
													>
														{comm.id}
													</label>
												</>
											))}
									</div>
								</div>
								{fetchSites.loading ? (
									<Skeleton height={35} />
								) : (
									<>
										{formValue.formSiteId ? (
											<Select
												options={siteOptions}
												value={siteOptions.filter((option) =>
													selectedOptions.includes(option)
												)}
												onChange={handleChange}
												isSearchable
												isMulti // Enable multi-select
												placeholder="Search by site name or id"
											/>
										) : (
											<Select
												options={siteOptions}
												value={selectedOptions}
												onChange={handleChange}
												isSearchable
												isMulti // Enable multi-select
												placeholder="Search by site name or id"
											/>
										)}
									</>

									// <>
									// 	<Select
									// 		options={siteOptions}
									// 		value={
									// 			formValue.formSiteId
									// 				? siteOptions.filter((option) =>
									// 						Array.isArray(formValue.formSiteId)
									// 							? formValue.formSiteId.includes(option.value)
									// 							: option.value === formValue.formSiteId
									// 				  )
									// 				: selectedOptions
									// 		}
									// 		onChange={handleChange}
									// 		isSearchable
									// 		isMulti // Enables multi-select
									// 		placeholder="Search by site name or id"
									// 	/>
									// </>

									// <>
									// 	{formValue.formSiteId ? (
									// 		<Select
									// 			options={siteOptions}
									// 			value={
									// 				siteOptions.filter(
									// 					(option) => option.value === formValue.formSiteId
									// 				)[0]
									// 			}
									// 			onChange={handleChange}
									// 			isSearchable
									// 			placeholder="Search by site name or id"
									// 		/>
									// 	) : (
									// 		<Select
									// 			options={siteOptions}
									// 			value={selectedOptions}
									// 			onChange={handleChange}
									// 			isSearchable
									// 			placeholder="Search by site name or id"
									// 		/>
									// 	)}
									// </>
								)}
								{fetchSites.error && (
									<h6 className=" mt-2">
										<i
											id="sentinel-hub-icon"
											onClick={() => reloadSites()}
											className="me-2 fa-solid fa-arrows-rotate"
											data-tooltip-id="reload-sites-historic-form"
										></i>{' '}
										Could not fetch sites at this time
									</h6>
								)}
								{fetchSites.loading && (
									<h6 className="mt-2">Loading sites ..</h6>
								)}
								<Tooltip
									id="reload-sites-historic-form"
									place="bottom"
									effect="solid"
								>
									Reload sites
								</Tooltip>
							</div>
						</div>
						<div className="col-12 col-lg-6 col-xl-5 py-3 py-md-3 px-2 pe-lg-5">
							<div className="form-group">
								<h5 className="card-title">Interval</h5>
								<p className="fst-italic">Set interval period</p> <br></br>
								<div className="row">
									<div className="d-flex flex-column">
										<div className="d-flex flex-row">
											<input
												id="full-history-true"
												type="radio"
												name="QCFormHistoricFullHistory"
												value={true}
												className="form-check-input"
												defaultChecked={formValue.fullHistory === true}
												onClick={(e) =>
													setFormValue({
														...formValue,
														fullHistory: true,
														formStartDate: ''
													})
												}
											></input>
											<label
												for="full-history-true"
												className="form-label ms-2"
											>
												Full capture history
											</label>
										</div>
										<div className="d-flex flex-row">
											<input
												id="full-history-false"
												type="radio"
												name="QCFormHistoricFullHistory"
												value={false}
												defaultChecked={formValue.fullHistory === false}
												className="form-check-input"
												onClick={(e) =>
													setFormValue({
														...formValue,
														fullHistory: false
													})
												}
											></input>
											<label
												for="full-history-false"
												className="form-label ms-2"
											>
												Choose specific dates
											</label>
										</div>
									</div>
								</div>
								<div className="row mt-5">
									<h6>Selecting captures between</h6>

									<div className="col-5 d-flex justify-content-evenly">
										{formValue.formStartDate ? (
											<span className="fw-bold">
												{formatDate(formValue.formStartDate)}
											</span>
										) : (
											<span className="fw-bold">earliest capture date</span>
										)}

										<p> at </p>
										<span className="fw-bold ms-2"> 00:00</span>
									</div>
									<div className="col-2 d-flex justify-content-center">
										and{' '}
									</div>
									<div className="col-5 d-flex justify-content-evenly">
										<span className="fw-bold">
											{formatDate(formValue.formEndDate)}
										</span>{' '}
										at{' '}
										{formValue.fullHistory ? (
											<span className="fw-bold">
												{new Date().toLocaleTimeString().slice(0, 5)}
											</span>
										) : (
											<span className="fw-bold">00:00</span>
										)}
									</div>
								</div>
								{formValue.fullHistory === false && (
									<Row className="py-2">
										<Col className="col-6 pe-1">
											<label for="start_date">Start date</label> <br></br>
											{formValue.fullHistory ? (
												<input
													className="form-control text-sm"
													id="start_date"
													name="formStartDate"
													title="start date of interval"
													max={new Date().toISOString().split('T')[0]}
													type="date"
													value=""
													disabled
												></input>
											) : (
												<input
													className="form-control text-sm"
													id="start_date"
													name="formStartDate"
													title="start date of interval"
													type="date"
													max={new Date().toISOString().split('T')[0]}
													value={formValue.formStartDate}
													onChange={(e) =>
														setFormValue({
															...formValue,
															formStartDate: e.target.value
														})
													}
												></input>
											)}
											<br></br>
										</Col>
										<Col className="col-6 ps-1">
											<label for="end_date">End date</label> <br></br>
											<input
												className="form-control text-sm"
												id="end_date"
												name="formEndDate"
												title="End date of interval"
												type="date"
												max={new Date().toISOString().split('T')[0]}
												value={formValue.formEndDate}
												onChange={(e) =>
													setFormValue({
														...formValue,
														formEndDate: e.target.value
													})
												}
											></input>
											<br></br>
										</Col>

										{formValue.formStartDate > formValue.formEndDate && (
											<p id="sensor-err" className="text-warning pt-3">
												<i class="fa-solid fa-triangle-exclamation"></i> The
												start date is after the end date
											</p>
										)}
									</Row>
								)}
							</div>
						</div>
						{/* <Col className="col-lg-5 col-12 py-3 py-md-3 px-2 pe-lg-5">
							<div className="form-group">
								<h5 className="card-title">Interval</h5>
								<p className="fst-italic">
									Adjust lookback days or end data to adjust interval
								</p>{' '}
								<br></br>
								

								<label for="num-lookback-days">
									Days lookback: <strong>{formValue.formLookback}</strong>
								</label>
								<input
									type="range"
									className="form-range"
									id="lookback-range"
									name="formLookback"
									min={1}
									max={10}
									step={1}
									value={parseInt(formValue.formLookback)}
									onChange={(e) => calcDate(e)}
								></input>
								<Row className="my-3">
									<Col className="col-6 pe-1">
										<label for="start_date">Start date</label> <br></br>
										<input
											className="form-control text-sm"
											id="start_date"
											name="start_date"
											title="start date of interval"
											type="date"
											value={formValue.formStartDate}
											disabled
										></input>
										<br></br>
									</Col>
									<Col className="col-6 ps-1">
										<label for="end_date">End date</label>
										<br></br>
										<input
											className="form-control text-sm"
											id="end_date"
											name="formEndDate"
											title="End date of interval"
											type="date"
											max={todayDate}
											value={formValue.formEndDate}
											onChange={(e) => calcDate(e)}
										></input>
										<br></br>
									</Col>
								</Row>
								<div className="row">
									<h6>Selecting captures between</h6>

									<div className="col-5 d-flex justify-content-evenly">
										<span className="fw-bold">
											{formatDate(formValue.formStartDate)}
										</span>
										<p> at </p>
										<span className="fw-bold"> 00:00</span>
									</div>
									<div className="col-2 d-flex justify-content-center">
										and{' '}
									</div>
									<div className="col-5 d-flex justify-content-evenly">
										<span className="fw-bold">
											{formatDate(formValue.formEndDate)}
										</span>{' '}
										at{' '}
										<span className="fw-bold">
											{formValue.formEndDateNow.slice(0, 5)}
										</span>
									</div>
								</div>
							</div>
						</Col> */}
					</Row>
				) : (
					<Row className="mt-2">
						<Col className="col-lg-3 col-6">
							<div className="form-group">
								<h5 className="card-title">Commodity</h5>
								<p className="fst-italic">Select one</p>

								<br></br>
								{config.commodities
									.filter(
										(comm) =>
											comm.siteTypeStr === pipelineContextQCFormUpload.comm
									)
									.map((comm, idx) => (
										<>
											<input
												className="form-check-input form-commodity"
												key={idx}
												type="radio"
												id={comm.id}
												name="daily-qc-comms"
												value={comm.index}
												onClick={(e) =>
													setFormValue({
														...formValue,
														formCommodities: [comm.index]
													})
												}
												checked={formValue.formCommodities.includes(comm.index)}
											></input>
											<label className="px-2" for={comm.id}>
												{comm.commodity}
											</label>
											<br></br>
										</>
									))}
							</div>
							<br></br>

							{formValue.formCommodities.length === 0 && (
								<p id="comm-err" className="text-warning">
									<i class="fa-solid fa-triangle-exclamation"></i> Select at
									least one commodity
								</p>
							)}
						</Col>

						<Col className="col-lg-4 col-12">
							<h5 className="card-title">Production State</h5>
							<p className="fst-italic">Select at least one</p>

							<br></br>
							{config.production_states.map(
								({ state, descript, formText }, idx) => (
									<>
										<input
											className="form-check-input form-prod-state"
											type="checkbox"
											id={state}
											name="daily-qc-prods"
											value={state}
											checked={formValue.formProdStates.includes(state)}
											onChange={(e) => handleOnChange(e, 'formProdStates')}
										></input>
										<label
											className="px-2"
											for={state}
											data-tooltip-id={`prodStatToolTip${state}`}
										>
											{`${state} - ${formText}`}
										</label>{' '}
										<Tooltip
											id={`prodStatToolTip${state}`}
											place="top"
											effect="solid"
										>
											{descript}
										</Tooltip>
										<br></br>
									</>
								)
							)}
							{formValue.formProdStates.length === 0 && (
								<p id="prod-state-err" className="text-warning pt-3">
									<i class="fa-solid fa-triangle-exclamation"></i> Select at
									least one production state
								</p>
							)}
						</Col>
						<Col className="col-lg-4 col-12">
							<div className="form-group">
								<h5 className="card-title">Interval</h5>
								<p className="fst-italic">
									Adjust lookback days or end data to adjust interval
								</p>{' '}
								<br></br>
								<label for="num-lookback-days">
									Days lookback: <strong>{formValue.formLookback}</strong>
								</label>
								<input
									type="range"
									className="form-range"
									id="lookback-range"
									name="formLookback"
									min={1}
									max={10}
									step={1}
									value={parseInt(formValue.formLookback)}
									onChange={(e) => calcDate(e)}
								></input>
								<Row className="my-3">
									<Col className="col-6 pe-1">
										<label for="start_date">Start date</label> <br></br>
										<input
											className="form-control text-sm"
											id="start_date"
											name="start_date"
											title="start date of interval"
											type="date"
											value={formValue.formStartDate}
											disabled
										></input>
										<br></br>
									</Col>
									<Col className="col-6 ps-1">
										<label for="end_date">End date</label>
										<br></br>
										<input
											className="form-control text-sm"
											id="end_date"
											name="formEndDate"
											title="End date of interval"
											type="date"
											max={todayDate}
											value={formValue.formEndDate}
											onChange={(e) => calcDate(e)}
										></input>
										<br></br>
									</Col>
								</Row>
								<div className="row">
									<h6>Selecting captures between</h6>

									<div className="col-5 d-flex justify-content-evenly">
										<span className="fw-bold">
											{formatDate(formValue.formStartDate)}
										</span>
										<p> at </p>
										<span className="fw-bold"> 00:00</span>
									</div>
									<div className="col-2 d-flex justify-content-center">
										and{' '}
									</div>
									<div className="col-5 d-flex justify-content-evenly">
										<span className="fw-bold">
											{formatDate(formValue.formEndDate)}
										</span>{' '}
										at{' '}
										<span className="fw-bold">
											{formValue.formEndDateNow.slice(0, 5)}
										</span>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				)}
			</form>
		</>
	);
};

export default QCFormUpload;
