import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'react-tooltip';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Legend,
	registerables
} from 'chart.js';
import { ClipLoader } from 'react-spinners';
import exportConfig from '../../../config';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Legend,
	...registerables
);

const Barplot = ({
	plotData,
	captureDate,
	plotDataError,
	plotDataLoading,
	fetchBarplotData,
	loading,
	imageData
}) => {
	/**
	 * A bar chart with historical data based on user-selected date ranges
	 *
	 * @component
	 *
	 * @param {Object} props - Main props passed from QCPage
	 * @param {Object} props.plotData - Dataset from 'barplot' endpoint
	 * @param {boolean} props.plotDataLoading - Indicates if the barplot endpoint is currently loading
	 * @param {Object} props.plotDataError - Error object if fetching barplot data fails
	 * @param {string} props.captureDate - The captured date of the current capture
	 * @param {Function} props.fetchBarplotData - Function to manually trigger data fetching
	 * @param {Object} props.imageData - Data for the images, used to determine if images have loaded successfully
	 *
	 * @state {Object} data - The main dataset used for rendering the chart.
	 * @state {Object} dataTop - Processed dataset formatted for the chart display.
	 * @state {number} totalDays - Total number of days in the selected date range.
	 * @state {number} startIndex - Start index for slicing data based on user selection.
	 * @state {number} endIndex - End index for slicing data.
	 * @state {Object} xAxisFormat - Configuration for the x-axis formatting.
	 * @state {number} userChosenDateRange - Number of days selected by the user.
	 * @state {string} userChosenEndDate - The selected end date in YYYY-MM-DD format.
	 *
	 * @description
	 * - Manages state for historical data visualization.
	 * - Dynamically updates data when the user selects different date ranges.
	 * - Fetches and processes data to generate a bar chart.
	 * - Handles error states and loading indicators.
	 * - Supports sensor-based annotations for specific datasets.
	 * - Provides interactive UI elements for user customization.
	 *
	 * @returns {JSX.Element} A rendered bar chart component with selectable date ranges.
	 * */

	const [data, setData] = useState(plotData);
	const [dataTop, setDataTop] = useState();
	const [totalDays, setTotalDays] = useState();
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(30);
	const [xAxisFormat, setXAxisFormat] = useState({
		ticks: {
			font: {
				size: 10
			}
		},
		type: 'time',
		time: {
			unit: 'day',
			tooltipFormat: 'dd MMM yy',
			displayFormats: {
				month: 'MMM yy'
			}
		},
		stacked: true,
		grid: {
			display: false
		},
		offset: false,
		barPercentage: 1.0,
		categoryPercentage: 1.0
	});
	const [userChosenDateRange, setUserChosenDateRange] = useState(30);
	const [userChosenEndDate, setUserChosenEndDate] = useState(new Date());

	const dateRanges = [
		{ optionsString: '30days', optionsLabel: 'Last 30 days', num: 30 },
		{ optionsString: '60days', optionsLabel: 'Last 60 days', num: 60 },
		{ optionsString: '90days', optionsLabel: 'Last 90 days', num: 90 }
	];

	useEffect(() => {
		let today = new Date(captureDate);
		let todayString = today.toISOString().split('T')[0];
		setUserChosenEndDate(todayString);
	}, [plotData, captureDate]);

	useEffect(() => {
		setData(plotData);
	}, [plotData]);

	useEffect(() => {
		if (data && userChosenEndDate) {
			try {
				let endDate = new Date(userChosenEndDate);
				let startDate = new Date(endDate);
				startDate.setDate(endDate.getDate() - parseInt(userChosenDateRange));
				let startDateString = startDate.toISOString().split('T')[0];
				let endDateString = endDate.toISOString().split('T')[0];
				let startIndex;

				if (data.dates.indexOf(startDateString) !== -1) {
					startIndex = data.dates.indexOf(startDateString);
				} else {
					startIndex = 0;
				}
				let endIndex = data.dates.indexOf(endDateString);

				setStartIndex(startIndex);
				setEndIndex(endIndex);
				setTotalDays(totalDays);

				let setsOfData = [];

				for (const [key, config] of Object.entries(
					exportConfig.barplotLabelsConfig
				)) {
					if (key in data) {
						setsOfData.push({
							type: 'bar',
							label: key,
							backgroundColor: config.backgroundColor,
							borderColor: config.borderColor,
							borderWidth: 0,
							order: 2,
							data: data[key].slice(startIndex, endIndex)
						});
					}
				}

				if ('sensors' in data) {
					if ('landsat-8-l1tp' in data['sensors']) {
						let date = data['sensors']['landsat-8-l1tp']['captured'].slice(
							0,
							10
						);
						let index = data.dates.indexOf(date);

						if (index > startIndex && index < endIndex) {
							let obj = {
								type: 'scatter',
								label: 'landsat-8-l1tp',
								data: [{ x: date, y: 50 }],
								backgroundColor: 'white',
								borderColor: 'black',
								pointRadius: 5,
								pointHoverRadius: 7,
								order: 1,
								pointStyle: 'rectRot'
							};
							setsOfData.push(obj);
						}
					}

					if ('sentinel-2-l1c' in data['sensors']) {
						let date = data['sensors']['sentinel-2-l1c']['captured'].slice(
							0,
							10
						);
						let index = data.dates.indexOf(date);

						if (index > startIndex && index < endIndex) {
							let obj = {
								type: 'scatter',
								label: 'sentinel-2-l1c',
								data: [{ x: date, y: 70 }],
								backgroundColor: 'white',
								borderColor: 'black',
								pointRadius: 5,
								pointHoverRadius: 7,
								order: 1,
								pointStyle: 'crossRot'
							};
							setsOfData.push(obj);
						}
					}
				}

				setDataTop({
					labels: data.dates.slice(startIndex, endIndex),
					datasets: setsOfData
				});
			} catch (e) {
				setDataTop({
					labels: ['No Data', 'No Data', 'No Data'],
					datasets: [
						{
							label: 'Error: No Data Available',
							data: [null, null, null],
							backgroundColor: 'rgba(200, 200, 200, 0.5)',
							borderColor: 'rgba(200, 200, 200, 1)',
							borderWidth: 1
						}
					]
				});
			}
		}
	}, [data, userChosenDateRange, userChosenEndDate]);

	const optionsTop = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: xAxisFormat,
			y: {
				beginAtZero: true,
				ticks: {
					display: false
				},
				grid: {
					display: false
				}
			}
		},
		plugins: {
			legend: {
				labels: {
					usePointStyle: true
				},
				display: true
			}
		},
		barPercentage: 1.0,
		categoryPercentage: 1.0
	};

	return (
		<>
			{plotDataLoading || loading || (!captureDate && imageData) ? (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '400px' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6">
						Historic Data{' '}
						<button
							style={{
								backgroundColor: '#f8f9fa',
								border: 'none',
								marginLeft: '3px',
								marginTop: '2px'
							}}
						>
							<ClipLoader size={15} color="rgb(98, 152, 64)" />
						</button>
					</legend>

					<div style={{ position: 'relative', width: '100%', height: '100%' }}>
						<Skeleton
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%'
							}}
						/>
					</div>
				</fieldset>
			) : plotDataError ? (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '400px' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6 text-warning">
						Historic Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
							<i
								id="sentinel-hub-icon"
								data-tooltip-id="historic-plot-data"
								onClick={(e) => fetchBarplotData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: '#ffc107' }}
							></i>
						</button>
					</legend>

					<div style={{ position: 'relative', width: '100%', height: '100%' }}>
						<Skeleton
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%'
							}}
						/>
					</div>

					<Tooltip id="historic-plot-data" place="right" effect="solid">
						Historic data not fetched successfully; click to re-fetch
					</Tooltip>
				</fieldset>
			) : plotData && captureDate ? (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '400px' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6">
						Historic Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
							<i
								id="sentinel-hub-icon"
								data-tooltip-id="historic-plot-data"
								onClick={(e) => fetchBarplotData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: 'rgb(98, 152, 64)' }}
							></i>
						</button>
					</legend>

					<div className="row mb-3">
						<div className="col-4">
							<label for="plotDataWindow" className="form-label">
								Date lookback:
							</label>
							<select
								name="plotDataWindow"
								id="cars"
								onChange={(e) => setUserChosenDateRange(e.target.value)}
								className="form-control  ms-1 me-2"
							>
								{dateRanges.map((dateRange, idx) => (
									<option
										key={idx}
										value={dateRange.num}
										selected={userChosenDateRange === dateRange.optionsString}
									>
										{dateRange.optionsLabel}
									</option>
								))}
							</select>
						</div>
						<div className="col-4">
							<label className="form-label" for="plotEndDate">
								End date:
							</label>
							<input
								id="plotEndDate"
								type="date"
								className="form-control  ms-1 me-2"
								value={userChosenEndDate}
								min={'2016-04-01'}
								max={captureDate.slice(0, 10)}
								onChange={(e) => setUserChosenEndDate(e.target.value)}
							></input>
						</div>
					</div>

					<div
						style={{
							position: 'relative',
							height: '100%',

							width: '100%'
						}}
					>
						<div
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%'
							}}
						>
							<div
								style={{
									height: '80%'
								}}
							>
								{dataTop && <Bar data={dataTop} options={optionsTop} />}
							</div>
						</div>
					</div>
				</fieldset>
			) : (
				<fieldset
					className="form-group p-3 border rounded-3"
					style={{ height: '400px' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6 text-warning">
						Historic Data{' '}
						<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
							<i
								id="sentinel-hub-icon"
								data-tooltip-id="historic-plot-data"
								onClick={(e) => fetchBarplotData()}
								className="fa-solid fa-arrows-rotate ms-1 fa-sm"
								style={{ color: '#ffc107' }}
							></i>
						</button>
					</legend>

					<div style={{ position: 'relative', width: '100%', height: '100%' }}>
						<Skeleton
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%'
							}}
						/>
					</div>

					<Tooltip id="historic-plot-data" place="right" effect="solid">
						Historic data not fetched successfully; click to re-fetch
					</Tooltip>
				</fieldset>
			)}
		</>
	);
};

export default Barplot;
