const sortCapsBySiteIDThenDate = (listOfCaps) => {
    console.log(listOfCaps)
    return listOfCaps.sort((a, b) => {
        // order by site_id
        if (a.site_id < b.site_id) return -1;
        if (a.site_id > b.site_id) return 1;

        // then by capture date
        return new Date(a.captured) - new Date(b.captured);
    });
}



const helperFunctions = {
    sortCapsBySiteIDThenDate
}

export default helperFunctions