import React, { Component, Fragment, useState, useRef, useEffect } from 'react';
import {
	Alert,
	Row,
	Card,
	CardBody,
	CardHeader,
	Col,
	Form,
	FormGroup,
	FormText,
	Input,
	InputGroup,
	Label,
	Button
} from 'reactstrap';
import { Auth, Storage } from 'aws-amplify';
import earthiLogo from '../../images/earthi.svg';
import gainsLogo from '../../images/gains-logo-dark.svg';
import esaLogo from '../../images/esa.png';
//import { cognitoError } from '../components/cognitoError';
//import DocumentTitle from 'react-document-title';
import LoaderButton from '../LoaderButton';
//import { isSelectorValid } from '../utils';
//import BodyClassName from 'react-body-classname';
import QRCodeSVG from 'qrcode.react';
import { ReactSVG } from 'react-svg';
import { Navigate } from 'react-router-dom';
import { getAuthenticatedUserAttributes } from '../../actions/authUtils';
import exportConfig from '../../config';

export default function Login(props) {
	// page state
	const [isLoading, setIsLoading] = useState(false);
	const [isAutoFill, setIsAutoFill] = useState(false);
	const [isVerifying, setIsVerifying] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [copied, showCopied] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [dropdown, setDropdown] = useState(false);

	// user
	const [user, setUser] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	// mfa
	const [challengeName, setChallengeName] = useState('');
	const [mfaCode, setMfaCode] = useState('');
	const [challengeCode, setChallengeCode] = useState('');

	const [storedUser, setStoredUser] = useState();

	const usernameRef = useRef('');
	const passwordRef = useRef('');

	useEffect(() => {
		document.title = 'GAINS | Login';

		setStoredUser(localStorage.getItem('authenticatedUser'));

		//props.setIsAuthenticating(true)
	}, []);

	const handleChange = (event) => {
		const newIsAutofill = isAutoFill ? !isAutoFill : isAutoFill;

		setErrorMsg('');
		setIsAutoFill(newIsAutofill);

		if (event.target.id === 'email') {
			setEmail(event.target.value);
		} else if (event.target.id === 'password') {
			setPassword(event.target.value);
		}
	};

	const handleSignIn = async (event) => {
		event.preventDefault();
		setIsLoading(true);

		try {
			let userRole = '';

			// here is where it fails, which suggests an Auth issue
			const user = await Auth.signIn(email, password);

			// DEV
			// props.userHasAuthenticated(true, []);
			// <Navigate to="/home" replace={true} />;
			// DEV

			let authUser = '';

			if (!user.challengeName) {
				console.log('1');
				authUser = await Auth.currentAuthenticatedUser();

				userRole = authUser.attributes['custom:userRole'];
				setIsLoading(false);
				setErrorMsg(
					'Access is denied to the Admin Site for this user as MFA is required.'
				);
			} else {
				console.log('2');
				// setUser(user);
				// props.userHasAuthenticated(true, []);

				//user has already set up MFA
				if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
					setChallengeName(user.challengeName);
					setUser(user);
					setIsLoading(false);

					console.log('3');

					// const files = await Storage.list()
					// console.log(files)

					//user is admin and has not set up MFA yet
				} else if (userRole === 'admin' || userRole === 'editor') {
					console.log('4');
					const code = await Auth.setupTOTP(user);

					setMfaCode(code);
					setUser(user);
					setIsLoading(false);

					//log in nrmally if user is not an admin
				} else {
					console.log('here 5');
					props.userHasAuthenticated(true, []);
					<Navigate to="/" replace={true} />;
					//this.props.history.push('');
				}
			}
		} catch (err) {
			console.log('6');
			setIsLoading(false);
			console.log(err);
			setErrorMsg('Could not access Cognito User Pool at this time.');
		}
	};

	const validateForm = () => {
		return email.length > 0 && password.length > 0;
	};

	const renderError = () => {
		if (errorMsg)
			return (
				<Alert color="warning" className="py-2 px-3">
					{errorMsg}
				</Alert>
			);
	};

	const renderSignInForm = () => {
		// const { mfaCode, challengeName } = this.state;

		if (!mfaCode && !challengeName) {
			return (
				<Fragment>
					<Form onSubmit={handleSignIn}>
						<FormGroup>
							<Label for="email">Email</Label>
							<Input
								id="email"
								placeholder="Enter email address"
								autoFocus
								value={email}
								onChange={handleChange}
								innerRef={usernameRef}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="password">Password</Label>
							<InputGroup>
								<Input
									id="password"
									type={showPassword ? 'text' : 'password'}
									placeholder="Enter password"
									value={password}
									onChange={handleChange}
									innerRef={passwordRef}
								/>

								<Button outline color="secondary" onClick={toggleShowPassword}>
									<i
										className={
											showPassword ? 'fa fa-eye fa-lg' : 'fa fa-eye-slash fa-lg'
										}
									/>
									<span className="sr-only">Toggle password display</span>
								</Button>
							</InputGroup>
						</FormGroup>
						{renderError()}
						<LoaderButton
							block
							color="primary"
							disabled={!isAutoFill && !validateForm()}
							type="submit"
							isLoading={isLoading}
							text="Log in"
							loadingText="Logging in…"
						/>
					</Form>
					<div className="text-center mt-3 login-card">
						<Fragment>
							<a
								className="small"
								target="_blank"
								rel="noopener"
								href="https://navigate.gains.earthi.world/signUp"
							>
								Sign up
							</a>
							<span className="text-muted"> | </span>
						</Fragment>

						<a
							className="small"
							href="https://navigate.gains.earthi.world/forgotPassword"
							target="_blank"
							rel="noopener"
						>
							Forgot password
						</a>
					</div>
				</Fragment>
			);
		}
	};

	const toggleShowPassword = () => setShowPassword(!showPassword);
	//this.setState({ showPassword: !this.state.showPassword });

	const handleMfaVerification = async (event) => {
		//this function fill be called only if mfaCode or challengeName==='SOFTWARE_TOKEN_MFA' is set
		//console.log("handleMFAVerification")
		event.preventDefault();
		//const { user, mfaCode, challengeName, challengeCode } = this.state;

		setIsVerifying(true);

		try {
			if (mfaCode) {
				await Auth.verifyTotpToken(user, challengeCode);
				await Auth.setPreferredMFA(user, 'TOTP');

				// const res = Auth.confirmSignIn(user, challengeCode, 'SOFTWARE_TOKEN_MFA');
			} else if (challengeName === 'SOFTWARE_TOKEN_MFA') {
				await Auth.confirmSignIn(user, challengeCode, 'SOFTWARE_TOKEN_MFA');
			}

			let userRole =
				user['signInUserSession']['idToken']['payload']['custom:userRole'];

			if (userRole === 'admin' || userRole === 'editor') {
				//console.log(userRole);

				const userAttributesAuth = await getAuthenticatedUserAttributes();
				//console.log(userAttributesAuth);
				if (userAttributesAuth) {
					//console.log('user attr');
					props.setUserAttributes({
						givenName: userAttributesAuth.givenName,
						familyName: userAttributesAuth.familyName,
						email: userAttributesAuth.email,
						initials: userAttributesAuth.initials
					});
				}

				props.userHasAuthenticated(true, [], userAttributesAuth.email);
			} else {
				setIsLoading(false);
				setIsVerifying(false);
				setErrorMsg('Access is denied to the Admin Site for this user.');
			}
		} catch (err) {
			console.log(err);
			setIsVerifying(false);
			setErrorMsg('Code mismatch: Invalid MFA code received for user. ');
		}
	};

	const renderVerifyMfaForm = () => {
		//const { mfaCode, challengeName, challengeCode } = this.state;

		if (mfaCode || challengeName === 'SOFTWARE_TOKEN_MFA') {
			return (
				<Form onSubmit={handleMfaVerification}>
					{mfaCode && (
						<FormGroup row>
							<Col xs={5}>
								<FormText color="muted" className="mb-1">
									Scan QR code using Authenticator App
								</FormText>
								<div className="mt-2">
									<QRCodeSVG
										//fgColor={colours.savantSmelterCold}
										value={`otpauth://totp/AWSCognito:${user.username}?secret=${mfaCode}&issuer=AWSCognito`}
									/>
								</div>
							</Col>
							<Col xs={7}>
								<FormText color="muted" className="mb-1 ">
									Or enter this code into your Authenticator device
								</FormText>
								<div className="d-flex h-75">
									<Input
										type="textarea"
										name="mfaCode"
										id="mfaCode"
										bsSize="xs"
										value={mfaCode}
										readOnly
										className="text-monospace mt-1 mb-2 small"
										style={{ resize: 'none' }}
									/>

									{/* {!this.state.copied && (
												<CopyToClipboard
													text={mfaCode}
													onCopy={() => this.setState({ copied: true })}
												>
													<div className="ml-2 align-self-center">
														<i className="fa fa-clone" />
													</div>
												</CopyToClipboard>
											)} */}
									{this.state.copied && (
										<div className="ml-2 align-self-center text-success">
											<i className="fa fa-check" />
										</div>
									)}
								</div>
							</Col>
						</FormGroup>
					)}
					<FormGroup row>
						<Col xs={12}>
							<Label for="totp">
								Enter the 6-digit code from your Authenticator:
							</Label>
							<Input
								id="totp"
								placeholder="Authentication code"
								autoFocus
								value={challengeCode}
								onChange={
									(e) => setChallengeCode(e.target.value)
									// this.setState({ challengeCode: e.target.value })
								}
							/>
						</Col>
					</FormGroup>
					{renderError()}
					<LoaderButton
						block
						color="primary"
						disabled={challengeCode.length !== 6}
						type="submit"
						isLoading={isVerifying}
						text="Verify"
						loadingText="Verifying…"
					/>
					{/* <Mailto
								email="GAINSsupport@earthi.co.uk"
								subject="Lost MFA device"
								body="Please reset my MFA."
							>
								Lost MFA device?
							</Mailto> */}
					{/* {userHasAuthenticated && <Navigate to="/home" state={userHasAuthenticated} replace={true} />} */}
				</Form>
			);
		}
	};

	return (
		<>
			{/* {props.isAuthenticated && <Navigate to="/" replace />} */}

			{!props.isAuthenticating &&
			!props.isAuthenticated &&
			storedUser === undefined ? (
				<></>
			) : !props.isAuthenticating && !props.isAuthenticated ? (
				<div
					className="container"
					style={{ width: '450px', marginTop: '80px' }}
				>
					<Card className="card-login mx-auto mt-5">
						<CardHeader className="card-logo-header">
							<Row className="justify-content-start p-2">
								<Col>
									<ReactSVG
										src={earthiLogo}
										className="card-logo"
										//svgClassName="earthi-card-logo"
									/>
								</Col>
								<Col>
									<ReactSVG
										src={gainsLogo}
										className="card-logo"
										//svgClassName="gains-card-logo"
									/>
								</Col>
								<Col></Col>
							</Row>

							<h1 className="sr-only">GAINS</h1>
						</CardHeader>
						<CardBody>
							<p
								className="my-2 py-0 text-center text-primary fw-bold"
								style={{ position: 'relative', bottom: '0' }}
								data-tooltip-id="environment"
							>
								{' '}
								{exportConfig.gainsEnvironment.toUpperCase()}
							</p>
							{renderSignInForm()}
							{renderVerifyMfaForm()}

							<hr />
							<div className="mt-3 small">
								In association with:
								<br />
								<a
									href="https://www.esa.int/ESA"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={esaLogo}
										className="card-logo esa-card-logo pull-left"
										alt="European Space Agency"
									/>
								</a>
								<a
									href="https://navigatecommodities.com/"
									target="_blank"
									rel="noopener noreferrer"
								>
									{/* <img
										src={navigateLogo}
										className="card-logo navigate-card-logo pull-right"
										alt="Navigate Commodities"
									/> */}
								</a>
								{/* <a
									href="http://www.marexspectron.com/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={marexSpectronLogo}
										className="card-logo marex-card-logo pull-right"
										alt="Marex Spectron"
									/>
								</a> */}
							</div>
						</CardBody>
					</Card>

					<Card className="card-login mx-auto mt-2">
						<CardBody>
							<div
								className="d-flex justify-content-between"
								data-bs-toggle="collapse"
								href="#collapseExample"
								role="button"
								aria-expanded="false"
								aria-controls="collapseExample"
								onClick={(e) => setDropdown(!dropdown)}
							>
								<div className="smaller">
									<strong>System access disclaimer:</strong> This is a
									restricted computer system which may be accessed and used only
									by authorised personnel.
								</div>
								<div>
									{!dropdown && <i className="fa-solid fa-caret-down"></i>}
									{dropdown && <i className="fa-solid fa-caret-up"></i>}
								</div>
							</div>

							<div className="collapse" id="collapseExample">
								<br></br>
								<div className="smaller">
									UNAUTHORISED ACCESS OR USE OF THIS COMPUTER SYSTEM IS STRICTLY
									PROHIBITED AND CONSTITUTES AN OFFENCE UNDER THE COMPUTER
									MISUSE ACT 1990 and may subject violators to criminal, civil,
									and/or administrative action.
								</div>
								<br></br>
								<div className="smaller">
									All access and use of this computer system is monitored and
									audited by authorised personnel, therefore there should not be
									any expectations of Right to Privacy on this computer system.
								</div>
								<br></br>
								<div className="smaller">
									All activity is subject to being intercepted, recorded, read,
									copied and disclosed by and to authorised personnel for
									official purposes, including criminal investigations. Access
									or use of this computer system by any person, whether
									authorised or unauthorised, constitutes consent to these
									terms.
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
			) : (
				<Navigate to="/" />
			)}
		</>
	);
}
