import { useState, useCallback } from 'react';
import toast from 'react-hot-toast';

export const conditionalToastPromise = (promise, msjString, method) => {
	if (msjString) {
		return toast.promise(
			promise,
			{
				loading:
					method === 'GET'
						? `Fetching ${msjString}...`
						: `Posting ${msjString}...`,
				success:
					method === 'GET'
						? `${
								msjString.charAt(0).toUpperCase() + msjString.slice(1)
						  } fetched successfully!`
						: `${
								msjString.charAt(0).toUpperCase() + msjString.slice(1)
						  } posted successfully!`,
				error: (err) =>
					method === 'GET'
						? `${err.message} failed to fetch ${msjString}`
						: `${err.message} failed to post ${msjString}`
			},
			{
				position: 'top-right',
				style: {
					minWidth: '250px',
					textAlign: 'left',
					background: '#333',
					color: '#fff'
				}
			}
		);
	}
	return promise;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function useGainsQCApi() {
	/**
	 * Makes an asynchronous HTTP request with built-in loading, error handling,
	 * and timeout functionality
	 *
	 * @param {string} url - The API endpoint to fetch data from.
	 * @param {string} method - The HTTP method (e.g., "GET", "POST", "PUT", "DELETE").
	 * @param {Object|null} [body=null] - The request payload (JSON object), if applicable.
	 * @param {string} msjString - A message string used for notifications.
	 * @param {number} fakeDelay - Simulated delay (in milliseconds) before updating state.
	 * @param {number} [timeout=180000] - Timeout duration in milliseconds (default: 3 minutes).
	 *
	 * @returns {Promise<void>} - The function does not return data directly; it updates state variables.
	 *
	 * @throws {Error} - Throws an error if the request fails, times out, or encounters network issues.
	 *
	 * @async
	 */

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const makeRequest = useCallback(
		async (
			url,
			method,
			body = null,
			msjString,
			fakeDelay,
			timeout = 180000
		) => {
			setLoading(true);
			setError(null);

			const options = {
				method,
				headers: {
					accept: 'application/json'
				},
				body: body ? JSON.stringify(body) : null
			};
			const controller = new AbortController();
			const { signal } = controller;
			const updatedOptions = { ...options, signal };

			// creates a promise that rejects with a timeout error and aborts the request if it exceeds the specified duration
			const timeoutPromise = new Promise((_, reject) => {
				setTimeout(() => {
					controller.abort();
					reject(new Error('Request timed out'));
				}, timeout);
			});

			// creates a promise that fetches data from the given URL, handles errors for failed responses,
			// applies a fake delay, and updates the state with the fetched data
			const fetchPromise = fetch(url, updatedOptions)
				.then((response) => {
					if (!response.ok) {
						console.log(response)
						setLoading(false);

						const error = new Error(
							`Error ${response.status}: ${response.statusText}`
						);
						error.status = response.status;
						error.statusText = response.statusText;
						error.url = url;
						throw error;
					}

					return response.json();
				})
				.then(async (data) => {
					await delay(fakeDelay);
					setData(data);
					setLoading(false);
				});

			
			// creates a promise that resolves or rejects based on whichever completes first: 
			// the fetch request or the timeout, then triggers a notification
			const racePromise = Promise.race([fetchPromise, timeoutPromise]);
			conditionalToastPromise(racePromise, msjString, method);

			try {
				await racePromise;
			} catch (err) {
				console.log(err);
				console.log(typeof err);

				if (err.name === 'AbortError') {
					setError({
						message: 'Request was aborted due to timeout',
						status: 'Timeout',
						url: url
					});
				} else if (
					err.name === 'TypeError' &&
					err.message.includes('Failed to fetch')
				) {
					setError({
						message: 'Failed to fetch.',
						status: 'Type error',
						url: url
					});
				} else {
					setError({
						message: err.message || 'An unknown error occurred',
						status: err.status || 'Unknown error',
						url: url
					});
				}

				setLoading(false);
			}
		},
		[]
	);

	const resetData = useCallback(() => {
		setData(null);
	}, []);

	const overrideData = (newData) => {
		setData(newData);
	};

	return { data, loading, error, makeRequest, resetData, overrideData };
}

export default useGainsQCApi;
