import React from 'react';
import { Tooltip } from 'react-tooltip';

export const DailyQCComplete = ({dashboardInfo}) => {
	return (
		<>
			<div class="dashboard-card">
				<div
					class="dashboard-card-header"
					data-tooltip-id="dashboard-daily-qc-completed-info"
				>
					<div className="row">
						<div className="col">
							<i className="fa-solid fa-calendar-day me-2"></i> Daily QC Checklist
						</div>
						<div className="col d-flex justify-content-end">
						<i className="fa-solid fa-wrench"></i>
					</div>
					</div>
				</div>
				<div className="dashboard-card-body">
					<table style={{ width: '100%' }}>
						<tbody>
							<tr>
								<td>Pre-QC</td>
								<td className="text-center">
									{dashboardInfo && dashboardInfo.dailyQC.preQCDate ? <i className="fa-regular fa-circle-check"></i> : '-'}
									
								</td>
								<td className="text-end">{dashboardInfo && dashboardInfo.dailyQC.preQCDate}</td>
							</tr>
							<tr>
								<td>QC</td>
								<td className="text-center">
									<i className="fa-regular fa-circle-check"></i>
								</td>
								<td className="text-end"></td>
							</tr>
							<tr>
								<td>Post-QC</td>
								<td className="text-center">-</td>
								<td className="text-end"></td>
							</tr>
							<tr>
								<td>Upload</td>
								<td className="text-center">-</td>
								<td className="text-end"></td>
							</tr>
							<tr>
								<td>Data check</td>
								<td className="text-center">-</td>
								<td className="text-end"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<Tooltip
				id="dashboard-daily-qc-completed-info"
				place="bottom"
				effect="solid"
			></Tooltip>
		</>
	);
};
