import React from 'react';
import { smeltersPostQCFields } from './QC/tableFields';
import { SkeletonTable } from './SkeletonTable';
import EmptyTable from './EmptyTable';

const PostQCDataTable = ({ tablesData, loading, error }) => {
	console.log(tablesData);
	console.log(loading);
	console.log(error);

	if (tablesData) {
		return (
			<>
				{tablesData.map((site2, idx) => (
					<div
						key={idx}
						className="table-responsive my-2"
						style={{
							maxWidth: '100%',
							maxHeight: '500px',
							overflowY: 'auto'
						}}
					>
						<h5 className="mt-3">
							Site ID: {site2.length > 0 && site2[0].site_id}
						</h5>
						<table className="table">
							<thead className="table-light">
								<tr>
									{smeltersPostQCFields
										.map((item) => item.header)
										.map((key) => (
											<th>{key}</th>
										))}
								</tr>
							</thead>

							<tbody className="table-group-divider">
								{site2.map((site) => (
									<tr
										className={
											site.hasOwnProperty('filled') && site.filled === 1
												? 'table-active'
												: ''
										}
									>
										<td>{site.hasOwnProperty('id') ? site.id : null}</td>
										<td>
											{site.hasOwnProperty('captured') ? site.captured : null}
										</td>
										<td>
											{site.hasOwnProperty('site_id') ? site.site_id : null}
										</td>
										<td>
											{site.hasOwnProperty('sensor') ? site.sensor : null}
										</td>
										<td>{site.hasOwnProperty('state') ? site.state : null}</td>
										<td>
											{site.hasOwnProperty('change') ? site.change : null}
										</td>
										<td>
											{site.hasOwnProperty('cloud_frac') &&
											typeof site.cloud_frac === 'number'
												? site.cloud_frac.toFixed(4)
												: null}
										</td>
										<td>
											{site.hasOwnProperty('raw_index_score')
												? site.raw_index_score
												: null}
										</td>
										<td>
											{site.hasOwnProperty('normalized_raw_index_score_6') &&
											typeof site.normalized_raw_index_score_6 === 'number'
												? site.normalized_raw_index_score_6.toFixed(4)
												: null}
										</td>
										<td>
											{site.hasOwnProperty('normalized_raw_index_score_7') &&
											typeof site.normalized_raw_index_score_7 === 'number'
												? site.normalized_raw_index_score_7.toFixed(4)
												: null}
										</td>
										<td>
											{site.hasOwnProperty('normalized_raw_index_score_8') &&
											typeof site.normalized_raw_index_score_8 === 'number'
												? site.normalized_raw_index_score_8.toFixed(4)
												: null}
										</td>
										<td>
											{site.hasOwnProperty('normalized_raw_index_score_9') &&
											typeof site.normalized_raw_index_score_9 === 'number'
												? site.normalized_raw_index_score_9.toFixed(4)
												: null}
										</td>
										<td>
											{site.hasOwnProperty('dispersion_index')
												? site.dispersion_index
												: null}
										</td>
										<td>
											{site.hasOwnProperty('working') ? site.working : null}
										</td>
										<td>
											{site.hasOwnProperty('filled') ? site.filled : null}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				))}
			</>
		);
	}
	if (loading) {
		return (
			<SkeletonTable
				numRows={10}
				cols={smeltersPostQCFields.map((item) => item.header)}
				height={450}
			/>
		);
	}
	if (error) {
		return (
			<EmptyTable
				numRows={10}
				cols={smeltersPostQCFields.map((item) => item.header)}
				height={450}
			/>
		);
	}
};

export default PostQCDataTable;
