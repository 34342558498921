import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import config from '../../../config';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import divideReturnQCList from '../../../helpers/divideReturnQCList';
import { pipelineContext } from '../../../contexts/pipelineContext';
import useGainsQCApi from '../../../services/useGainsQCApi';
import CapturesTable from '../../CapturesTable';
import {
	stockpileFields,
	smelterFieldsTechDetail,
	smelterFieldsTechDetailNoAuto
} from '../tableFields';
import exportConfig from '../../../config';
import helperFunctions from '../../../helpers/generalHelperFunctions';

const QCCaptures = ({
	formValue,
	moveToQC,
	fetchCapturesError,
	fetchCapturesLoading,
	user,
	autoQCProps,
	setAutoQCProps,
	filterAutoQCCaps,
	keepCaps,
	setAutoQCPayload,
	sendCapsToAutoQC,
	sendBackCapsList,
	setSendBackCapsList
}) => {
	const [loading, setLoading] = useState(true);
	const [manualCapturesTableIsCollapsed, setManualCapturesTableIsCollapse] =
		useState(true);
	const [autoCapturesTableIsCollapsed, setAutoCapturesTableIsCollapse] =
		useState(false);
	const [toggleAutoQCColourCode, setToggleAutoQCColourCode] = useState(false);

	const {
		error: errorSendingCapsBack,
		loading: sendCapsBackLoading,
		makeRequest: sendCapsBack
	} = useGainsQCApi();

	const {
		capsInPipeline,
		setCapsInPipeline,
		autoQCCaps,
		setAutoQCCaps,
		comm,
		setProgressBar,
		setQCInProgress,
		straightToQC,
		qcType,
		notificationsEnabled
	} = useContext(pipelineContext);

	useEffect(() => {
		if (!fetchCapturesLoading) {
			if (capsInPipeline.length > 0) {
				capsInPipeline.forEach((obj) => {
					obj.capturedDate = obj.captured.slice(0, 10);
					obj.capturedTime = obj.captured.slice(11, 19);
				});
			}
			if (autoQCCaps.length > 0) {
				autoQCCaps.forEach((obj) => {
					obj.capturedDate = obj.captured.slice(0, 10);
					obj.capturedTime = obj.captured.slice(11, 19);
				});
			}
			setLoading(false);

			// if limit was set, send back rest of caps here
			if (sendBackCapsList.length > 0) {
				sendingCapsBackFunction();
			}
		}
	}, [fetchCapturesLoading, capsInPipeline]);

	const sendingCapsBackFunction = async () => {
		await sendCapsBack(
			`${exportConfig.baseUrl}/${comm}/remove_from_queue`,
			'POST',
			{
				commodityIds: `${formValue.formCommodities.join(',')}`,
				qcUser: user,
				captureIds: sendBackCapsList.join(',')
			},
			notificationsEnabled ? 'sending caps back' : null
		);

		setSendBackCapsList([]);
	};

	useEffect(() => {
		let newCaps = keepCaps;
		let [x, y] = filterAutoQCCaps(newCaps);
		setCapsInPipeline(x);
		setAutoQCCaps(y);
	}, [autoQCProps]);

	const resetCapsAndGoBackToForms = async () => {
		if (capsInPipeline.length > 0 || autoQCCaps.length > 0) {
			let caps = capsInPipeline.map((cap) => cap.id);
			let capsAuto = autoQCCaps.map((cap) => cap.id);

			let sendCaps = [...caps, ...capsAuto, ...sendBackCapsList];
			console.log('Sending caps back', sendCaps);

			if (comm === 'smelters') {
				await sendCapsBack(
					`${exportConfig.baseUrl}/${comm}/remove_from_queue`,
					'POST',
					{
						commodityIds: `${formValue.formCommodities.join(',')}`,
						qcUser: user,
						captureIds: sendCaps.join(',')
					},
					notificationsEnabled ? 'sending caps back' : null
				);
			} else {
				const urls = divideReturnQCList(capsInPipeline);
				for (let i in urls) {
					sendCapsBack(
						`${exportConfig.baseUrl}/${comm}/reset_captures?table=${exportConfig.tableNames[comm]}&captureIds=${urls[i]}`,
						'POST',
						null
					);
				}
			}
		}

		if (!errorSendingCapsBack) {
			setCapsInPipeline([]);
			setAutoQCCaps([]);
			setAutoQCPayload({});
			setProgressBar({
				width: 3 * (100 / 7),
				title: qcType
			});
			setQCInProgress(false);
			//setLoading(false);
		}
	};

	const movingToQC = () => {
		moveToQC(capsInPipeline, autoQCCaps);
	};

	const renderSkeletonTable = () => {
		return (
			<div className="table-responsive" style={{ maxHeight: '400px' }}>
				<table className="table">
					<thead className="table-light">
						<tr>
							<th scope="col">ID</th>
							<th scope="col">Capture Date</th>
							<th scope="col">Capture Time</th>
							<th scope="col">Site</th>
							<th scope="col">Sensor</th>
							<th scope="col">Site ID</th>
							<th scope="col">Capacity</th>
							<th scope="col">Initial State</th>
							<th scope="col">State</th>
							<th scope="col">Hotspots</th>
							<th scope="col">Invalid Frac</th>
							<th scope="col">Valid Data Frac</th>
							<th scope="col">Max Band Ratio</th>
						</tr>
					</thead>
					<tbody className="table-group-divider">
						{Array.from({ length: 4 }, (_, rowIndex) => (
							<tr key={rowIndex}>
								{Array.from({ length: 13 }, (_, rowIndex) => (
									<td>
										<Skeleton />
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	const moveToManual = (capture) => {
		capture.movedToManual = true
		let newManualCapsSorted = helperFunctions.sortCapsBySiteIDThenDate([...capsInPipeline, capture])
		let newAutoCapsSorted = helperFunctions.sortCapsBySiteIDThenDate(autoQCCaps.filter((cap) => cap.id !== capture.id))

		setCapsInPipeline(newManualCapsSorted)
		setAutoQCCaps(newAutoCapsSorted);
	};

	const moveToAuto = (capture) => {
		delete capture.movedToManual

		let newManualCapsSorted = helperFunctions.sortCapsBySiteIDThenDate(capsInPipeline.filter((cap) => cap.id !== capture.id))
		let newAutoCapsSorted = helperFunctions.sortCapsBySiteIDThenDate([...autoQCCaps, capture])

		setCapsInPipeline(newManualCapsSorted)
		setAutoQCCaps(newAutoCapsSorted);
	}

	if (loading) {
		return (
			<div>
				<hr></hr>

				<div className="px-3 mx-3">
					<div className="row">
						<Col className="col-6 my-2 my-md-0 col-md-3 order-1 order-md-6 d-flex justify-content-start">
							<div className="d-flex flex-row align-items-center">
								<button
									className={
										capsInPipeline.length > 0
											? 'btn interactButtonSecondary'
											: 'btn interactButton'
									}
									style={{ maxHeight: '40px', width: '200px' }}
									onClick={(e) => resetCapsAndGoBackToForms(qcType)}
									data-tooltip-id={
										!fetchCapturesError ? 'regress-to-form-reset-caps' : ''
									}
								>
									<i class="fa-solid fa-left-long pe-3"></i>
									Back to form
								</button>
								<h1 className="ms-5 mt-2">QC</h1>
							</div>
						</Col>

						<Tooltip
							id="regress-to-form-reset-caps"
							place="bottom"
							effect="solid"
						>
							Navigate back to the form
						</Tooltip>

						<Col className="col-6 my-2 my-md-0 col-md-3 order-2 order-md-5 offset-md-6 d-flex justify-content-end align-items-center">
							<button
								data-tooltip-id="autoQCCaptureTooltip"
								className="btn interactButton"
								style={{ maxHeight: '40px', width: '200px' }}
								disabled
								onClick={(e) => moveToQC(capsInPipeline, autoQCCaps)}
							>
								QC captures
								<i class="fa-solid fa-right-long ps-3"></i>
							</button>
						</Col>
					</div>

					<h4 className="py-1 py-md-3">Loading captures ..</h4>

					<div className="row">
						<Col className="col-6 my-2 my-md-0 col-md-3 order-3 offset-md-3 order-md-1 text-center">
							{capsInPipeline.length > 0 ? (
								<h1>{capsInPipeline.length}</h1>
							) : (
								<h1>
									<Skeleton width={40} />
								</h1>
							)}
							{/* <h1>{captures.length}</h1> */}

							<p>captures to QC</p>
						</Col>
						<Col className="col-6 my-2 my-md-0 col-md-3 order-4 order-md-2 text-center">
							{autoQCCaps.length > 0 ? (
								<h1>{autoQCCaps.length}</h1>
							) : (
								<h1>
									<Skeleton width={40} />
								</h1>
							)}
							<p>captures eligible for Auto QC</p>
						</Col>
					</div>

					<>
						<div className="row mt-4">
							{capsInPipeline.length > 0 ? (
								<h4 className="text-secondary">0 captures to QC</h4>
							) : (
								<Col className="d-flex justify-content-start">
									<h4 className="text-secondary">
										<Skeleton width={50} />
									</h4>
								</Col>
							)}

							{renderSkeletonTable()}
						</div>

						<div className="row py-4">
							{autoQCCaps.length > 0 ? (
								<h4 className="text-secondary">0 captures to Auto QC</h4>
							) : (
								<Col className="d-flex justify-content-start">
									<h4 className="text-secondary">
										<Skeleton width={50} />
									</h4>
								</Col>
							)}
							{/* <h4 className="text-secondary">0 captures to QC</h4> */}
							{renderSkeletonTable()}
						</div>
					</>
				</div>
			</div>
		);
	}

	return (
		<div>
			<hr></hr>
			<div className="px-3 mx-3">
				<div className="row">
					<div className="col-6 my-2 my-md-0 col-md-3 order-1 order-md-6 d-flex justify-content-start">
						{straightToQC || sendCapsBackLoading ? (
							<div className="d-flex flex-row align-items-center">
								<button
									className="btn interactButtonSecondary"
									style={{ maxHeight: '40px', width: '200px' }}
									disabled
								>
									<i class="fa-solid fa-left-long pe-3"></i>
									Back to form
								</button>
								<h1 className="ms-5 mt-2">QC</h1>
							</div>
						) : (
							<div className="d-flex flex-row align-items-center">
								<button
									className="btn interactButtonSecondary"
									style={{ maxHeight: '40px', width: '200px' }}
									onClick={(e) => resetCapsAndGoBackToForms(qcType)}
									data-tooltip-id={
										!fetchCapturesError && 'regress-to-form-reset-caps'
									}
								>
									<i class="fa-solid fa-left-long pe-3"></i>
									Back to form
								</button>
								<h1 className="ms-5 mt-2">QC</h1>
							</div>
						)}
					</div>

					<Tooltip
						id="regress-to-form-reset-caps"
						place="bottom"
						effect="solid"
					>
						Send back to the database the {capsInPipeline.length} captures
						assigned to you and navigate back to the form
					</Tooltip>

					{fetchCapturesError ? (
						<div className="col-6 my-2 my-md-0 order-3 order-md-1 text-center">
							<h5 className="text-center text-warning mt-4">
								<i className="fa-solid fa-triangle-exclamation me-2"></i> Error
								fetching captures! Please try again later.
							</h5>
						</div>
					) : sendCapsToAutoQC.error ? (
						<div className="col-6 my-2 my-md-0 order-3 order-md-1 text-center">
							<h5 className="text-center text-warning mt-4">
								<i className="fa-solid fa-triangle-exclamation me-2"></i> Error
								sending captures to Auto QC! Please try again later.
							</h5>
						</div>
					) : (
						<>
							<div className="col-6 my-2 my-md-0 col-md-3 order-2 order-md-5 offset-md-6 d-flex justify-content-end align-items-center">
								{capsInPipeline.length > 0 && (
									<>
										<button
											data-tooltip-id="autoQCCaptureTooltip"
											className="btn interactButton"
											style={{ maxHeight: '40px', width: '200px' }}
											//onClick={(e) => moveToQC(captures, autoCaptures)}
											onClick={movingToQC}
										>
											QC {capsInPipeline.length} captures{' '}
											<i className="fa-solid fa-right-long ps-1 ps-md-3"></i>
										</button>

										<Tooltip
											id="autoQCCaptureTooltip"
											place="bottom"
											effect="solid"
										>
											and send {autoQCCaps.length} captures to auto QC
										</Tooltip>
									</>
								)}

								{autoQCCaps.length === keepCaps.length &&
									keepCaps.length !== 0 && (
										<>
											<button
												data-tooltip-id="autoQCCaptureTooltip"
												className="btn interactButton"
												style={{ maxHeight: '40px', width: '200px' }}
												//onClick={(e) => moveToQC(captures, autoCaptures)}
												onClick={movingToQC}
											>
												Auto QC {autoQCCaps.length} caps{' '}
												<i className="fa-solid fa-right-long ps-1 ps-md-3"></i>
											</button>

											<Tooltip
												id="autoQCCaptureTooltip"
												place="bottom"
												effect="solid"
											>
												and move directly to upload
											</Tooltip>
										</>
									)}

								{keepCaps.length === 0 && (
									<>
										<button
											data-tooltip-id="autoQCCaptureTooltip"
											className="btn interactButtonSecondary"
											style={{ maxHeight: '40px', width: '200px' }}
											//onClick={(e) => moveToQC(captures, autoCaptures)}
											//onClick={movingToQC}
											disabled
										>
											No captures to QC
										</button>

										<Tooltip
											id="autoQCCaptureTooltip"
											place="bottom"
											effect="solid"
										>
											and move directly to upload
										</Tooltip>
									</>
								)}
							</div>
						</>
					)}
				</div>

				<h4 className="py-1 py-md-3">
					{capsInPipeline.length > 0 && 'Review the captures to QC'}
				</h4>

				<div className="row mt-2">
					<div className="col-6 my-2 my-md-0 col-md-3 offset-md-3 order-3 order-md-1 text-center">
						<h1>{capsInPipeline.length}</h1>
						<p>captures to QC</p>
					</div>
					<div className="col-6 my-2 my-md-0 col-md-3 order-4 order-md-2 text-center">
						<h1>{autoQCCaps.length}</h1>
						<p>captures eligible for Auto QC</p>
						<div className="d-flex justify-content-center">
							{keepCaps.length !== 0 && !loading && !fetchCapturesError && (
								<div className="form-check form-switch">
									<input
										className="form-check-input"
										data-tooltip-id="autoQCToggleTT"
										type="checkbox"
										id="autoQCToggle"
										checked={autoQCProps.autoQCToggle}
										onChange={(e) =>
											setAutoQCProps((prevState) => ({
												...prevState,
												autoQCToggle: !prevState.autoQCToggle
											}))
										}
									></input>
									<label className="form-check-label" for="autoQCToggle">
										Auto QC
									</label>
								</div>
							)}
						</div>
					</div>
				</div>

				{capsInPipeline.length === 0 &&
					autoQCCaps.length === 0 &&
					!loading &&
					!fetchCapturesError && (
						<Row>
							<h5 className="text-center mt-4">
								There are no captures which meet these criteria.
							</h5>
						</Row>
					)}

				{capsInPipeline.length === 0 && autoQCCaps.length === 0 ? (
					<></>
				) : (
					<div className="mt-1 p-1">
						<div
							className={`collapse ${autoQCProps.autoQCToggle ? 'show' : ''}`}
							id="setAutoQCCollapse"
						>
							<Row>
								<Col></Col>
								<Col className="col-6">
									<form>
										<fieldset className="form-group p-3 border rounded-3 d-flex justify-content-center">
											<legend className="float-none w-auto px-3">
												Set Auto QC parameters{' '}
												<i
													className="fa fa-refresh ms-3 fa-sm"
													id="autoQCRefreshIcon"
													data-tooltip-id="refreshAutoQCProps"
													onClick={(e) =>
														setAutoQCProps({
															autoQCToggle: true,
															outOfSwathToggle: false,
															inactiveAutoQCToggle: false,
															inactiveInvalidShutdownToggle: true,
															invalidPercentageThreshold: 0.15,
															invalidCloudFractionThreshold: 0.95,
															invalidBandRatioThreshold: 1,
															activeBandsRatioThreshold: 3,
															inactiveCloudFractionThreshold: 0.05,
															inactiveBandRatioThreshold: 1,
															hotspotsThresh: 4
														})
													}
												></i>
											</legend>

											<div className="">
												<div className="table" id="autoQCCriteriaTable">
													<table>
														{/* TOGGLES */}

														<tr>
															<td data-tooltip-id="autoQCSwathToggleTT">
																{/* out of swath toggle */}

																<div className="d-flex flex-column align-items-center text-center">
																	<div className="form-check form-switch d-flex justify-content-center">
																		<input
																			className="form-check-input px-0"
																			type="checkbox"
																			role="switch"
																			id="autoQCSwathToggle"
																			checked={autoQCProps.outOfSwathToggle}
																			onChange={(e) =>
																				setAutoQCProps((prevState) => ({
																					...prevState,
																					outOfSwathToggle:
																						!prevState.outOfSwathToggle
																				}))
																			}
																		/>
																	</div>
																	<label
																		className="form-check-label mt-1"
																		htmlFor="autoQCSwathToggle"
																	>
																		Out of swath
																	</label>

																	<Tooltip
																		id="autoQCSwathToggleTT"
																		place="bottom"
																		effect="solid"
																		style={{ textAlign: 'left' }}
																	>
																		If <span className="fw-bold">ON</span>, set
																		captures with{' '}
																		<span className="fst-italic">
																			invalid data fraction
																		</span>{' '}
																		(or{' '}
																		<span className="fst-italic">
																			min site area
																		</span>
																		) more than{' '}
																		{autoQCProps.invalidPercentageThreshold} to
																		state{' '}
																		<span className="fw-bold">invalid</span>
																		<br />
																		(Swath rule)
																	</Tooltip>
																</div>
															</td>
															<td data-tooltip-id="invalidInactiveShutdownToggleTT">
																<div className="d-flex flex-column align-items-center text-center">
																	<div className="form-check form-switch d-flex justify-content-center">
																		<input
																			className="form-check-input px-0"
																			type="checkbox"
																			role="switch"
																			id="invalidInactiveShutdownToggle"
																			checked={
																				autoQCProps.inactiveInvalidShutdownToggle
																			}
																			onChange={(e) =>
																				setAutoQCProps((prevState) => ({
																					...prevState,
																					inactiveInvalidShutdownToggle:
																						!prevState.inactiveInvalidShutdownToggle
																				}))
																			}
																		></input>
																	</div>
																	<label
																		className="form-check-label mt-1"
																		for="invalidInactiveShutdownToggle"
																	>
																		Shutdown sites
																	</label>

																	<Tooltip
																		id="invalidInactiveShutdownToggleTT"
																		place="bottom"
																		effect="solid"
																		style={{ textAlign: 'left' }}
																	>
																		If <span className="fw-bold">ON</span>, set
																		captures with an{' '}
																		<span className="fst-italic">
																			initial state
																		</span>{' '}
																		of{' '}
																		<span className="fst-italic">inactive</span>{' '}
																		or{' '}
																		<span className="fst-italic">invalid</span>{' '}
																		to their initial state
																		<br />
																		(Shutdown rule)
																	</Tooltip>
																</div>
															</td>
															<td data-tooltip-id="invalidAutoQCToggleToggleTT">
																<div className="d-flex flex-column align-items-center text-center">
																	<div className="form-check form-switch d-flex justify-content-center">
																		<input
																			className="form-check-input px-0"
																			type="checkbox"
																			id="invalidAutoQCToggle"
																			checked={autoQCProps.invalidAutoQCToggle}
																			onChange={(e) =>
																				setAutoQCProps((prevState) => ({
																					...prevState,
																					invalidAutoQCToggle:
																						!prevState.invalidAutoQCToggle
																				}))
																			}
																		></input>
																	</div>
																	<label
																		className="form-check-label"
																		for="invalidAutoQCToggle"
																	>
																		Invalid Auto QC
																	</label>
																	<Tooltip
																		id="invalidAutoQCToggleToggleTT"
																		place="bottom"
																		effect="solid"
																		style={{ textAlign: 'left' }}
																	>
																		If <span className="fw-bold">ON</span>, set{' '}
																		<span className="fst-italic">invalid</span>{' '}
																		captures with a{' '}
																		<span className="fst-italic">
																			max band ratio
																		</span>{' '}
																		less than{' '}
																		<span className="fst-italic">
																			{autoQCProps.invalidBandRatioThreshold}
																		</span>{' '}
																		and a{' '}
																		<span className="fst-italic">
																			cloud fraction
																		</span>{' '}
																		more than{' '}
																		<span className="fst-italic">
																			{
																				autoQCProps.invalidCloudFractionThreshold
																			}
																		</span>{' '}
																		to their initial state
																		<br />
																		(Invalid by band and cloud ratio rule)
																	</Tooltip>
																</div>
															</td>
															<td data-tooltip-id="activeAutoQCToggleToggleTT">
																<div className="d-flex flex-column align-items-center text-center">
																	<div className="form-check form-switch d-flex justify-content-center">
																		<input
																			className="form-check-input px-0"
																			type="checkbox"
																			role="switch"
																			id="activeAutoQCToggle"
																			checked={autoQCProps.activeAutoQCToggle}
																			onChange={(e) =>
																				setAutoQCProps((prevState) => ({
																					...prevState,
																					activeAutoQCToggle:
																						!prevState.activeAutoQCToggle
																				}))
																			}
																		></input>
																	</div>
																	<label
																		className="form-check-label"
																		for="activeAutoQCToggle"
																	>
																		Active Auto QC
																	</label>
																	<Tooltip
																		id="activeAutoQCToggleToggleTT"
																		place="bottom"
																		effect="solid"
																		style={{ textAlign: 'left' }}
																	>
																		If <span className="fw-bold">ON</span>, set{' '}
																		<span className="fst-italic">active</span>{' '}
																		captures with a{' '}
																		<span className="fst-italic">
																			max band ratio
																		</span>{' '}
																		more than{' '}
																		<span className="fst-italic">
																			{autoQCProps.activeBandsRatioThreshold}
																		</span>{' '}
																		and number of hotspots less than its{' '}
																		<span className="fst-italic">
																			hotspots threshold
																		</span>{' '}
																		to their initial state
																		<br />
																		(Active by band ratio)
																	</Tooltip>
																</div>
															</td>
															<td data-tooltip-id="inactiveAutoQCToggleToggleTT">
																<div className="d-flex flex-column align-items-center text-center">
																	<div className="form-check form-switch d-flex justify-content-center">
																		<input
																			className="form-check-input px-0"
																			type="checkbox"
																			id="inactiveAutoQCToggle"
																			checked={autoQCProps.inactiveAutoQCToggle}
																			onChange={(e) =>
																				setAutoQCProps((prevState) => ({
																					...prevState,
																					inactiveAutoQCToggle:
																						!prevState.inactiveAutoQCToggle
																				}))
																			}
																		></input>
																	</div>
																	<label
																		className="form-check-label"
																		for="inactiveAutoQCToggle"
																	>
																		Inactive Auto QC
																	</label>
																	<Tooltip
																		id="inactiveAutoQCToggleToggleTT"
																		place="bottom"
																		effect="solid"
																		style={{ textAlign: 'left' }}
																	>
																		If <span className="fw-bold">ON</span>, set{' '}
																		<span className="fst-italic">inactive</span>{' '}
																		captures with a{' '}
																		<span className="fst-italic">
																			max band ratio
																		</span>{' '}
																		less than{' '}
																		<span className="fst-italic">
																			{autoQCProps.inactiveBandRatioThreshold}
																		</span>{' '}
																		and a{' '}
																		<span className="fst-italic">
																			cloud fraction
																		</span>{' '}
																		less than{' '}
																		<span className="fst-italic">
																			{
																				autoQCProps.inactiveCloudFractionThreshold
																			}
																		</span>{' '}
																		to their initial state
																		<br />
																		(Inactive by band and cloud ratios)
																	</Tooltip>
																</div>{' '}
															</td>
														</tr>

														<tr>
															<td>
																<div className="d-flex flex-column align-items-center text-center">
																	<div className=" d-flex justify-content-center">
																		<input
																			id="autoQCInvalidPercThreshold"
																			type="number"
																			style={{ width: '100px' }}
																			className="form-control text-center"
																			min={0}
																			max={1}
																			step={0.01}
																			value={
																				autoQCProps.invalidPercentageThreshold
																			}
																			disabled={!autoQCProps.outOfSwathToggle}
																			onChange={(e) =>
																				setAutoQCProps({
																					...autoQCProps,
																					invalidPercentageThreshold:
																						parseFloat(e.target.value)
																				})
																			}
																		></input>
																	</div>
																	<label
																		className={
																			autoQCProps.outOfSwathToggle
																				? `form-check-label`
																				: `form-check-label text-secondary`
																		}
																		for="autoQCInvalidPercThreshold"
																	>
																		Min site area %
																	</label>
																</div>
															</td>
															<td></td>
															<td>
																<div className="d-flex flex-column align-items-center text-center">
																	<div className=" d-flex justify-content-center">
																		<input
																			id="invalidBandRatio"
																			type="number"
																			style={{ width: '100px' }}
																			className="form-control text-center"
																			min={0}
																			max={10000}
																			step={0.01}
																			disabled={
																				!autoQCProps.invalidAutoQCToggle
																			}
																			value={
																				autoQCProps.invalidBandRatioThreshold
																			}
																			onChange={(e) =>
																				setAutoQCProps({
																					...autoQCProps,
																					invalidBandRatioThreshold: parseFloat(
																						e.target.value
																					)
																				})
																			}
																		></input>
																	</div>
																	<label
																		className={
																			autoQCProps.invalidAutoQCToggle
																				? `form-check-label`
																				: `form-check-label text-secondary`
																		}
																		for="invalidBandRatio"
																	>
																		Max band ratio
																	</label>
																</div>
															</td>
															<td>
																<div className="d-flex flex-column align-items-center text-center">
																	<div className=" d-flex justify-content-center">
																		<input
																			id="activeBandsRatioThreshold"
																			type="number"
																			style={{ width: '100px' }}
																			className="form-control text-center"
																			min={0}
																			max={10000}
																			step={0.01}
																			disabled={!autoQCProps.activeAutoQCToggle}
																			value={
																				autoQCProps.activeBandsRatioThreshold
																			}
																			onChange={(e) =>
																				setAutoQCProps({
																					...autoQCProps,
																					activeBandsRatioThreshold: parseFloat(
																						e.target.value
																					)
																				})
																			}
																		></input>
																	</div>{' '}
																	<label
																		className={
																			autoQCProps.activeAutoQCToggle
																				? `form-check-label`
																				: `form-check-label text-secondary`
																		}
																		for="activeBandsRatioThreshold"
																	>
																		Min band ratio
																	</label>
																</div>
															</td>
															<td>
																<div className="d-flex flex-column align-items-center text-center">
																	<div className=" d-flex justify-content-center">
																		<input
																			id="inactiveBandRatioThreshold"
																			type="number"
																			style={{ width: '100px' }}
																			className="form-control text-center"
																			disabled={
																				!autoQCProps.inactiveAutoQCToggle
																			}
																			min={0}
																			max={1}
																			step={0.01}
																			value={
																				autoQCProps.inactiveBandRatioThreshold
																			}
																			onChange={(e) =>
																				setAutoQCProps({
																					...autoQCProps,
																					inactiveBandRatioThreshold:
																						parseFloat(e.target.value)
																				})
																			}
																		></input>
																	</div>
																	<label
																		className={
																			autoQCProps.inactiveAutoQCToggle
																				? `form-check-label`
																				: `form-check-label text-secondary`
																		}
																		for="inactiveBandRatioThreshold"
																	>
																		Max band ratio
																	</label>
																</div>
															</td>
														</tr>

														<tr>
															<td></td>
															<td></td>
															<td>
																<div className="d-flex flex-column align-items-center text-center">
																	<div className=" d-flex justify-content-center">
																		<input
																			id="invalidCloudFraction"
																			type="number"
																			style={{ width: '100px' }}
																			className="form-control text-center"
																			min={0}
																			max={1}
																			step={0.01}
																			disabled={
																				!autoQCProps.invalidAutoQCToggle
																			}
																			value={
																				autoQCProps.invalidCloudFractionThreshold
																			}
																			onChange={(e) =>
																				setAutoQCProps({
																					...autoQCProps,
																					invalidCloudFractionThreshold:
																						parseFloat(e.target.value)
																				})
																			}
																		></input>
																	</div>
																	<label
																		className={
																			autoQCProps.invalidAutoQCToggle
																				? `form-check-label`
																				: `form-check-label text-secondary`
																		}
																		for="invalidCloudFraction"
																		data-tooltip-id="invalidCloudFraction"
																	>
																		Min cloud coverage %
																	</label>
																</div>
															</td>
															<td>
																{/* <div className="d-flex flex-column align-items-center text-center">
																	<div className=" d-flex justify-content-center">
																		<input
																			id="activeHotSpotsThresh"
																			type="number"
																			style={{ width: '100px' }}
																			className="form-control text-center"
																			min={0}
																			max={100}
																			step={1}
																			disabled={
																				!autoQCProps.activeAutoQCToggle
																			}
																			value={
																				autoQCProps.hotspotsThresh
																			}
																			onChange={(e) =>
																				setAutoQCProps({
																					...autoQCProps,
																					hotspotsThresh:
																						parseInt(e.target.value)
																				})
																			}
																		></input>
																	</div>
																	<label
																		className={
																			autoQCProps.hotspotsThresh
																				? `form-check-label`
																				: `form-check-label text-secondary`
																		}
																		for="activeHotSpotsThresh"
																		data-tooltip-id="invalidCloudFraction"
																	>
																		Hotspots Threshold
																	</label>
																</div> */}
															</td>
															<td>
																<div className="d-flex flex-column align-items-center text-center">
																	<div className=" d-flex justify-content-center">
																		<input
																			id="inactiveCloudFractionThreshold"
																			type="number"
																			style={{ width: '100px' }}
																			className="form-control text-center"
																			min={0}
																			max={1}
																			step={0.01}
																			disabled={
																				!autoQCProps.inactiveAutoQCToggle
																			}
																			value={
																				autoQCProps.inactiveCloudFractionThreshold
																			}
																			onChange={(e) =>
																				setAutoQCProps({
																					...autoQCProps,
																					inactiveCloudFractionThreshold:
																						parseFloat(e.target.value)
																				})
																			}
																		></input>
																	</div>
																	<label
																		className={
																			autoQCProps.inactiveAutoQCToggle
																				? `form-check-label`
																				: `form-check-label text-secondary`
																		}
																		for="inactiveCloudFractionThreshold"
																	>
																		Max cloud ratio
																	</label>
																</div>
															</td>
														</tr>
													</table>
												</div>

												<Tooltip
													id="refreshAutoQCProps"
													place="bottom"
													effect="solid"
												>
													Reset default Auto QC criteria
												</Tooltip>
											</div>
										</fieldset>
									</form>
								</Col>
								<Col></Col>
							</Row>
						</div>
					</div>
				)}

				<div className="collapse" id="collapseFormValues">
					<Row>
						<Col></Col>
						<Col className="col-8">
							<form>
								<fieldset className="form-group p-3 border rounded-3">
									<legend className="float-none w-auto px-3">
										Captures criteria
									</legend>

									{/* INTERVAL */}
									<Row
										className="d-flex justify-content-start
							"
									>
										<Col className="col-3">
											<label
												htmlFor="captures-criteria-s_date"
												className="form-check-label"
											>
												Interval
											</label>
										</Col>
										<Col className="col-1 d-flex justify-content-start">
											<label
												htmlFor="captures-criteria-s_date"
												className="form-check-label"
											>
												from
											</label>
										</Col>
										<Col className="col-3 d-flex justify-content-start">
											<input
												type="date"
												disabled
												id="captures-criteria-s_date"
												value={formValue.formStartDate}
												className="form-control"
											></input>
										</Col>
										<Col className="col-1 d-flex justify-content-start">
											<label
												htmlFor="captures-criteria-e_date"
												className="form-check-label"
											>
												to
											</label>
										</Col>
										<Col className="col-3 d-flex justify-content-start">
											<input
												type="date"
												disabled
												id="captures-criteria-e_date"
												value={formValue.formEndDate}
												className="form-control"
											></input>
										</Col>
									</Row>

									{/* SENSORS */}
									<Row className="py-2">
										<Col className="col-3">
											<label htmlFor="customRange1" className="form-label">
												Sensors
											</label>
										</Col>
										<Col className="col-3 d-flex justify-content-start">
											<label className="form-check-label pe-3">
												landsat-8-l1tp
											</label>
											{formValue.formSensors.includes('landsat-8-l1tp') && (
												<input
													type="checkbox"
													className="form-check-input"
													id="captures-criteria-sensors-landsat"
													checked
													disabled
												></input>
											)}
											{!formValue.formSensors.includes('landsat-8-l1tp') && (
												<input
													type="checkbox"
													className="form-check-input"
													id="captures-criteria-sensors-landsat"
													checked
													disabled
												></input>
											)}
										</Col>
										<Col className="col-3 d-flex justify-content-start">
											<label className="form-check-label pe-3">
												sentinel-2-l1c
											</label>
											{formValue.formSensors.includes('sentinel-2-l1c') && (
												<input
													type="checkbox"
													className="form-check-input"
													id="captures-criteria-sensors-sentinel"
													checked
													disabled
												></input>
											)}
											{!formValue.formSensors.includes('sentinel-2-l1c') && (
												<input
													type="checkbox"
													className="form-check-input"
													id="captures-criteria-sensors-sentinel"
													checked
													disabled
												></input>
											)}
										</Col>
									</Row>

									{/* COMMODITIES */}
									<Row className="py-2">
										<Col className="col-3">
											<label htmlFor="customRange1" className="form-label">
												Commodities
											</label>
										</Col>
										<Col className="col-9">
											{config.commodities.map((comm, idx) => (
												<>
													<label
														htmlFor={comm.class}
														className="form-check-label pe-2"
													>
														{comm.commodity}
													</label>
													{formValue.formCommodities.includes(comm.index) ? (
														<input
															id={comm.class}
															className="form-check-input me-3"
															type="checkbox"
															checked
															disabled
														></input>
													) : (
														<input
															id={comm.class}
															className="form-check-input me-3"
															type="checkbox"
															disabled
														></input>
													)}
												</>
											))}
										</Col>
									</Row>

									{/* PRODUCTION STATE */}
									<Row className="py-2">
										<Col className="col-3">
											<label htmlFor="customRange1" className="form-label">
												Production States
											</label>
										</Col>

										<Col className="col-9">
											{config.production_states.map((state, idx) => (
												<>
													<label
														htmlFor={state.state}
														className="form-check-label pe-2"
														title={state.descript}
													>
														{state.state}
													</label>
													{formValue.formProdStates.includes(state.state) && (
														<input
															id={state.state}
															className="form-check-input me-3"
															type="checkbox"
															disabled
															checked
														></input>
													)}
													{!formValue.formProdStates.includes(state.state) && (
														<input
															id={state.state}
															className="form-check-input me-3"
															type="checkbox"
															disabled
														></input>
													)}
												</>
											))}
										</Col>
									</Row>
								</fieldset>
							</form>
						</Col>
						<Col></Col>
					</Row>
				</div>

				{capsInPipeline.length === 0 && autoQCCaps.length === 0 ? (
					<></>
				) : (
					<>
						<Row className="py-2">
							<h4>
								{capsInPipeline.length} captures to QC{' '}
								<span>
									<i
										className={
											manualCapturesTableIsCollapsed
												? 'fa-solid fa-caret-up'
												: 'fa-solid fa-caret-down'
										}
										onClick={() =>
											setManualCapturesTableIsCollapse(
												!manualCapturesTableIsCollapsed
											)
										}
										href="#manualCapturesTableCollapse"
										data-bs-target="#manualCapturesTableIsCollapsed"
										role="button"
										aria-expanded="false"
										aria-controls="manualCapturesTableIsCollapsed"
									></i>
								</span>
							</h4>

							{/* <p>
								<button
									className="btn interactButtonSecondary"
									onClick={() =>
										setManualCapturesTableIsCollapse(
											!manualCapturesTableIsCollapsed
										)
									}
									href="#manualCapturesTableCollapse"
									data-bs-target="#manualCapturesTableIsCollapsed"
									role="button"
									aria-expanded="false"
									aria-controls="manualCapturesTableIsCollapsed"
								>
									{manualCapturesTableIsCollapsed
										? 'View manual caps table'
										: 'Hide manual caps table'}
								</button>
							</p> */}

							{capsInPipeline.length > 0 ? (
								<div
									className={`collapse ${
										manualCapturesTableIsCollapsed ? 'show' : ''
									}`}
									id="manualCapturesTableCollapse"
								>
									<div className="table-responsive">
										{comm === 'smelters' && (
											<CapturesTable
												fields={smelterFieldsTechDetailNoAuto}
												captures={capsInPipeline}
												qcCat="smelters"
												tableHeight="500px"
												moveToAuto={moveToAuto}
											/>
										)}

										{comm === 'stockpiles' && (
											<CapturesTable
												fields={stockpileFields}
												captures={capsInPipeline}
												qcCat="stockpiles"
												tableHeight="500px"
											/>
										)}
									</div>
								</div>
							) : (
								<h5>No captures</h5>
							)}
						</Row>

						<Row className="py-4">
							<h4>
								{autoQCCaps.length} captures to Auto QC{' '}
								<span>
									<i
										className={
											autoCapturesTableIsCollapsed
												? 'fa-solid fa-caret-up'
												: 'fa-solid fa-caret-down'
										}
										onClick={() =>
											setAutoCapturesTableIsCollapse(
												!autoCapturesTableIsCollapsed
											)
										}
										href="#autoCapturesTableCollapse"
										data-bs-target="#autoCapturesTableIsCollapsed"
										role="button"
										aria-expanded="false"
										aria-controls="autoCapturesTableIsCollapsed"
									></i>
								</span>
							</h4>

							{autoQCCaps.length > 0 ? (
								<div
									className={`collapse ${
										autoCapturesTableIsCollapsed ? 'show' : ''
									}`}
									id="autoCapturesTableCollapse"
								>
									<p className="py-0 my-0 ">
										Please verify you want to send these captures to Auto QC.
									</p>
									<p>
										Values in <span className="fw-bold">bold</span> represent
										the property which qualifies the capture for Auto QC.
									</p>

									<div className="form-check form-switch align-items-center my-2">
										<label
											data-tooltip-id="toggleAutoQCColourCodeToggleTT"
											className="form-check-label me-2"
											htmlFor="toggleAutoQCColourCodeToggle"
										>
											Toggle Auto QC Colour Code
										</label>
										<input
											className="form-check-input px-0"
											type="checkbox"
											role="switch"
											id="toggleAutoQCColourCodeToggle"
											checked={toggleAutoQCColourCode}
											onChange={(e) =>
												setToggleAutoQCColourCode((prevState) => !prevState)
											}
										/>
									</div>
									<Tooltip
										id="toggleAutoQCColourCodeToggleTT"
										place="top"
										effect="solid"
									>
										View captures colour coded by the determined Auto QC State{' '}
										<br />
										<div className="d-flex justify-content-start align-items-center">
											<div
												style={{
													width: '15px',
													height: '15px',
													borderRadius: '50%',
													backgroundColor: 'rgb(218, 165, 32)',
													marginRight: '5px'
												}}
											></div>{' '}
											Active
										</div>{' '}
										<div className="d-flex justify-content-start align-items-center">
											<div
												style={{
													width: '15px',
													height: '15px',
													borderRadius: '50%',
													backgroundColor: 'rgb(65, 105, 225)',
													marginRight: '5px'
												}}
											></div>{' '}
											Inactive
										</div>{' '}
										<div className="d-flex justify-content-start align-items-center">
											<div
												style={{
													width: '15px',
													height: '15px',
													borderRadius: '50%',
													backgroundColor: ' rgb(169, 169, 169)',
													marginRight: '5px'
												}}
											></div>{' '}
											Invalid
										</div>{' '}
									</Tooltip>

									<div className="table-responsive">
										{comm === 'smelters' && (
											<CapturesTable
												fields={smelterFieldsTechDetail}
												captures={autoQCCaps}
												qcCat="smelters"
												tableHeight="500px"
												autoQCProps={autoQCProps}
												toggleAutoQCColourCode={toggleAutoQCColourCode}
												moveToManual={moveToManual}
											/>
										)}

										{comm === 'stockpiles' && (
											<CapturesTable
												fields={stockpileFields}
												captures={autoQCCaps}
												qcCat="stockpiles"
												tableHeight="500px"
											/>
										)}
									</div>
								</div>
							) : (
								<h5 className="text-secondary">
									{autoQCProps.autoQCToggle ? (
										<>No captures meet the criteria for Auto QC</>
									) : (
										<>Auto QC is turned off</>
									)}
								</h5>
							)}
						</Row>
					</>
				)}
			</div>
		</div>
	);
};

export default QCCaptures;
