import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import formatDate from '../../../helpers/formatDate';
import config from '../../../config';
import useGainsQCApi from '../../../services/useGainsQCApi';
import { Toaster } from 'react-hot-toast';
import { pipelineContext } from '../../../contexts/pipelineContext';
import { Tooltip } from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';
import exportConfig from '../../../config';

const QCFormHistoric = ({
	fetchCapturesObj,
	handleSubmit,
	formValue,
	setFormValue,
	formValid,
	setFormValid,
	loading,
	error,
	setCapturesUrl,
	fetchSites,
	props
}) => {
	const [siteOptions, setSiteOptions] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [selectedCommForSites, setSelectedCommForSites] = useState(null);

	const pipelineContextQCFormHist = useContext(pipelineContext);

	const reloadSites = () => {
		fetchSites.makeRequest(
			`${config.baseUrl}/${pipelineContextQCFormHist.comm}/sites?hasCaptures=true`,
			'GET',
			null,
			'',
			1000
		);
	};

	useEffect(() => {
		if (pipelineContextQCFormHist.comm === "smelters"){
			setSelectedCommForSites(3)
		} else {
			setSelectedCommForSites(4)
		}
	},[pipelineContextQCFormHist.comm])

	console.log(selectedCommForSites)

	useEffect(() => {
		if (fetchSites?.data) {
			var options;
			options = fetchSites.data.data
				.filter((site) => site.commodity_id === selectedCommForSites)
				.map(({ id, name }) => ({
					value: id,
					label: `${id} - ${name}`
				}))
				.sort((a, b) => a.label.localeCompare(b.label));

			setSiteOptions(options);
		}
	}, [fetchSites.data, selectedCommForSites]);

	useEffect(() => {
		let firstName = props.authProps.userAttributes.givenName.toLowerCase();
		let lastName = props.authProps.userAttributes.familyName.toLowerCase();

		validateForm();

		let qcSts = '0';
		if (formValue.inclQCdCaps) {
			qcSts = '0,1';
		}

		// 08/10/24 LC - this should always be assign_captures, but the stockpiles EP for this is not
		// ready so we have to use the captures EP
		if (pipelineContextQCFormHist.comm === 'smelters') {
			if (formValue.fullHistory) {
				setCapturesUrl(
					`assign_captures?startDate=2016-01-01&endDate=${formValue.formEndDate}&siteIds=${formValue.formSiteId}&qcStates=${qcSts}&qcUser=${firstName}.${lastName}&expirationTime=30`
				);
			} else {
				setCapturesUrl(
					`assign_captures?startDate=${formValue.formStartDate}&endDate=${formValue.formEndDate}&siteIds=${formValue.formSiteId}&qcStates=${qcSts}&qcUser=${firstName}.${lastName}&expirationTime=30`
				);
			}
		} else {
			if (formValue.fullHistory) {
				setCapturesUrl(
					`captures?startDate=2016-01-01&endDate=${formValue.formEndDate}&siteIds=${formValue.formSiteId}&qc=0&qcUser=${firstName}.${lastName}`
				);
			} else {
				setCapturesUrl(
					`captures?startDate=${formValue.formStartDate}&endDate=${formValue.formEndDate}&siteIds=${formValue.formSiteId}&qc=0&qcUser=${firstName}.${lastName}`
				);
			}
		}
	}, [formValue]);

	const handleChange = (selectedValues) => {
		setSelectedOptions(selectedValues);
		setFormValue({ ...formValue, formSiteId: selectedValues.value });
	};

	const validateForm = () => {
		if (
			formValue.fullHistory &&
			formValue.formEndDate !== '' &&
			typeof formValue.formSiteId === 'number' &&
			formValue.formEndDate > formValue.formStartDate
		) {
			setFormValid(true);
		} else if (
			formValue.formEndDate !== '' &&
			formValue.formStartDate !== '' &&
			typeof formValue.formSiteId === 'number' &&
			formValue.formEndDate > formValue.formStartDate
		) {
			setFormValid(true);
		} else {
			setFormValid(false);
		}
	};

	const handleBackButton = (e) => {
		e.preventDefault();
		setFormValue({
			formSiteId: '',
			formStartDate: '',
			formEndDate: new Date().toISOString().substring(0, 10),
			formAutoQC: true,
			formEndDateNow: '',
			fullHistory: true,
			formSensors: ['sentinel-2-l1c', 'landsat-8-l1tp'],
			formCommodities: [3],
			formProdStates: [0, 1],
			formLookback: 1,
			manuallySelectSites: true,
		});

		pipelineContextQCFormHist.setQCInProgress(false);
		pipelineContextQCFormHist.setProgressBar({
			width: 2 * (100 / 7),
			title: 'QC Type'
		});
		pipelineContextQCFormHist.setQCType(undefined);
	};

	return (
		<>
			<Toaster />
			<hr></hr>
			<form onSubmit={handleSubmit} className="px-3 mx-3">
				<div className="row">
					<div className="col d-flex justify-content-start align-items-center">
						<button
							className="btn interactButtonSecondary"
							onClick={handleBackButton}
							style={{ width: '200px' }}
						>
							<i className="fa-solid fa-left-long pe-3"></i>
							Change QC type
						</button>
						<h1 className="ms-5 mt-2">QC</h1>
					</div>

					<div className="col d-flex justify-content-end  align-items-center">
						{formValid && !loading ? (
							<button
								type="submit"
								className="btn interactButton mx-0"
								style={{ width: '200px', maxHeight: '40px' }}
							>
								View captures <i className="fa-solid fa-right-long ps-2"></i>
							</button>
						) : (
							<button
								disabled
								className="btn btn-secondary mx-0"
								style={{ width: '200px', maxHeight: '40px' }}
							>
								View captures <i className="fa-solid fa-right-long ps-2"></i>
							</button>
						)}
					</div>
				</div>

				<div className="row">
					<h4 className="py-3 py-md-3">
						Fill in the captures criteria for{' '}
						<span className="fw-bold">historic QC</span>
					</h4>

					<div className="col-12 col-lg-6 col-xl-5 py-3 py-md-3 px-2 pe-lg-5">
						<div className="form-group" style={{ width: '90%' }}>
							<h5 className="card-title">Site</h5>
							<p className="fst-italic">Select one site</p> <br></br>
							<div className="form-group">
								<div className="d-flex flex-row align-items-center mb-3">
									{exportConfig.commodities
										.filter((comm) => comm.siteTypeStr === pipelineContextQCFormHist.comm)
										.map((comm, idx) => (
											<>
												<input
													id={`id${comm.index}`}
													type="radio"
													name="historic-form-site-comms-filter"
													key={idx}
													value={comm.index}
													className="form-check-input"
													checked={selectedCommForSites === comm.index}
													onClick={() => setSelectedCommForSites(comm.index)}
												></input>
												<label
													for={`id${comm.index}`}
													className="form-label ms-1 me-4 my-0"
												>
													{comm.id}
												</label>
											</>
										))}
								</div>
							</div>
							{fetchSites.loading ? (
								// <Select
								// 	options={siteOptions}
								// 	value={
								// 		siteOptions.filter(
								// 			(option) => option.value === formValue.formSiteId
								// 		)[0]
								// 	}
								// 	onChange={handleChange}
								// 	isSearchable
								// 	placeholder="Search by site name or id"
								// 	isDisabled={true}
								// />
								<Skeleton height={35} />
							) : (
								<>
									{formValue.formSiteId ? (
										<Select
											options={siteOptions}
											value={
												siteOptions.filter(
													(option) => option.value === formValue.formSiteId
												)[0]
											}
											onChange={handleChange}
											isSearchable
											placeholder="Search by site name or id"
										/>
									) : (
										<Select
											options={siteOptions}
											value={selectedOptions}
											onChange={handleChange}
											isSearchable
											placeholder="Search by site name or id"
										/>
									)}
								</>
							)}
							{fetchSites.error && (
								<h6 className=" mt-2">
									<i
										id="sentinel-hub-icon"
										onClick={() => reloadSites()}
										className="me-2 fa-solid fa-arrows-rotate"
										data-tooltip-id="reload-sites-historic-form"
									></i>{' '}
									Could not fetch sites at this time
								</h6>
							)}
							{fetchSites.loading && <h6 className="mt-2">Loading sites ..</h6>}
							<Tooltip
								id="reload-sites-historic-form"
								place="bottom"
								effect="solid"
							>
								Reload sites
							</Tooltip>
						</div>

						<div className="form-group mt-5 pt-5" style={{ width: '90%' }}>
							<div className="row">
								<div className="col-10">
									<h5 className="card-title">Include QC'd captures</h5>
									<p className="fst-italic my-0 py-0">
										Select to fetch captures which have already been QC'd
									</p>{' '}
									<br></br>
								</div>
								<div className="col-2 d-flex justify-content-end">
									<input
										id="include-qcd-caps"
										type="checkbox"
										name="QCFormHistoricInclQCdCaps"
										value={true}
										className="form-check-input"
										defaultChecked={formValue.inclQCdCaps === true}
										onClick={(e) =>
											setFormValue({
												...formValue,
												inclQCdCaps:
													formValue.inclQCdCaps === true ? false : true
											})
										}
									></input>
								</div>
							</div>

							{/* <label for="include-qcd-caps" className="form-label ms-2">
											
										</label> */}
						</div>
					</div>

					<div className="col-1"></div>

					<div className="col-12 col-lg-6 col-xl-5 py-3 py-md-3 px-2 pe-lg-5">
						<div className="form-group">
							<h5 className="card-title">Interval</h5>
							<p className="fst-italic">Set interval period</p> <br></br>
							<div className="row">
								<div className="d-flex flex-column">
									<div className="d-flex flex-row">
										<input
											id="full-history-true"
											type="radio"
											name="QCFormHistoricFullHistory"
											value={true}
											className="form-check-input"
											defaultChecked={formValue.fullHistory === true}
											onClick={(e) =>
												setFormValue({
													...formValue,
													fullHistory: true,
													formStartDate: ''
												})
											}
										></input>
										<label for="full-history-true" className="form-label ms-2">
											Full capture history
										</label>
									</div>
									<div className="d-flex flex-row">
										<input
											id="full-history-false"
											type="radio"
											name="QCFormHistoricFullHistory"
											value={false}
											defaultChecked={formValue.fullHistory === false}
											className="form-check-input"
											onClick={(e) =>
												setFormValue({
													...formValue,
													fullHistory: false
												})
											}
										></input>
										<label for="full-history-false" className="form-label ms-2">
											Choose specific dates
										</label>
									</div>
								</div>
							</div>
							
							<div className="row mt-5">
								<h6>Selecting captures between</h6>

								<div className="col-5 d-flex justify-content-evenly">
									{formValue.formStartDate ? (
										<span className="fw-bold">
											{formatDate(formValue.formStartDate)}
										</span>
									) : (
										<span className="fw-bold">earliest capture date</span>
									)}

									<p> at </p>
									<span className="fw-bold ms-2"> 00:00</span>
								</div>
								<div className="col-2 d-flex justify-content-center">and </div>
								<div className="col-5 d-flex justify-content-evenly">
									<span className="fw-bold">
										{formatDate(formValue.formEndDate)}
									</span>{' '}
									at{' '}
									{formValue.fullHistory ? (
										<span className="fw-bold">
											{new Date().toLocaleTimeString().slice(0, 5)}
										</span>
									) : (
										<span className="fw-bold">00:00</span>
									)}
								</div>
							</div>
							{formValue.fullHistory === false && (
								<Row className="py-2">
									<Col className="col-6 pe-1">
										<label for="start_date">Start date</label> <br></br>
										{formValue.fullHistory ? (
											<input
												className="form-control text-sm"
												id="start_date"
												name="formStartDate"
												title="start date of interval"
												max={new Date().toISOString().split('T')[0]}
												type="date"
												value=""
												disabled
											></input>
										) : (
											<input
												className="form-control text-sm"
												id="start_date"
												name="formStartDate"
												title="start date of interval"
												type="date"
												max={new Date().toISOString().split('T')[0]}
												value={formValue.formStartDate}
												onChange={(e) =>
													setFormValue({
														...formValue,
														formStartDate: e.target.value
													})
												}
											></input>
										)}
										<br></br>
									</Col>
									<Col className="col-6 ps-1">
										<label for="end_date">End date</label> <br></br>
										<input
											className="form-control text-sm"
											id="end_date"
											name="formEndDate"
											title="End date of interval"
											type="date"
											max={new Date().toISOString().split('T')[0]}
											value={formValue.formEndDate}
											onChange={(e) =>
												setFormValue({
													...formValue,
													formEndDate: e.target.value
												})
											}
										></input>
										<br></br>
									</Col>

									{formValue.formStartDate > formValue.formEndDate && (
										<p id="sensor-err" className="text-warning pt-3">
											<i class="fa-solid fa-triangle-exclamation"></i> The start
											date is after the end date
										</p>
									)}
								</Row>
							)}
						</div>

						{/* <div className="form-group mt-5">
							<Row>
								<Col className="d-flex justify-content-end">
									<label
										data-tooltip-id="disabledAutoQC"
										className="px-2 text-decoration-line-through"
										for="set_auto_qc"
									>
										set parameters for Auto QC
									</label>
									<input
										className="form-check-input"
										type="checkbox"
										id="set_auto_qc"
										name="set_auto_qc"
										disabled
										value={formValue.formAutoQC}
										defaultChecked={formValue.formAutoQC}
										onClick={() =>
											setFormValue({
												...formValue,
												formAutoQC: !formValue.formAutoQC
											})
										}
									></input>
									<Tooltip
										id="disabledAutoQC"
										place="bottom"
										effect="solid"
										style={{ backgroundColor: 'grey' }}
									>
										Disabled for now to focus on fully working manual QC
										pipeline
									</Tooltip>
								</Col>
							</Row>
						</div> */}
					</div>
				</div>
			</form>
		</>
	);
};

export default QCFormHistoric;
