import React, { useEffect, useState, useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import useGainsQCApi from '../../services/useGainsQCApi';

const CurrentlyQCingUser = ({ user, data, loading, error }) => {
	/**
	 * renders all captures in database assigned to user
	 *
	 * @param {string} user - user attributes derived from authentication
	 * @param {boolean} loading - if loading fetched data from parent component Dashboard
	 * @param {boolean} error - if error from fetched data in parent component Dashboard
	 * @param {Object} data - object containing count of captures, for all site types, commodities and QC stages
	 *
	 * @returns {JSX.Element} - bootstrap card containing count of user's captures in database
	 */

	const [capsUser, setCapsUser] = useState([]);

	useEffect(() => {
		let userQCHandle = `${user.givenName.toLowerCase()}.${user.familyName.toLowerCase()}`;

		if (data && data.length > 0) {
			setCapsUser(
				data
					.filter((dat) => dat.user_id === userQCHandle)
					.reduce((sum, item) => sum + item.count, 0)
			);
		}
	}, [data]);

	const navigate = useNavigate();

	const navigateToUsersCaptures = () => {
		navigate('/users_captures');
	};

	if (loading || error) {
		return (
			<>
				<div class="dashboard-card">
					<div class="dashboard-card-header">
						<i className="fa-regular fa-file-lines me-2"></i>My QC
					</div>
					<div class="dashboard-card-body">
						<h5>
							There are{' '}
							<strong>
								<span style={{ display: 'inline-block' }}>
									<Skeleton width={30} />
								</span>
							</strong>{' '}
							captures in your pipeline
						</h5>

						<Row>
							<Col>
								<h1 className="text-center">
									<Skeleton width={50} />
								</h1>
								<p className="text-center">to QC</p>
							</Col>
						</Row>

						{/* <Row>
							<div className="d-flex justify-content-center mt-4">
								<button
									data-tooltip-id="dashboard-remove-caps"
									className="btn interactButton mx-2"
									style={{ width: '180px' }}
									disabled
								>
									View your captures
								</button>
							</div>
						</Row> */}

						<Tooltip id="dashboard-remove-caps">
							View, remove, or continue to QC the captures assigned to you
						</Tooltip>

						<Tooltip id="dashboard-remove-caps-disabled">
							You have no captures to view
						</Tooltip>
					</div>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div class="dashboard-card">
					<div class="dashboard-card-header">
						<div className="row">
							<div className="col-10">
								<i className="fa-regular fa-user me-2"></i>My QC
							</div>
							<div className="col-2 d-flex justify-content-end">
								<i
									data-tooltip-id="currentlyQCingUser-navigate-to-user-caps-page"
									id="captureImageHover"
									onClick={navigateToUsersCaptures}
									className="fa-solid fa-arrow-up-right-from-square m-1"
									style={{ color: 'rgb(130, 195, 89)' }}
								></i>
							</div>
						</div>
					</div>
					<div class="dashboard-card-body">
						<h5>
							There are <strong>{capsUser > 0 ? capsUser : 0}</strong> captures
							in your pipeline
						</h5>
						<Row>
							<Col>
								<h1
									className="text-center"
									data-tooltip-id="dashboard-userCaps-QC"
								>
									{capsUser > 0 ? capsUser : 0}
								</h1>
								<p className="text-center">to QC</p>
							</Col>
						</Row>
					</div>
				</div>

				{data && user && (
					<>
						<Tooltip id="dashboard-userCaps-QC">
							Captures to QC <br></br>
							<i className="fa-solid fa-city me-2"></i>
							<br></br>
							<i className="fa-solid fa-cubes-stacked me-2"></i>{' '}
						</Tooltip>

						<Tooltip id="dashboard-userCaps-upload">
							Captures to upload <br></br>
							<i className="fa-solid fa-city me-2"></i>
							<br></br>
							<i className="fa-solid fa-cubes-stacked me-2"></i>{' '}
						</Tooltip>
					</>
				)}

				<Tooltip id="dashboard-remove-caps">
					View, remove, or continue to QC the captures assigned to you
				</Tooltip>
				<Tooltip id="dashboard-remove-caps-disabled">
					You have no captures to view
				</Tooltip>

				<Tooltip
					place="top"
					effect="solid"
					id="currentlyQCingUser-navigate-to-user-caps-page"
				>
					Open My Captures
				</Tooltip>
			</>
		);
	}
};

export default CurrentlyQCingUser;
