import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import earthiLogo from '../../images/earthi-rev.png';
import { Col } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import useGainsQCApi from '../../services/useGainsQCApi';
import CapturesTable from '../CapturesTable';
import exportConfig from '../../config';

const Testing = (props) => {
	return (
		<>
			<Col className="px-0">
				<div className="contentContainer" id="dashboard-container">
					<div className="px-0 mx-0">
						<hr></hr>

						{/* TITLE ROW */}
						<div className="row" style={{ border: 'solid black 1px' }}>
							<div className="col" style={{ border: 'solid pink 1px' }}>
								<h1>Testing</h1>
							</div>
							<div className="col" style={{ border: 'solid pink 1px' }}></div>
							<div className="col" style={{ border: 'solid pink 1px' }}></div>
							<div className="col" style={{ border: 'solid pink 1px' }}></div>
						</div>

						{/* INFO ROW */}
						<div className="row" style={{ border: 'solid black 1px' }}></div>

						{/* PICS ROW */}
						<div
							className="row"
							style={{ border: 'solid black 1px', height: '40vh' }}
						>
							<div
								className="col-12 my-2 my-lg-0 col-lg-4"
								style={{ border: 'solid orange 1px' }}
							>
								<div style={{ width: '100%', height: '100%', border: 'solid yellow 1px' }}></div>
							</div>
							<div
								className="col-12 my-2 my-lg-0 col-lg-4"
								style={{ border: 'solid orange 1px' }}
							>
								<div style={{ width: '100%', height: '100%', border: 'solid yellow 1px' }}></div>
							</div>
							<div
								className="col-12 my-2 my-lg-0 col-lg-4"
								style={{ border: 'solid orange 1px' }}
							>
								<div style={{ width: '100%', height: '100%', border: 'solid yellow 1px' }}></div>
							</div>
						</div>

						{/* PICS ERROR ROW */}
						<div
							className="row"
							style={{ border: 'solid black 1px', height: '5vh' }}
						></div>

						{/* EVERYTHING ELSE ROW */}
						<div
							className="row"
							style={{ border: 'solid black 1px', height: '40vh' }}
						>
							<div
								className="col-6 mt-3"
								style={{ border: 'solid green 1px' }}
							></div>
							<div
								className="col-3 mt-3"
								style={{ border: 'solid green 1px' }}
							></div>
							<div
								className="col-3 mt-3"
								style={{ border: 'solid green 1px' }}
							></div>
						</div>
					</div>
				</div>
			</Col>
		</>
	);
};

export default Testing;
