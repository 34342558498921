import React from 'react';

const EmptyTable = ({ numRows, cols, height }) => {
	return (
		<div
			className="table-responsive"
			style={{ maxHeight: height, maxWidth: '100%', overflowX: 'scroll' }}
		>
			<table className="table">
				<thead className="table-light">
					<tr>
						{cols.map((col, idx) => (
							<th scope="col" key={idx}>
								{col}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="table-group-divider">
					{Array.from({ length: numRows }, (_, rowIndex) => (
						<tr key={rowIndex}>
							{Array.from({ length: cols.length }, (_, rowIndex) => (
								<td>-</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default EmptyTable
