import React, { useState, useEffect } from 'react';
import * as echarts from 'echarts';

const QCActivityGraph = () => {
	const [timeRange, setTimeRange] = useState('1month');
	const chartRef = React.useRef(null);

	// Example data structure
	const timeRanges = {
		'1month': [
			{ date: '2024-08-01', smelters: 120, stockpiles: 90, airfields: 80 },
			{ date: '2024-08-08', smelters: 130, stockpiles: 95, airfields: 85 },
			{ date: '2024-08-15', smelters: 140, stockpiles: 100, airfields: 90 },
			{ date: '2024-08-22', smelters: 135, stockpiles: 110, airfields: 92 },
			{ date: '2024-08-29', smelters: 150, stockpiles: 115, airfields: 95 }
		],
		'3months': [
			{ date: '2024-06-01', smelters: 100, stockpiles: 75, airfields: 65 },
			{ date: '2024-07-01', smelters: 110, stockpiles: 85, airfields: 75 },
			{ date: '2024-08-01', smelters: 120, stockpiles: 90, airfields: 80 },
			{ date: '2024-09-01', smelters: 150, stockpiles: 120, airfields: 100 }
		],
		'6months': [
			{ date: '2024-03-01', smelters: 90, stockpiles: 60, airfields: 55 },
			{ date: '2024-04-01', smelters: 95, stockpiles: 65, airfields: 60 },
			{ date: '2024-05-01', smelters: 105, stockpiles: 70, airfields: 65 },
			{ date: '2024-06-01', smelters: 100, stockpiles: 75, airfields: 65 },
			{ date: '2024-07-01', smelters: 110, stockpiles: 85, airfields: 75 },
			{ date: '2024-08-01', smelters: 120, stockpiles: 90, airfields: 80 },
			{ date: '2024-09-01', smelters: 150, stockpiles: 120, airfields: 100 }
		],
		'1year': [
			{ date: '2023-09-01', smelters: 70, stockpiles: 50, airfields: 45 },
			{ date: '2023-12-01', smelters: 80, stockpiles: 55, airfields: 50 },
			{ date: '2024-03-01', smelters: 90, stockpiles: 60, airfields: 55 },
			{ date: '2024-06-01', smelters: 100, stockpiles: 75, airfields: 65 },
			{ date: '2024-09-01', smelters: 150, stockpiles: 120, airfields: 100 }
		],
		allTime: [
			{ date: '2020-01-01', smelters: 50, stockpiles: 30, airfields: 25 },
			{ date: '2021-01-01', smelters: 60, stockpiles: 40, airfields: 35 },
			{ date: '2022-01-01', smelters: 70, stockpiles: 50, airfields: 45 },
			{ date: '2023-01-01', smelters: 75, stockpiles: 55, airfields: 50 },
			{ date: '2024-01-01', smelters: 90, stockpiles: 60, airfields: 55 },
			{ date: '2024-09-01', smelters: 150, stockpiles: 120, airfields: 100 }
		]
	};

	useEffect(() => {
		if (chartRef.current) {
			const chart = echarts.init(chartRef.current);

			const updateChart = () => {
				const data = timeRanges[timeRange];

				const option = {
					title: {
						text: '',
						left: 'center',
						top: 'bottom'
					},
					grid: {
						right: 150 // Increase space on the right for the legend
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['smelters', 'stockpiles', 'airfields'],
						orient: 'vertical',
						right: 0,
						top: 'middle'
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: data.map((item) => item.date)
					},
					yAxis: {
						type: 'value'
					},
					series: [
						{
							name: 'smelters',
							type: 'line',
							data: data.map((item) => item.smelters)
						},
						{
							name: 'stockpiles',
							type: 'line',
							data: data.map((item) => item.stockpiles)
						},
						{
							name: 'airfields',
							type: 'line',
							data: data.map((item) => item.airfields)
						}
					]
				};

				chart.setOption(option);
			};

			updateChart();

			// Clean up chart on component unmount
			return () => {
				chart.dispose();
			};
		}
	}, [timeRange]); // Re-render chart when timeRange changes

	return (
		<div class="dashboard-card">
			<div
				class="dashboard-card-header"
				data-tooltip-id="dashboard-site-changes-tt"><div className="row">
				<div className="col">
					<i className="fa-solid fa-location-pin me-2"></i> Captures QC'd over time
				</div>
				<div className="col d-flex justify-content-end">
				<i className="fa-solid fa-wrench"></i>
				</div>
			</div></div>
		

<div class="dashboard-card-body">
				<div>
					<div style={{ textAlign: 'center', zIndex: '9999' }}>
						<label className="px-2">
							<input
								className="form-check-input form-commodity"
								type="radio"
								name="timeRange"
								value="1month"
								checked={timeRange === '1month'}
								onChange={(e) => setTimeRange(e.target.value)}
							/>
							&nbsp; 1 Month
						</label>
						<label className="px-2">
							<input
								className="form-check-input form-commodity"
								type="radio"
								name="timeRange"
								value="3months"
								checked={timeRange === '3months'}
								onChange={(e) => setTimeRange(e.target.value)}
							/>
							&nbsp; 3 Months
						</label>
						<label className="px-2">
							<input
								className="form-check-input form-commodity"
								type="radio"
								name="timeRange"
								value="6months"
								checked={timeRange === '6months'}
								onChange={(e) => setTimeRange(e.target.value)}
							/>
							&nbsp; 6 Months
						</label>
						<label className="px-2">
							<input
								className="form-check-input form-commodity"
								type="radio"
								name="timeRange"
								value="1year"
								checked={timeRange === '1year'}
								onChange={(e) => setTimeRange(e.target.value)}
							/>
							&nbsp; 1 Year
						</label>
						<label className="px-2">
							<input
								className="form-check-input form-commodity"
								type="radio"
								name="timeRange"
								value="allTime"
								checked={timeRange === 'allTime'}
								onChange={(e) => setTimeRange(e.target.value)}
							/>
							&nbsp; All Time
						</label>
					</div>

					<div
						ref={chartRef}
						style={{ width: '100%', height: '350px', marginTop: '-10px' }}
						lazyUpdate={true}
					></div>
				</div>
			</div>
		</div>
	);
};

export default QCActivityGraph;
