import React from 'react';
import { Tooltip } from 'react-tooltip';

export const QCTypePills = ({dashboardQCType, setDashboardQCType}) => {
	return (
		<>
			<div>
				<div
					class="form-check-dashboard-qcinfo"
					data-tooltip-id="dashboard-qc-type-radios-smelter"
				>
					<input
						className="form-check-input-dashboard-qcinfo"
						type="radio"
						name="flexRadioDefault"
						id="flexRadioDefault1"
						checked={dashboardQCType === 'smelters'}
						onChange={(e) => setDashboardQCType('smelters')}
					></input>
					<label
						class="form-check-label-dashboard-qcinfo"
						for="flexRadioDefault1"
						data-tooltip-id="dashboard-qcinfo-smelters"
					>
						<i className="fa-solid fa-city"></i>
					</label>
				</div>
				<div
					className="form-check-dashboard-qcinfo"
					data-tooltip-id="dashboard-qc-type-radios-stockpiles"
				>
					<input
						className="form-check-input-dashboard-qcinfo"
						type="radio"
						name="flexRadioDefault"
						checked={dashboardQCType === 'stockpiles'}
						id="flexRadioDefault2"
						onChange={(e) => setDashboardQCType('stockpiles')}
					></input>
					<label
						className="form-check-label-dashboard-qcinfo"
						for="flexRadioDefault2"
						data-tooltip-id="dashboard-qcinfo-stockpiles"
					>
						<i class="fa-solid fa-cubes-stacked"></i>
					</label>
				</div>
				<div
					className="form-check-dashboard-qcinfo"
					data-tooltip-id="dashboard-qc-type-radios-airfield"
				>
					<input
						className="form-check-input-dashboard-qcinfo"
						type="radio"
						name="flexRadioDefault"
						checked={dashboardQCType === 'airfields'}
						id="flexRadioDefault3"
						onChange={(e) => setDashboardQCType('airfields')}
					></input>
					<label
						className="form-check-label-dashboard-qcinfo"
						for="flexRadioDefault3"
						data-tooltip-id="dashboard-qcinfo-airfield"
					>
						<i class="fa-solid fa-tower-broadcast"></i>
					</label>
				</div>
			</div>

			<Tooltip
				id="dashboard-qc-type-radios-smelter"
				place="bottom"
				effect="solid"
			>View smelter info</Tooltip>

			<Tooltip
				id="dashboard-qc-type-radios-stockpiles"
				place="bottom"
				effect="solid"
			>View stockpiles info</Tooltip>

			<Tooltip
				id="dashboard-qc-type-radios-airfield"
				place="bottom"
				effect="solid"
			>View airfield info</Tooltip>
		</>
	);
};
