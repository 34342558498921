import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getDashboardData } from '../actions/getActions';

const endpointContext = createContext();

const EndpointProvider = ({ children, env, isAuthenticated }) => {
	const [dbData, setDbData] = useState({
		smelterSitesWithCaptures: [],
		stockpilesSitesWithCaptures: [],
		availableCapsSmelters: {},
		availableCapsStockpiles: {}
	});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchData = useCallback(async () => {
		setLoading(true);
		setError(null);
		try {
			const results = await getDashboardData(env);
			let timeDateNow = new Date();

			setDbData({
				smelterSitesWithCaptures: results[0].data,
				stockpilesSitesWithCaptures: results[1].data,
				availableCapsSmelters: results[2].data,
				availableCapsStockpiles: results[3].data,
				capsInUsersQueues: results[4].data,
				lastUpdate: timeDateNow.toLocaleTimeString()
			});
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	}, [env]);

	useEffect(() => {
		if (isAuthenticated){
			fetchData();
		}
		
	}, [fetchData, isAuthenticated]);

	return (
		<endpointContext.Provider
			value={{ dbData, loading, error, refresh: fetchData }}
		>
			{children}
		</endpointContext.Provider>
	);
};

export { endpointContext, EndpointProvider };
