import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { QCImageSmelter } from '../QCImages/QCImageSmelter';
import useGainsQCApi from '../../../services/useGainsQCApi';
import exportConfig from '../../../config';
import { pipelineContext } from '../../../contexts/pipelineContext';
import { QCImageComparisonSmelter } from '../QCImages/QCImageComparisonSmelter';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import formatDate from '../../../helpers/formatDate';
import formatDateTime from '../../../helpers/formatDateTime';
import { closestIndexTo } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

const CompareImageModal = ({
	imageData,
	compareImageDataList,
	modalId,
	currentCap,
	fetchImageDataGeneral,
	compareImageIndex,
	setCompareImageIndex,
	compareImageData,
	loading
}) => {
	const [timeDifference, setTimeDifference] = useState(null);
	const [allowedDates, setAllowedDates] = useState([]);
	const [imageTypeSelected, setImageTypeSelected] = useState(
		'trueColourImageOverlay'
	);
	const [compareImageCap, setCompareImageCap] = useState(null)

	useEffect(() => {
		console.log("use effect")
		if (
			compareImageDataList &&
			compareImageDataList.length > 0 &&
			compareImageIndex
		) {
			setCompareImageCap(compareImageDataList[compareImageIndex])
			let dates = compareImageDataList.map((date) =>
				date.captured.slice(0, 10)
			);
			setAllowedDates(dates);

			if (currentCap && compareImageDataList[compareImageIndex]) {
				let timeDifference =
					new Date(compareImageDataList[compareImageIndex].captured) -
					new Date(currentCap.captured);
				let daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

				setTimeDifference(daysDifference);
			}
		}
	}, [compareImageDataList, compareImageIndex, compareImageData]);

	const setSelectedDate = (date) => {
		let dateTimeString = `${date[0].getFullYear()}-${String(
			date[0].getMonth() + 1
		).padStart(2, '0')}-${String(date[0].getDate()).padStart(2, '0')}`;
		let capsWithThatDate = compareImageDataList.filter(
			(cap) => cap.captured.slice(0, 10) === dateTimeString
		);

		console.log(capsWithThatDate);

		let index = compareImageDataList.indexOf(capsWithThatDate[0]);
		setCompareImageIndex(index);
	};

	
	return (
		<div
			class="modal modal-xl fade"
			id={modalId}
			tabindex="-1"
			aria-labelledby={`${modalId}Label`}
			aria-hidden="true"
			style={{ marginTop: '80px' }}
		>
			<div class="modal-dialog">
				<div class="modal-content" style={{ marginLeft: '40px' }}>
					<div
						className="modal-header text-light"
						style={{ backgroundColor: 'rgb(48, 48, 48)' }}
					>
						<h4 class="modal-title" id={`id1Label`}>
							Image comparison
						</h4>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>

					<div class="modal-body">
						<div className="row mb-3">
							<div className="col">
								<h5 className="text-center">Current capture</h5>
								<p className="text-center py-0 my-0 fw-bold">
									{formatDateTime(currentCap.captured)}
								</p>
							
							</div>
							<div className="col">
								<h5 className="text-center">
									Comparison capture (
									{timeDifference && timeDifference > 0 && '+'}
									{timeDifference && timeDifference} days)
								</h5>
								<div className="text-center d-flex justify-content-center align-items-center">
									{compareImageIndex > 1 && !loading ? (
										<i
											onClick={() =>
												setCompareImageIndex(compareImageIndex - 1)
											}
											title="View previous date"
											id="captureImageHover"
											className="fa-solid fa-caret-left fa-xl ms-2 me-4 "
										></i>
									) : (
										<i
											title="No previous dates"
											className="text-secondary fa-solid fa-caret-left fa-xl ms-2 me-4 "
										></i>
									)}

									<Flatpickr
										value={
											compareImageCap?.captured
												? new Date(compareImageCap.captured.slice(0, 10) + "T00:00:00")
												: null
										}
										onChange={(date) => setSelectedDate(date)}
										options={{
											enable: allowedDates.map((date) => new Date(date)),
											dateFormat: 'd-m-Y',
											minDate:
												compareImageDataList &&
												new Date(compareImageDataList[1].captured.slice(0, 10)+ "T00:00:00"),
											maxDate:
												compareImageDataList &&
												new Date(
													compareImageDataList[
														compareImageDataList.length - 1
													].captured.slice(0, 10)+ "T00:00:00"
												)
										}}
										className="date-picker form-control text-center"
										style={{ width: '150px' }}
									/>
									<p className="ms-2 py-0 my-0">
										{compareImageCap &&
											compareImageCap.captured.slice(
												11,
												16
											)}
									</p>

									{compareImageIndex < (compareImageDataList.length-1) &&
									!loading ? (
										<i
											title={'View next date'}
											id="captureImageHover"
											className="fa-solid fa-caret-right fa-xl ms-4"
											onClick={() =>
												setCompareImageIndex(compareImageIndex + 1)
											}
										></i>
									) : (
										<i
											title={'No later dates'}
											className="text-secondary fa-solid fa-caret-right fa-xl ms-4"
										></i>
									)}
								</div>

								{/* <div>
									<h3>Select a Date</h3>
									
									{compareImageDataList[compareImageIndex] && (
										<p>
											Selected Date:{' '}
											{compareImageDataList[compareImageIndex].captured.slice(
												0,
												10
											)}
										</p>
									)}
								</div> */}
							</div>
						</div>

						<div className="row" style={{ height: '50vh' }}>
							<div className="col">
								<QCImageComparisonSmelter
									imageData={imageData}
									modalId={`123`}
									imageType={imageTypeSelected}
								/>
							</div>
							<div className="col">
								{!loading ? (
									<QCImageComparisonSmelter
										imageData={compareImageData}
										modalId={`456`}
										imageType={imageTypeSelected}
									/>
								) : (
									<Skeleton height={'600px'} />
								)}
							</div>
						</div>

						{/* <div className="row my-2">
							<div className="col d-flex justify-content-center">
								<table
									className="table table-responsive"
									style={{ width: '70%', marginLeft: '15%' }}
								>
									<tr>
										<td>ID</td>
										<td>{currentCap.id}</td>
									</tr>

									<tr>
										<td>Captured</td>
										<td>{formatDateTime(currentCap.captured)}</td>
									</tr>

									<tr>
										<td>Sensor</td>
										<td>{currentCap.sensor}</td>
									</tr>

									<tr>
										<td>Site ID</td>
										<td>{currentCap.site_id}</td>
									</tr>
								</table>
							</div>
							<div className="col d-flex justify-content-center">
								<table
									className="table table-responsive"
									style={{ width: '70%', marginLeft: '15%' }}
								>
									<tr>
										<td>ID</td>
										<td>
											{compareImageDataList[compareImageIndex] &&
												compareImageDataList[compareImageIndex].id}
										</td>
									</tr>

									<tr>
										<td>Captured</td>
										<td>
											{compareImageDataList[compareImageIndex] &&
												formatDateTime(
													compareImageDataList[compareImageIndex].captured
												)}
										</td>
									</tr>
									<tr>
										<td>Sensor</td>
										<td>
											{compareImageDataList[compareImageIndex] &&
												compareImageDataList[compareImageIndex].sensor}
										</td>
									</tr>

									<tr>
										<td>Site ID</td>
										<td>{currentCap.site_id}</td>
									</tr>
								</table>
							</div>
						</div> */}

						<div className="row my-3">
							<div className="col-4 offset-4 d-flex justify-content-evenly">
								<div>
									<input
										className="form-check-input"
										type="radio"
										name="comparisonImageType"
										id="trueColor"
										checked={imageTypeSelected === 'trueColourImageOverlay'}
										onChange={(e) =>
											setImageTypeSelected('trueColourImageOverlay')
										}
									></input>
									<label class="form-check-label ms-2" for="trueColor">
										True color
									</label>
								</div>

								<div>
									<input
										className="form-check-input"
										type="radio"
										name="comparisonImageType"
										id="ratio"
										checked={imageTypeSelected === 'ratioImageOverlay'}
										onChange={(e) => setImageTypeSelected('ratioImageOverlay')}
									></input>
									<label class="form-check-label ms-2" for="ratio">
										Ratio
									</label>
								</div>

								<div>
									<input
										className="form-check-input"
										type="radio"
										name="comparisonImageType"
										id="composite"
										checked={imageTypeSelected === 'compositeImageOverlay'}
										onChange={(e) =>
											setImageTypeSelected('compositeImageOverlay')
										}
									></input>
									<label class="form-check-label ms-2" for="composite">
										Composite
									</label>
								</div>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary"
							data-bs-dismiss="modal"
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompareImageModal;
