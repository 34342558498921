import React from 'react';

const ConfirmFinishQCModal = () => {
	return (
		<div
			class="modal fade"
			id="confirmCloseQCModal"
			tabindex="-1"
			aria-labelledby="confirmCloseQCModalLabel"
			aria-hidden="true"
			style={{ marginTop: '80px' }}
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div
						className="modal-header text-light"
						style={{ backgroundColor: 'rgb(48, 48, 48)' }}
					>
						<h4 className="modal-title" id="confirmCloseQCModalLabel">
							Confirm Close QC
						</h4>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>

					<div class="modal-body">
                        <p>Are you sure you want to move to upload?  You have not finished QCing all captures. </p>
                    </div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn interactButton"
							data-bs-dismiss="modal"
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConfirmFinishQCModal;
