let smelterFields = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Site Name',
		data: 'site_name'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Capture Time',
		data: 'captured'
	},
	{
		header: 'Sensor',
		data: 'sensor'
	},

	{
		header: 'QC User',
		data: 'qc_user'
	}
];

let smelterFieldsUploadManualQC = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Site Name',
		data: 'site_name'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Capture Time',
		data: 'captured'
	},
	{
		header: 'Sensor',
		data: 'sensor'
	},
	{
		header: 'Initial State',
		data: 'initial_state'
	},
	{
		header: 'QC State',
		data: 'state'
	},

	{
		header: 'QC User',
		data: 'qc_user'
	}
];

let smelterFieldsUploadAutoQC = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Site Name',
		data: 'site_name'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Capture Time',
		data: 'captured'
	},
	{
		header: 'Sensor',
		data: 'sensor'
	},
	{
		header: 'Initial State',
		data: 'initial_state'
	},
	{
		header: 'Auto QC State',
		data: 'autoQCState'
	},

	{
		header: 'QC User',
		data: 'qc_user'
	}
];

let smelterFieldsTechDetailNoAuto = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Site Name',
		data: 'site_name'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Capture Time',
		data: 'captured'
	},
	{
		header: 'Sensor',
		data: 'sensor'
	},
	{
		header: 'Initial State',
		data: 'initial_state'
	},
	{
		header: 'Working',
		data: 'working'
	},
	
	{
		header: 'Cloud Fraction',
		data: 'cloud_frac'
	},
	{
		header: 'Invalid Data Fraction',
		data: 'invalid_data_frac'
	},
	{
		header: 'Max Band Ratio',
		data: 'max_band_ratio'
	},

	{
		header: 'QC User',
		data: 'qc_user'
	}
];

let smelterFieldsTechDetail = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Site Name',
		data: 'site_name'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Capture Time',
		data: 'captured'
	},
	{
		header: 'Sensor',
		data: 'sensor'
	},
	{
		header: 'Initial State',
		data: 'initial_state'
	},
	{
		header: 'Auto QC State',
		data: 'autoQCState'
	},
	
	{
		header: 'Hotspots',
		data: 'hotspots'
	},
	{
		header: 'Hotspots Threshold',
		data: 'hotspot_thresh'
	},
	{
		header: 'Working',
		data: 'working'
	},
	{
		header: 'Cloud Fraction',
		data: 'cloud_frac'
	},
	{
		header: 'Invalid Data Fraction',
		data: 'invalid_data_frac'
	},
	{
		header: 'Max Band Ratio',
		data: 'max_band_ratio'
	},
	{
		header: 'QC User',
		data: 'qc_user'
	}
];

let stockpileFields = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Site Name',
		data: 'site_name'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Capture Time',
		data: 'captured'
	},
	{
		header: 'Model',
		data: 'model_name'
	},
	{
		header: 'QC User',
		data: 'qc_user'
	}
];

let smeltersPostQCFields = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Captured',
		data: 'captured'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Sensor',
		data: 'sensor'
	},
	{
		header: 'State',
		data: 'state'
	},
	{
		header: 'Change',
		data: 'change'
	},
	{
		header: 'Cloud Fraction',
		data: 'cloud_frac'
	},
	{
		header: 'Raw Index',
		data: 'raw_index_score'
	},
	{
		header: 'NRIS 6',
		data: 'normalized_raw_index_score_6'
	},
	{
		header: 'NRIS 7',
		data: 'normalized_raw_index_score_7'
	},
	{
		header: 'NRIS 8',
		data: 'normalized_raw_index_score_8'
	},
	{
		header: 'NRIS 9',
		data: 'normalized_raw_index_score_9'
	},
	{
		header: 'Dispersion Index',
		data: 'dispersion_index'
	},
	{
		header: 'Working',
		data: 'working'
	},
	{
		header: 'Filled',
		data: 'filled'
	},
];

let smeltersPrePostQCFields = [
	{
		header: 'ID',
		data: 'id'
	},
	{
		header: 'Site',
		data: 'site_id'
	},
	{
		header: 'Capacity',
		data: 'capacity'
	},
	{
		header: 'Capture Date',
		data: 'captured'
	},
	{
		header: 'Sensor',
		data: 'sensor'
	},
	{
		header: 'State',
		data: 'state'
	},
	{
		header: 'Cloud Fraction',
		data: 'cloud_frac'
	},
	{
		header: 'Hotspots',
		data: 'hotspots'
	}
];

export {
	smelterFields,
	stockpileFields,
	smeltersPostQCFields,
	smeltersPrePostQCFields,
	smelterFieldsTechDetail,
	smelterFieldsTechDetailNoAuto,
	smelterFieldsUploadAutoQC,
	smelterFieldsUploadManualQC
};
