import React, { useContext, useEffect, useState, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import { pipelineContext } from '../../../contexts/pipelineContext';
import useGainsQCApi from '../../../services/useGainsQCApi';
import {
	smelterFieldsTechDetailNoAuto,
	smelterFieldsUploadAutoQC,
	smelterFieldsUploadManualQC,
	smeltersPostQCFields
} from '../tableFields';
import CapturesTable from '../../CapturesTable';
import { ClipLoader } from 'react-spinners';
import Pako from 'pako';
import exportConfig from '../../../config';
import { Buffer } from 'buffer';
import PostQCDataTable from '../../PostQCDataTable';

const QCUpload = ({
	props,
	formValue,
	qcTimings,
	setQCTimings,
	setFormValue
}) => {
	// TEST CASES
	// Index calc
	// 		- index calc fails
	// Websocket
	// 		- no execution ID
	// 		- websocket can't connect
	// 		- websocket error
	//		- websocket timeout
	// Fetch Post QC Data
	//		- response not OK
	// 		- data not OK
	//		- problem fetching
	// 		- no url / no data
	// Upload

	// captures
	const [manCapsUpload, setManCapsUpload] = useState([]);
	const [capsToSendBack, setCapsToSendBack] = useState([]);

	// toggles / user options
	const [manCapsCollapsed, setManCapsCollapsed] = useState(true);
	const [autoCapsCollapsed, setAutoCapsCollapsed] = useState(false);
	const [isToastVisible, setIsToastVisible] = useState(false);
	const [toggleFill, setToggleFill] = useState(true);
	const [overwriteOnPostQC, setOverwriteOnPostQC] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	// tables data
	const [postQCData, setPostQCData] = useState();
	const [postQCDataFiltered, setPostQCDataFiltered] = useState();
	const [tablesData, setTablesData] = useState(null);
	const [totalPages, setTotalPages] = useState(null);
	const itemsPerPage = 7;
	const [paginatedData, setPaginatedData] = useState(null);
	const [uniqueSites, setUniqueSites] = useState(0);
	const [noPostQCData, setNoPostQCData] = useState(false);

	// statuses
	// index calc, websocket, fetch data, upload
	const [postQCPerformed, setPostQCPerformed] = useState(false);
	const [uploaded, setUploaded] = useState(false);
	const [uploadLoading, setUploadLoading] = useState(false);
	const [postQCResponse, setPostQCResponse] = useState([]);
	const [uploadResponse, setUploadResponse] = useState([]);
	const [receivedWSMsjs, setReceivedWSMsjs] = useState([]);

	const indexCalcStatusRef = useRef(0);
	const websocketStatusRef = useRef(0);
	const fetchWSDataStatusRef = useRef(0);
	const postQCStatusRef = useRef(0);
	const uploadStatusRef = useRef(0);

	const [postQCAttempts, setPostQCAttempts] = useState(0);
	const [uploadAttempts, setUploadAttempts] = useState(0);

	const statuses = {
		0: 'NOT STARTED',
		1: 'LOADING',
		2: 'COMPLETED',
		3: 'ERROR'
	};

	const [toastContent, setToastContent] = useState([]);
	const toastIdRef = useRef(null);
	const [executionID, setExecutionID] = useState(null);
	const [socketUrl, setSocketUrl] = useState(null);

	const { makeRequest: sendCapsBack } = useGainsQCApi();
	const runIndexCalc = useGainsQCApi();
	const uploadToDB = useGainsQCApi();

	const pipelineContextQCUpload = useContext(pipelineContext);

	const reconnectAttempts = useRef(0);
	const ws = useRef(null);
	const isUnmounted = useRef(false);

	useEffect(() => {
		const statuses = [
			indexCalcStatusRef.current,
			websocketStatusRef.current,
			fetchWSDataStatusRef.current
		];

		if (statuses.every((status) => status === 2)) {
			postQCStatusRef.current = 2;
		} else if (statuses.some((status) => status === 1)) {
			postQCStatusRef.current = 1;
		} else if (statuses.some((status) => status === 3)) {
			postQCStatusRef.current = 3;
		}
	}, [
		indexCalcStatusRef.current,
		websocketStatusRef.current,
		fetchWSDataStatusRef.current
	]);

	const statusIcons = {
		0: <i className="ps-2 fa-regular fa-circle" title="Not started"></i>,
		1: <i className="ps-2 fa-solid fa-spinner" title="Loading ..."></i>,
		2: <i className="ps-2 fa-regular fa-circle-check" title="Completed"></i>,
		3: (
			<i
				className="ps-2 fa-solid fa-circle-exclamation"
				title="Error in process"
			></i>
		),
		default: <i className="ps-2 fa-regular fa-circle-question"></i>
	};

	const StatusIcon = ({ status }) => statusIcons[status] || statusIcons.default;

	const copyPostQCLogToClipboard = async () => {
		let processedData = toastContent.map((row) =>
			row.map((cell, index) =>
				index === 1 && typeof cell === 'object' && cell?.props?.children
					? Array.isArray(cell.props.children)
						? cell.props.children
								.filter(
									(child) =>
										typeof child === 'string' || typeof child === 'number'
								)
								.join(' ')
						: typeof cell.props.children === 'string' ||
						  typeof cell.props.children === 'number'
						? cell.props.children
						: ''
					: cell
			)
		);

		const csvContent = processedData.map((row) => row.join(',')).join('\n');

		try {
			await navigator.clipboard.writeText(csvContent);
			alert('Copied to clipboard!');
		} catch (err) {
			console.error('Failed to copy: ', err);
			alert('Failed to copy text');
		}
	};

	const toastContentDiv = (toastContent) => {
		return (
			<div style={{ width: '100%' }}>
				<div className="row pb-2">
					<div className="col-10 d-flex justify-content-center">
						<h5>Post QC Log Details</h5>
					</div>
					<div className="col-1 d-flex justify-content-center">
						<i
							onClick={copyPostQCLogToClipboard}
							title="Copy log details to clipboard"
							className="fa-regular fa-copy"
						></i>
					</div>
					<div className="col-1 d-flex justify-content-center">
						<i
							onClick={toggleToast}
							title="Close log"
							className="fa-solid fa-xmark"
						></i>
					</div>
				</div>

				<div className="row pb-3 d-flex justify-content-center">
					<div className="col d-flex justify-content-center align-items-center">
						Index calc <StatusIcon status={indexCalcStatusRef.current} />
					</div>
					<div className="col d-flex justify-content-center align-items-center">
						Post QC Data <StatusIcon status={fetchWSDataStatusRef.current} />
					</div>
					<div className="col d-flex justify-content-center align-items-center">
						Upload <StatusIcon status={uploadStatusRef.current} />
					</div>
					<div className="col d-flex justify-content-center align-items-center">
						WS <i className="ps-2 fa-solid fa-plug"></i>{' '}
						{websocketStatusRef.current === 1 ? (
							<i
								title="Websocket open"
								className="ps-2 fa-solid fa-spinner"
							></i>
						) : (
							<i
								title="Websocket closed"
								className="ps-2 fa-solid fa-circle-minus"
							></i>
						)}
					</div>
				</div>

				<div
					className="table"
					style={{ maxHeight: '250px', overflowY: 'scroll' }}
				>
					<table className="uploadToastNotificationsTable">
						<tbody>
							{toastContent.map((item, index) => (
								<tr key={index}>
									<td style={{ textAlign: 'top', paddingRight: '10px' }}>
										{item[0]}
									</td>
									<td
										title={
											typeof item[1] === 'object' &&
											Array.isArray(item[1]?.props?.children) &&
											item[1].props.children[0] === 'Execution ID:' &&
											`Execution ID: ${item[1].props.children[2].toString()}`
										}
										style={{ textAlign: 'top', paddingLeft: '10px' }}
									>
										{typeof item[1] === 'object' &&
										Array.isArray(item[1]?.props?.children) &&
										item[1].props.children[0] === 'Execution ID:'
											? `Execution ID: ${item[1].props.children[2]
													.toString()
													.slice(0, 35)}...`
											: item[1]}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (isToastVisible) {
			if (!toastIdRef.current) {
				toastIdRef.current = toast(toastContentDiv(toastContent), {
					duration: 300000,
					style: {
						minWidth: '600px',
						textAlign: 'left',
						background: '#333',
						color: '#fff'
					}
				});
			} else {
				toast(toastContentDiv(toastContent), {
					id: toastIdRef.current,
					duration: 300000,
					style: {
						minWidth: '600px',
						textAlign: 'left',
						background: '#333',
						color: '#fff'
					}
				});
			}
		} else {
			if (toastIdRef.current) {
				toast.dismiss(toastIdRef.current);
				toastIdRef.current = null;
			}
		}
	}, [isToastVisible, toastContent]);

	const toggleToast = () => {
		setIsToastVisible((prev) => !prev);
	};

	useEffect(
		() => {
			/**
			 * determine the caps to upload
			 * and caps to remove
			 */

			pipelineContextQCUpload.setQCInProgress(false);

			setManCapsUpload(
				pipelineContextQCUpload.comm === 'stockpiles'
					? pipelineContextQCUpload.capsInPipeline.filter(
							(cap) => cap['qc_state'] === 4
					  )
					: pipelineContextQCUpload.capsInPipeline.filter((cap) =>
							cap.hasOwnProperty('newQC')
					  )
			);

			setCapsToSendBack(
				pipelineContextQCUpload.comm === 'stockpiles'
					? pipelineContextQCUpload.capsInPipeline.filter(
							(cap) => cap['qc_state'] !== 4
					  )
					: pipelineContextQCUpload.capsInPipeline.filter(
							(cap) => cap.state === null
					  )
			);

			const uniqueSiteIds = [
				...new Set(
					pipelineContextQCUpload.capsInPipeline
						.filter((cap) => cap.state !== null)
						.map((item) => item.site_id)
				)
			];
			setUniqueSites(uniqueSiteIds.length);

			setQCTimings({
				...qcTimings,
				qcEnd: new Date()
			});

			// needs to depend on progressBar to retrigger this calc when moving to upload
		},
		[
			//pipelineContextQCUpload.capsInPipeline,
			//pipelineContextQCUpload.progressBar
		]
	);

	const runPostQCIndexCalc = async () => {
		/**
		 * perform index calculation
		 */

		if (indexCalcStatusRef.current === 2 || postQCAttempts >= 2) return;
		if (!isToastVisible) toggleToast();

		setPostQCAttempts((prev) => prev + 1);
		indexCalcStatusRef.current = 1;
		setToastContent((prevItems) => [
			...prevItems,
			[
				new Date().toLocaleTimeString('en-GB'),
				`QC upload starting: ${
					pipelineContextQCUpload.qcType
				} / ${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`
			]
		]);

		setToastContent((prevItems) => [
			...prevItems,
			[new Date().toLocaleTimeString('en-GB'), 'Index calc: starting ...']
		]);

		var siteIds = [];
		var indexCalcBody;

		if (!pipelineContextQCUpload.straightToUpload) {
			if (pipelineContextQCUpload.qcType === 'Daily') {
				indexCalcBody = {
					prod_states: formValue.formProdStates.join(),
					commodity_ids: formValue.formCommodities.join(),
					start_date: formValue.formStartDate,
					//end_date: formValue.formEndDate,
					overwrite: overwriteOnPostQC
				};
			}

			if (pipelineContextQCUpload.qcType === 'Historic') {
				indexCalcBody = {
					site_ids: formValue.formSiteId,
					commodity_ids: formValue.formCommodities.join(),
					start_date: formValue.formStartDate,
					//end_date: formValue.formEndDate,
					overwrite: overwriteOnPostQC
				};
			}
		} else {
			if (formValue.manuallySelectSites) {
				siteIds = formValue.formSiteId;

				indexCalcBody = {
					site_ids: siteIds,
					// prod_states: formValue.prod_states,
					// commodities: formValue.commodities,
					start_date: formValue.formStartDate,
					end_date: formValue.formEndDate,
					overwrite: overwriteOnPostQC
				};
			} else {
				indexCalcBody = {
					//site_ids: siteIds,
					prod_states: formValue.formProdStates.join(),
					commodity_ids: formValue.formCommodities.join(),
					start_date: formValue.formStartDate,
					//end_date: formValue.formEndDate,
					overwrite: overwriteOnPostQC
				};
			}
		}

		await runIndexCalc.makeRequest(
			`${config.baseUrl}/smelters/init-post-qc`,
			'POST',
			indexCalcBody,
			pipelineContextQCUpload.notificationsEnabled ? 'post qc' : null
		);
	};

	useEffect(() => {
		/**
		 * upon successful index calc, connect to websocket
		 */

		console.log(runIndexCalc.data);
		console.log(runIndexCalc.error);

		if (indexCalcStatusRef.current === 2) return;

		if (runIndexCalc.data && !runIndexCalc.error) {
			console.log('here in index calc use effect');

			setToastContent((prevItems) => [
				...prevItems,
				[
					new Date().toLocaleTimeString('en-GB'),
					<>Index calc: successfully posted</>
				]
			]);

			setToastContent((prevItems) => [
				...prevItems,
				[
					new Date().toLocaleTimeString('en-GB'),
					<>
						Execution ID:{' '}
						{runIndexCalc.data?.execution_id && runIndexCalc.data.execution_id}
					</>
				]
			]);

			indexCalcStatusRef.current = 2;

			setSocketUrl(runIndexCalc.data.response_url);
		} else if (runIndexCalc.error) {
			console.log('here in index calc use effect 2');
			// TO DO: handle index calc error
			console.log(`Index Calc Error: ${runIndexCalc.error}`);
			console.log(runIndexCalc.error);
			indexCalcStatusRef.current = 3;
			setToastContent((prevItems) => [
				...prevItems,
				[
					new Date().toLocaleTimeString('en-GB'),
					<>
						<i className="fa-solid fa-triangle-exclamation pe-2"></i> Index
						calc: error
					</>
				]
			]);

			setToastContent((prevItems) => [
				...prevItems,
				[
					new Date().toLocaleTimeString('en-GB'),
					<>
						<i className="fa-solid fa-triangle-exclamation pe-2"></i> Index
						calc: {JSON.stringify(runIndexCalc.error)}
					</>
				]
			]);

			if (postQCResponse.length >= 2) {
				setToastContent((prevItems) => [
					...prevItems,
					[
						new Date().toLocaleTimeString('en-GB'),
						<>
							<i className="fa-solid fa-triangle-exclamation pe-2"></i>Index
							calc: failed twice. Please exit QC and report any errors to an
							admin.
						</>
					]
				]);
			}

			setPostQCResponse((prevList) => [
				...prevList,
				{
					user: `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`,
					time: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit'
					}),
					date: new Date().toLocaleDateString('en-GB'),
					error: runIndexCalc.error,
					failed_at: 'init-post-qc',
					execution_id: null
				}
			]);
		} else {
			//
		}
	}, [runIndexCalc.data, runIndexCalc.error]);

	useEffect(() => {
		if (!socketUrl || postQCStatusRef.current === 2) return;

		const params = new URLSearchParams({
			execution_id: runIndexCalc.data.execution_id
		});

		setExecutionID(runIndexCalc.data.execution_id);

		const maxReconnectAttempts = 5;
		const reconnectDelay = 2000;
		let reconnectAttempts2 = 0;

		const connectWebSocket = () => {
			if (isUnmounted.current || postQCStatusRef.current === 2) return;

			console.log(reconnectAttempts2);

			console.log('Attempting to connect WebSocket...');
			ws.current = new WebSocket(`${socketUrl}?${params.toString()}`);

			ws.current.onopen = () => {
				console.log('WebSocket connection opened');
				websocketStatusRef.current = 1;
				setToastContent((prevItems) => [
					...prevItems,
					[
						new Date().toLocaleTimeString('en-GB'),
						<>
							<i className="fa-solid fa-plug-circle-check pe-2"></i> WS opened
						</>
					]
				]);

				reconnectAttempts.current = 0;
			};

			ws.current.onmessage = (event) => {
				console.log('ws on message');
				let data = JSON.parse(JSON.parse(event.data));
				console.log(data);

				setReceivedWSMsjs((prevMessages) => [
					...prevMessages,
					{
						msg: data.message,
						time: event.timeStamp,
						url: data.hasOwnProperty('url') ? data.url : null
					}
				]);

				setToastContent((prevItems) => [
					...prevItems,
					[
						new Date().toLocaleTimeString('en-GB'),
						<>
							<i class="fa-solid fa-plug pe-2"></i> WS {data.message}
						</>
					]
				]);

				if (
					data.message === 'Process status is SUCCEEDED' &&
					data.hasOwnProperty('url') &&
					data.url !== null
				) {
					console.log('Process succeeded, fetching additional data...');

					setToastContent((prevItems) => [
						...prevItems,
						[new Date().toLocaleTimeString('en-GB'), `Index calc: completed`]
					]);

					setToastContent((prevItems) => [
						...prevItems,
						[
							new Date().toLocaleTimeString('en-GB'),
							`Post QC data: fetching ...`
						]
					]);

					fetch(data.url)
						.then((response) => {
							if (!response.ok) {
								console.log('if response not ok');
								console.log(response);
								setToastContent((prevItems) => [
									...prevItems,
									[
										new Date().toLocaleTimeString('en-GB'),
										<>
											<i className="fa-solid fa-triangle-exclamation pe-2"></i>{' '}
											Post QC data: error
										</>
									]
								]);

								setPostQCResponse((preList) => [
									...preList,
									{
										user: `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`,
										time: new Date().toLocaleTimeString([], {
											hour: '2-digit',
											minute: '2-digit',
											second: '2-digit'
										}),
										date: new Date().toLocaleDateString('en-GB'),
										error: 'error fetching data from index calc',
										failed_at: 'websocket error',
										execution_id: runIndexCalc.data.execution_id
									}
								]);
								fetchWSDataStatusRef.current = 3;
								reconnectAttempts.current = 5;
								ws.current?.close();

								throw new Error('Failed to fetch secondary data');
							}
							return response.json();
						})
						.then((secondaryData) => {
							console.log('Secondary Data:', secondaryData);
							setToastContent((prevItems) => [
								...prevItems,
								[
									new Date().toLocaleTimeString('en-GB'),
									`Post QC data: successfully fetched`
								]
							]);

							setPostQCData(secondaryData);
							indexCalcStatusRef.current = 2;
							websocketStatusRef.current = 2;
							fetchWSDataStatusRef.current = 2;
							//reconnectAttempts.current = 5;
							ws.current?.close();
						})
						.catch((error) => {
							console.error('Error fetching secondary data:', error);
							setToastContent((prevItems) => [
								...prevItems,
								[new Date().toLocaleTimeString('en-GB'), `Post QC data: error`]
							]);

							setToastContent((prevItems) => [
								...prevItems,
								[
									new Date().toLocaleTimeString('en-GB'),
									`Post QC data: ${
										typeof error === 'object' ? JSON.stringify(error) : error
									}`
								]
							]);

							fetchWSDataStatusRef.current = 3;
							setPostQCResponse((preList) => [
								...preList,
								{
									user: `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`,
									time: new Date().toLocaleTimeString([], {
										hour: '2-digit',
										minute: '2-digit'
									}),
									date: new Date().toLocaleDateString('en-GB'),
									error: 'error fetching data from index calc',
									failed_at: 'websocket error',
									execution_id: runIndexCalc.data.execution_id
								}
							]);
							reconnectAttempts.current = 5;
							ws.current?.close();
						});
				} else if (data.hasOwnProperty('url') && data.url === null) {
					console.log('no url');
					fetchWSDataStatusRef.current = 2;
					setUploaded(true);
					setPostQCPerformed(true);
					setNoPostQCData(true);
					setToastContent((prevItems) => [
						...prevItems,
						[
							new Date().toLocaleTimeString('en-GB'),
							`Post QC data: no data for this site`
						]
					]);
					reconnectAttempts.current = 5;
					ws.current?.close();
				} else if (data.message === 'Process status is FAILED') {
					fetchWSDataStatusRef.current = 3;
					setPostQCPerformed(false);
					setToastContent((prevItems) => [
						...prevItems,
						[
							new Date().toLocaleTimeString('en-GB'),
							`Post QC data: error (process failed)`
						]
					]);

					setPostQCResponse((preList) => [
						...preList,
						{
							user: `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`,
							time: new Date().toLocaleTimeString([], {
								hour: '2-digit',
								minute: '2-digit'
							}),
							date: new Date().toLocaleDateString('en-GB'),
							error: data.message,
							failed_at: 'websocket error',
							execution_id: runIndexCalc.data.execution_id
						}
					]);
					reconnectAttempts.current = 5;
					ws.current?.close();
					console.log('Process failed:', data.message);
				} else {
					console.log('WS no message no error');
					console.log(data.message);
				}
			};

			ws.current.onerror = (error) => {
				console.error('WebSocket error:', error);
				websocketStatusRef.current = 3;

				setToastContent((prevItems) => [
					...prevItems,
					[
						new Date(ws.current.timeStamp).toLocaleTimeString('en-GB'),
						<>
							<i class="fa-solid fa-plug-circle-exclamation pe-2"></i> WS error{' '}
							{error}
						</>
					]
				]);
			};

			ws.current.onclose = (event) => {
				console.log('WebSocket connection closed', event);

				if (fetchWSDataStatusRef.current === 2) {
					console.log('should be closing websocket and not reopeining');
					reconnectAttempts.current = 5;
					websocketStatusRef.current = 2;
					setToastContent((prevItems) => [
						...prevItems,
						[
							new Date().toLocaleTimeString('en-GB'),
							<>
								<i class="fa-solid fa-plug-circle-xmark pe-2"></i> WS process
								completed
							</>
						]
					]);

					setToastContent((prevItems) => [
						...prevItems,
						[new Date().toLocaleTimeString('en-GB'), `Ready for upload`]
					]);

					console.log(
						'Max reconnect attempts reached or process completed. Not retrying.'
					);
				} else {
					console.log('here in websocket');
					setToastContent((prevItems) => [
						...prevItems,
						[
							new Date().toLocaleTimeString('en-GB'),
							<>
								<i class="fa-solid fa-plug-circle-xmark pe-2"></i> WS timeout
							</>
						]
					]);

					if (reconnectAttempts.current < maxReconnectAttempts) {
						websocketStatusRef.current = 1;
						const delay =
							reconnectDelay * Math.pow(2, reconnectAttempts.current);
						console.log(`Reconnecting in ${delay / 1000} seconds...`);
						setToastContent((prevItems) => [
							...prevItems,
							[
								new Date().toLocaleTimeString('en-GB'),
								<>
									<i className="fa-solid fa-plug-circle-check pe-2"></i> WS
									reconnecting (attempt {reconnectAttempts.current}) ...
								</>
							]
						]);
						setTimeout(connectWebSocket, delay);
						reconnectAttempts2++;
						reconnectAttempts.current++;
					}
				}
			};
		};

		connectWebSocket();

		return () => {
			console.log('Cleaning up WebSocket connection');

			isUnmounted.current = true;
			ws.current?.close();
		};
	}, [socketUrl, indexCalcStatusRef.current]);

	const getPageNumbers = () => {
		// const pageNumbers = [];
		// const startPage = Math.max(1, currentPage - 1);
		// const endPage = Math.min(totalPages, currentPage + 1);

		// for (let i = startPage; i <= endPage; i++) {
		// 	pageNumbers.push(i);
		// }
		// return pageNumbers;

		const totalVisiblePages = 3; // Always show 3 page numbers
		const pageNumbers = [];
		let startPage, endPage;

		if (totalPages <= totalVisiblePages) {
			// If total pages are fewer than or equal to 3, show all pages
			startPage = 1;
			endPage = totalPages;
		} else if (currentPage === 1) {
			// If on the first page, show the first 3 pages
			startPage = 1;
			endPage = totalVisiblePages;
		} else if (currentPage === totalPages) {
			// If on the last page, show the last 3 pages
			startPage = totalPages - (totalVisiblePages - 1);
			endPage = totalPages;
		} else {
			// Otherwise, center the current page
			startPage = currentPage - 1;
			endPage = currentPage + 1;
		}

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}

		return pageNumbers;
	};

	useEffect(() => {
		if (postQCData) {
			console.log(postQCData);
			var postQCDataFiltered;

			if (formValue.formEndDate) {
				postQCDataFiltered = postQCData.filter(
					(cap) =>
						new Date(cap.captured) >= new Date(formValue.formStartDate) &&
						new Date(cap.captured) <= new Date(formValue.formEndDate)
				);
			} else {
				postQCDataFiltered = postQCData.filter(
					(cap) => new Date(cap.captured) >= new Date(formValue.formStartDate)
				);
			}
			console.log(postQCDataFiltered);
			setPostQCDataFiltered(postQCDataFiltered);

			const uniqueSiteIds = [
				...new Set(postQCDataFiltered.map((item) => item.site_id))
			];
			setUniqueSites(uniqueSiteIds.length);

			const capturesGroupedById = [];

			for (let id in uniqueSiteIds) {
				let captureForThisSiteId = postQCDataFiltered.filter(
					(obj) => obj.site_id === uniqueSiteIds[id]
				);

				if (toggleFill) {
					if (
						captureForThisSiteId.length > 0 &&
						captureForThisSiteId.filter((cap) => cap.filled !== 1).length > 0
					) {
						captureForThisSiteId = captureForThisSiteId.filter(
							(cap) => cap.filled !== 1
						);
					} else {
						let newObject = Object.fromEntries(
							Object.entries(captureForThisSiteId[0]).map(([key, value]) => [
								key,
								key === 'site_id' ? value : '-'
							])
						);
						captureForThisSiteId = [newObject];
					}
				}

				capturesGroupedById.push(captureForThisSiteId);
			}

			setToastContent((prevItems) => [
				...prevItems,
				[
					new Date().toLocaleTimeString('en-GB'),
					`Post QC data processed: Captures (${postQCDataFiltered.length}), Sites (${uniqueSiteIds.length})`
				]
			]);

			setTablesData(capturesGroupedById);
			pipelineContextQCUpload.setPostQCData(capturesGroupedById);
		}
	}, [postQCData, toggleFill]);

	useEffect(() => {
		if (postQCData && tablesData && indexCalcStatusRef.current === 2) {
			setTotalPages(Math.ceil(tablesData.length / itemsPerPage));

			setPaginatedData(
				tablesData.slice(
					(currentPage - 1) * itemsPerPage,
					currentPage * itemsPerPage
				)
			);
		}
	}, [tablesData, currentPage]);

	const runUploadToDB = async () => {
		if (uploadStatusRef.current === 2 || uploadAttempts >= 2) return;

		setUploadAttempts((prev) => prev + 1);
		uploadStatusRef.current = 1;

		setToastContent((prevItems) => [
			...prevItems,
			[new Date().toLocaleTimeString('en-GB'), `Upload: started ...`]
		]);
		setUploadLoading(true);

		let bbody = {
			data: postQCData,
			//lookback: formValue.formLookback
			start_date: formValue.formStartDate,
			end_date: formValue.formEndDate
		};

		console.log(bbody);

		let uploadBody;

		try {
			const jsonString = JSON.stringify(bbody);
			const compressedData = Pako.gzip(jsonString);
			console.log(compressedData);
			uploadBody = Buffer.from(compressedData).toString('base64');

			console.log(uploadBody);

			uploadToDB.makeRequest(
				`${exportConfig.baseUrl}/${pipelineContextQCUpload.comm}/upload`,
				'POST',
				uploadBody,
				pipelineContextQCUpload.notificationsEnabled
					? 'upload to database'
					: null
			);
		} catch (error) {
			console.error('Error compressing JSON:', error);
			uploadStatusRef.current = 3;

			setToastContent((prevItems) => [
				...prevItems,
				[
					new Date().toLocaleTimeString('en-GB'),
					<>
						<i className="fa-solid fa-triangle-exclamation pe-2"></i>Upload:
						error compressing post QC data for upload
					</>
				]
			]);

			setUploadResponse((prevList) => [
				...prevList,
				{
					user: `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`,
					time: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit'
					}),
					date: new Date().toLocaleDateString('en-GB'),
					error: 'Failed to compress post QC data',
					failed_at: 'upload'
				}
			]);
		}
	};

	useEffect(() => {
		console.log(uploadToDB.error);
		console.log(uploadToDB.data);

		if (!uploadToDB.error && uploadToDB.data) {
			pipelineContextQCUpload.setUploadPerformed(true);
			uploadStatusRef.current = 2;
			setUploadLoading(false);
			setUploaded(true);
			setToastContent((prevItems) => [
				...prevItems,
				[
					new Date().toLocaleTimeString('en-GB'),
					`Upload: succesfully uploaded to database`
				]
			]);
		}
		if (uploadToDB.error) {
			console.log(uploadToDB.error);
			setToastContent((prevItems) => [
				...prevItems,
				[
					new Date().toLocaleTimeString('en-GB'),
					<>
						<i className="fa-solid fa-triangle-exclamation pe-2"></i> Upload:
						failed
					</>
				]
			]);

			setToastContent((prevItems) => [
				...prevItems,
				[
					new Date().toLocaleTimeString('en-GB'),
					<>
						<i className="fa-solid fa-triangle-exclamation pe-2"></i> Upload:{' '}
						{uploadToDB.error?.message && uploadToDB.error.message} |{' '}
						{uploadToDB.error?.status && uploadToDB.error.status}
					</>
				]
			]);

			uploadStatusRef.current = 3;
			setUploadLoading(false);
			setUploadResponse((prevList) => [
				...prevList,
				{
					user: `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`,
					time: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit'
					}),
					date: new Date().toLocaleDateString('en-GB'),
					error: uploadToDB.error,
					failed_at: 'upload'
				}
			]);
		}
	}, [uploadToDB.data, uploadToDB.error]);

	const renderPostQCButton = () => {
		// postQCStatus 2  - disabled, post QC performed
		// postQCStatus 0 - enabled, perform post QC
		// postQCStatus 3 - error
		// postQCStatus 1 - loading

		console.log(postQCResponse.length);
		console.log(postQCStatusRef.current);

		if (
			indexCalcStatusRef.current === 0 ||
			(postQCStatusRef.current === 3 && postQCResponse.length < 2)
		) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButton"
						data-tooltip-id="performPostQCTT"
						onClick={(e) => runPostQCIndexCalc()}
					>
						Perform Post QC
					</button>

					<Tooltip id="performPostQCTTNotDone" place="bottom" effect="solid">
						Click to start the Post QC process
					</Tooltip>
				</>
			);
		} else if (
			websocketStatusRef.current === 1 ||
			indexCalcStatusRef.current === 1
		) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButtonDisabled"
						data-tooltip-id="performPostQCTTPerforming"
					>
						<span>Performing Post QC</span>
						<div className="animated-ellipsis ps-1">
							<span>.</span>
							<span>.</span>
						</div>
					</button>
					<Tooltip id="performPostQCTTPerforming" place="bottom" effect="solid">
						Performing index calculation ..
					</Tooltip>
				</>
			);
		} else if (postQCStatusRef.current === 2) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButtonDisabled"
						data-tooltip-id="performPostQCTTPerformed"
					>
						Performed Post QC <i className="ps-1 fa-solid fa-circle-check"></i>
					</button>
					<Tooltip id="performPostQCTTPerformed" place="bottom" effect="solid">
						Completed index calculation
					</Tooltip>
				</>
			);
		} else if (postQCStatusRef.current === 3 && postQCResponse.length >= 2) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButtonDisabled"
						data-tooltip-id="performPostQCTTError"
					>
						Error in Post QC{' '}
						<i className="ps-1 fa-solid fa-triangle-exclamation"></i>
					</button>
					<Tooltip id="performPostQCTTError" place="bottom" effect="solid">
						Error performing index calculation - see log
					</Tooltip>
				</>
			);
		} else {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="performPostQCTT"
					disabled
				>
					Post QC
				</button>
			);
		}
	};

	const renderUploadButton = () => {
		// postQCStatus 0 - disabled, perform post QC first
		// postQCStatus 1 - disabled, perform post QC first
		// postQCStatus 2  - enabled, perform upload
		// postQCStatus 3 - error

		if (postQCStatusRef.current !== 2 && uploadStatusRef.current === 0) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButtonDisabled"
						data-tooltip-id="performUploadTTICNotDone"
					>
						Upload to database
					</button>
					<Tooltip id="performUploadTTICNotDone" place="bottom" effect="solid">
						Complete previous steps before performing upload
					</Tooltip>
				</>
			);
		} else if (
			(postQCStatusRef.current === 2 && uploadStatusRef.current === 0) ||
			(uploadStatusRef.current === 3 && uploadAttempts < 2)
		) {
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButton"
						data-tooltip-id="performUploadUpload"
						onClick={(e) => runUploadToDB()}
					>
						<span>Upload to database</span>
					</button>
					<Tooltip id="performUploadUpload" place="bottom" effect="solid">
						Upload the performed calculation to the database
					</Tooltip>
				</>
			);
		} else if (uploadStatusRef.current === 1) {
			console.log('upload button 2');
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButtonDisabled"
						data-tooltip-id="performUploadUploading"
						disabled
						//onClick={(e) => runPostQCIndexCalc()}
					>
						<span>Uploading</span>
						<div className="animated-ellipsis ps-1">
							<span>.</span>
							<span>.</span>
						</div>
					</button>
					<Tooltip id="performUploadUploading" place="bottom" effect="solid">
						Uploading the performed calculation to the database
					</Tooltip>
				</>
			);
		} else if (uploadStatusRef.current === 2) {
			console.log('upload button 3');
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButtonDisabled"
						data-tooltip-id="performUploadUploaded"
					>
						Uploaded <i className="ps-1 fa-solid fa-circle-check"></i>
					</button>
					<Tooltip id="performUploadUploaded" place="bottom" effect="solid">
						Successfully uploaded to the database
					</Tooltip>
				</>
			);
		} else if (uploadStatusRef.current === 2 && noPostQCData) {
			console.log('upload button 4');
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButton"
						data-tooltip-id="uploadButtonNoData"
						disabled
					>
						<span>No data to upload</span>
					</button>
					<Tooltip id="uploadButtonNoData" place="bottom" effect="solid">
						There is no data to upload to the database. You can finish QC.
					</Tooltip>
				</>
			);
		} else if (uploadStatusRef.current === 3 && uploadAttempts >= 2) {
			console.log('upload button 5');
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButton"
						data-tooltip-id="uploadButtonNoData"
						disabled
					>
						Error performing upload{' '}
						<i className="ps-1 fa-solid fa-triangle-exclamation"></i>
					</button>
					<Tooltip id="uploadButtonNoData" place="bottom" effect="solid">
						Cannot upload at this time. You can exit QC.
					</Tooltip>
				</>
			);
		} else {
			console.log('upload button 6');
			return (
				<>
					<button
						style={{ width: '200px' }}
						className="btn interactButton"
						data-tooltip-id="uploadButtonNoData"
						disabled
					>
						<span>Upload to database</span>
					</button>
				</>
			);
		}
	};

	const renderFinishQCButton = () => {
		// uploaded || noPostQC data - enabled

		console.log(postQCStatusRef.current);
		console.log(postQCResponse.length);
		console.log(uploadStatusRef.current);

		if (
			postQCStatusRef.current === 0 ||
			(postQCStatusRef.current === 3 && postQCResponse.length < 2)
		) {
			return (
				<>
					<button
						className="btn interactButtonDisabled"
						data-tooltip-id="QCNotfinishedTT"
						style={{ width: '200px' }}
					>
						Finish QC
					</button>
					<Tooltip id="QCNotfinishedTT" place="bottom" effect="solid">
						Complete all steps of upload before completing QC
					</Tooltip>
				</>
			);
		} else if (uploadStatusRef.current === 2) {
			return (
				<>
					<button
						className="btn interactButton"
						data-tooltip-id="QCfinishedTT"
						style={{ width: '200px' }}
						onClick={(e) => finishQC()}
					>
						Finish QC
						<i className="ps-2 fa-solid fa-circle-check"></i>
					</button>
					<Tooltip id="QCfinishedTT" place="bottom" effect="solid">
						You can now finish QC and review the report
					</Tooltip>
				</>
			);
		} else if (
			(postQCStatusRef.current === 3 && postQCResponse.length >= 2) ||
			(uploadStatusRef.current === 3 && uploadResponse.length >= 2)
		) {
			return (
				<>
					<button
						className="btn interactButton"
						data-tooltip-id="QCfinishedTT"
						style={{ width: '200px' }}
						onClick={(e) => finishQCEarly()}
					>
						Finish QC
					</button>
					<Tooltip id="QCfinishedTT" place="bottom" effect="solid">
						You can now finish QC
					</Tooltip>
				</>
			);
		} else {
			return (
				<>
					<button
						className="btn interactButtonDisabled"
						data-tooltip-id="QCfinishedTT"
						style={{ width: '200px' }}
						disabled
					>
						Finish QC
					</button>
					<Tooltip id="QCfinishedTT" place="bottom" effect="solid">
						You can now finish QC
					</Tooltip>
				</>
			);
		}
	};

	const finishQC = async () => {
		if (capsToSendBack.length > 0) {
			await sendCapsBack(
				`${config.baseUrl}/${pipelineContextQCUpload.comm}/remove_from_queue`,
				'POST',
				{
					commodityIds: `${formValue.formCommodities.join(',')}`,
					qcUser: `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`,
					captureIds: capsToSendBack.map((cap) => cap.id).join(',')
				},
				pipelineContextQCUpload.notificationsEnabled
					? 'sending non-QCd caps back'
					: null
			);
		}

		// reset everything else here
		//pipelineContextQCUpload.setCapsInPipeline([]);
		pipelineContextQCUpload.setProgressBar({ width: 100, title: 'QC Report' });
		pipelineContextQCUpload.setPostQCPerformed(true);
		pipelineContextQCUpload.setQCInProgress(false);
	};

	const finishQCEarly = async () => {
		if (capsToSendBack.length > 0) {
			await sendCapsBack(
				`${config.baseUrl}/${pipelineContextQCUpload.comm}/remove_from_queue`,
				'POST',
				{
					commodityIds: `${formValue.formCommodities.join(',')}`,
					qcUser: `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`,
					captureIds: capsToSendBack.map((cap) => cap.id).join(',')
				},
				pipelineContextQCUpload.notificationsEnabled
					? 'sending non-QCd caps back'
					: null
			);
		}

		// reset everything else here
		pipelineContextQCUpload.resetState();
		pipelineContextQCUpload.setNavigateHome(true);
	};

	const handleBackButton = () => {
		pipelineContextQCUpload.setProgressBar({
			width: 3 * (100 / 7),
			title: 'Upload'
		});
	};

	const renderToggleFill = () => {
		return (
			<>
				<div className="form-check form-switch ms-3 mt-2">
					<input
						className="form-check-input"
						data-tooltip-id="toggleFillTT"
						type="checkbox"
						id="flexSwitchCheckDefault1"
						onChange={(e) => setToggleFill((prevState) => !prevState)}
					></input>
					<label className="form-check-label" for="flexSwitchCheckDefault1">
						View captures where <span className="fw-bold">fill = 1</span>
					</label>
				</div>{' '}
				<Tooltip id="toggleFillTT" place="bottom" effect="solid">
					Captures where fill=1 are hidden by default
				</Tooltip>
			</>
		);
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const renderPagination = () => {
		return (
			<>
				{uniqueSites > itemsPerPage && (
					<div className="d-flex flex-column">
						<div className="d-flex flex-column">
							<nav aria-label="Page navigation example">
								<ul className="pagination custom-pagination my-0 py-0">
									<li
										className={`page-item ${
											currentPage === 1 ? 'disabled' : ''
										}`}
									>
										<button
											className="page-link"
											onClick={() => handlePageChange(1)}
											disabled={currentPage === 1}
										>
											<i className="fa-solid fa-angles-left"></i>
										</button>
									</li>
									<li
										className={`page-item ${
											currentPage === 1 ? 'disabled' : ''
										}`}
									>
										<button
											className="page-link"
											onClick={() => handlePageChange(currentPage - 1)}
											disabled={currentPage === 1}
										>
											<i className="fa-solid fa-chevron-left"></i>
										</button>
									</li>
									{getPageNumbers().map((page) => (
										<li
											key={page}
											className={`page-item ${
												currentPage === page ? 'active' : ''
											}`}
										>
											<button
												className="page-link bigger"
												onClick={() => handlePageChange(page)}
											>
												{page}
											</button>
										</li>
									))}
									<li
										className={`page-item ${
											currentPage === totalPages ? 'disabled' : ''
										}`}
									>
										<button
											className="page-link"
											onClick={() => handlePageChange(currentPage + 1)}
											disabled={currentPage === totalPages}
										>
											<i className="fa-solid fa-chevron-right"></i>
										</button>
									</li>
									<li
										className={`page-item ${
											currentPage === totalPages ? 'disabled' : ''
										}`}
									>
										<button
											className="page-link"
											onClick={() => handlePageChange(totalPages)}
											disabled={currentPage === totalPages}
										>
											<i className="fa-solid fa-angles-right"></i>
										</button>
									</li>
								</ul>
							</nav>

							<div className="d-flex justify-content-center align-items-center pt-1">
								<input
									id="upload-page-current-page"
									className="form-control text-center me-2"
									style={{ width: '70px' }}
									type="number"
									min={1}
									max={totalPages}
									value={currentPage}
									onChange={(e) => {
										const value = e.target.value;
										if (value.trim() !== '' && value <= totalPages) {
											setCurrentPage(parseInt(value));
										}
									}}
								></input>
								<p className="text-center py-0 my-0">/{totalPages} pages</p>
							</div>
						</div>
					</div>
				)}
			</>
		);
	};

	console.log('Post-QC data:', postQCData);
	console.log('Post-QC performed status:', postQCPerformed);
	console.log('Socket URL:', socketUrl);
	console.log('Received WebSocket messages:', receivedWSMsjs);
	console.log('Upload loading state:', uploadLoading);
	console.log('Uploaded state:', uploaded);
	console.log('Post-QC response:', postQCResponse);

	console.log('Index calc status ref', statuses[indexCalcStatusRef.current]);
	console.log('Websocket status ref', statuses[websocketStatusRef.current]);
	console.log('Fetch WS data ref', statuses[fetchWSDataStatusRef.current]);
	console.log('Post QC data ref', statuses[postQCStatusRef.current]);
	console.log('Upload status ref', statuses[uploadStatusRef.current]);
	console.log('WS reconnect attempts', reconnectAttempts.current);
	console.log('Reconnect', reconnectAttempts);
	console.log(toastContent);

	console.log(runIndexCalc.error);

	return (
		<>
			<div style={{ padding: '10px' }}>
				<div className="row mb-2">
					<hr></hr>

					{/* BACK BUTTON */}
					{pipelineContextQCUpload.straightToUpload && (
						<div className="row">
							<div className="col d-flex justify-content-start align-items-center">
								<button
									className="btn interactButtonSecondary"
									onClick={handleBackButton}
									data-tooltip-id={
										postQCStatusRef.current === 2
											? 'backButtonDisabledTT'
											: 'backButtonNotDisabledTT'
									}
									style={{ width: '200px' }}
									disabled={postQCStatusRef.current === 2}
								>
									<i className="fa-solid fa-left-long pe-3"></i>
									Back
								</button>
							</div>

							<Tooltip id="backButtonDisabledTT" place="bottom" effect="solid">
								The index calculation has been performed for the chosen
								criteria, therefore you cannot go back to the form
							</Tooltip>

							<Tooltip
								id="backButtonNotDisabledTT"
								place="bottom"
								effect="solid"
							>
								Go back to the form and choose different upload criteria
							</Tooltip>

							<div className="col d-flex justify-content-end  align-items-center"></div>
						</div>
					)}

					{/* TITLE / DESCRIPT */}
					<div className="row mt-4">
						<div className="col-9">
							<h2>Upload Captures </h2>

							<h5>
								<i
									data-tooltip-id="uploadPageViewNotifications"
									onClick={toggleToast}
									disabled={toastContent.length === 0}
									className={
										isToastVisible
											? 'fa-solid fa-clipboard-list pe-2'
											: 'fa-solid fa-clipboard pe-2'
									}
								></i>
								{pipelineContextQCUpload.straightToUpload ? (
									<>Perform upload for the given criteria </>
								) : (
									<>Use this page to upload QC'd captures to the database</>
								)}
							</h5>

							<Tooltip
								id="uploadPageViewNotifications"
								place="bottom"
								effect="solid"
							>
								{isToastVisible ? <>Hide info log</> : <>View info log</>}
							</Tooltip>
						</div>
					</div>
				</div>

				{/* BUTTONS  */}
				<div className="row my-2">
					<table style={{ width: '70%', marginLeft: '15%' }}>
						<tbody>
							<tr>
								<td className="d-flex justify-content-center align-items-center p-0">
									{' '}
									<label className="label me-3" for="upload-change-lookback">
										Lookback
									</label>
									<input
										id="upload-change-lookback"
										className="form-control my-1 text-center p-0"
										type="number"
										style={{ width: '80px' }}
										onChange={(e) =>
											setFormValue({
												...formValue,
												formLookback: e.target.value
											})
										}
										min={-1}
										max={7}
										value={formValue.formLookback ? formValue.formLookback : 1}
										disabled={postQCStatusRef.current === 2}
									></input>
								</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td className="d-flex justify-content-center align-items-center pt-0">
									<div className="form-check form-switch ms-3 mt-2">
										<label
											className="form-check-label"
											for="flexSwitchCheckDefault2"
											data-tooltip-id="overwrite-post-QC"
										>
											Overwrite existing data
										</label>
										<input
											className="form-check-input"
											type="checkbox"
											id="flexSwitchCheckDefault2"
											onChange={() =>
												setOverwriteOnPostQC((prevState) => !prevState)
											}
											disabled={postQCStatusRef.current === 2}
										></input>
									</div>
								</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td className="text-center">{renderPostQCButton()}</td>
								<td className="text-center">
									{indexCalcStatusRef.current === 1 ||
									websocketStatusRef.current === 1 ||
									postQCStatusRef.current === 1 ? (
										<ClipLoader size={18} />
									) : (
										<i class="fa-solid fa-circle-arrow-right"></i>
									)}
								</td>
								<td className="text-center">{renderUploadButton()}</td>
								<td className="text-center">
									{uploadStatusRef.current === 1 ? (
										<ClipLoader size={18} />
									) : (
										<i class="fa-solid fa-circle-arrow-right"></i>
									)}
								</td>
								<td className="text-center">{renderFinishQCButton()}</td>
							</tr>

							<tr>
								<td className="text-center fw-bold">
									{(runIndexCalc.error || postQCStatusRef.current === 3) && (
										<i
											data-tooltip-id="indexCalcErrorTT"
											className="fa-solid fa-triangle-exclamation pe-2 text-danger"
										></i>
									)}{' '}
									Step 1
								</td>
								<td className="text-center"></td>
								<td className="text-center fw-bold">
									{uploadToDB.error && (
										<i
											data-tooltip-id="uploadErrorTT"
											className="fa-solid fa-triangle-exclamation pe-2 text-danger"
										></i>
									)}{' '}
									Step 2
								</td>
								<td className="text-center"></td>
								<td className="text-center fw-bold">Step 3</td>
							</tr>
						</tbody>
					</table>
				</div>

				{noPostQCData && (
					<div className="row px-2 mt-4">
						<h4 className="text-center">
							There was no data found upon performing Post QC.
							<br /> If you think this is wrong you can contact an admin with{' '}
							<strong>execution id : {executionID && executionID}</strong>.
							<br /> You can now exit QC.
						</h4>
					</div>
				)}

				{/* TABLES */}
				{!pipelineContextQCUpload.straightToUpload ? (
					<>
						<div className="row px-2">
							{/* <h5>Captures QC'd ({manCapsUpload.length})</h5> */}

							<div className="row mb-3">
								<div className="col d-flex align-items-center">
									{postQCStatusRef.current === 2 && renderToggleFill()}
								</div>
								<div className="col d-flex align-items-center justify-content-center">
									{postQCStatusRef.current === 2 && renderPagination()}
								</div>
								<div className="col d-flex justify-content-end  align-items-center">
									{(!noPostQCData || postQCStatusRef.current === 0) ? (
										<>
											Total captures: {manCapsUpload.length} / sites :{' '}
											{uniqueSites}
										</>
									) : postQCStatusRef.current === 2 && (
										<>
											Total captures:{' '}
											{postQCDataFiltered && postQCDataFiltered.length} / sites
											: {uniqueSites}
										</>
									)}

									
								</div>
							</div>

							{!runIndexCalc.loading && !postQCData && !socketUrl && (
								<>
									<div className="my-2">
										<h5>
											Manually QC'd captures ({manCapsUpload.length})
											<span>
												<i
													className={
														manCapsCollapsed
															? 'fa-solid fa-caret-up ms-2'
															: 'fa-solid fa-caret-down ms-2'
													}
													onClick={() => setManCapsCollapsed(!manCapsCollapsed)}
													href="#manualCapturesUploadTableCollapse"
													data-bs-target="#manCapsCollapsed"
													role="button"
													aria-expanded="false"
													aria-controls="manCapsCollapsed"
												></i>
											</span>
										</h5>

										<div
											className={`collapse ${manCapsCollapsed ? 'show' : ''}`}
											id="manualCapturesUploadTableCollapse"
										>
											<CapturesTable
												fields={smelterFieldsUploadManualQC}
												captures={manCapsUpload}
												qcCat="smelters"
												tableHeight="400px"
											/>
										</div>
									</div>

									<div className="my-2">
										<h5>
											Auto QC'd captures (
											{pipelineContextQCUpload.autoQCCaps.length})
											<span>
												<i
													className={
														autoCapsCollapsed
															? 'fa-solid fa-caret-up ms-2'
															: 'fa-solid fa-caret-down ms-2'
													}
													onClick={() =>
														setAutoCapsCollapsed(!autoCapsCollapsed)
													}
													href="#autoCapturesUploadTableCollapse"
													data-bs-target="#autoCapsCollapsed"
													role="button"
													aria-expanded="false"
													aria-controls="autoCapsCollapsed"
												></i>
											</span>
										</h5>

										<div
											className={`collapse ${autoCapsCollapsed ? 'show' : ''}`}
											id="autoCapturesUploadTableCollapse"
										>
											<CapturesTable
												fields={smelterFieldsUploadAutoQC}
												captures={pipelineContextQCUpload.autoQCCaps}
												qcCat="smelters"
												tableHeight="400px"
											/>
										</div>
									</div>
								</>
							)}

							<PostQCDataTable
								tablesData={paginatedData}
								loading={
									runIndexCalc.loading ||
									indexCalcStatusRef.current === 1 ||
									indexCalcStatusRef.current === 2 ||
									websocketStatusRef.current === 1 ||
									fetchWSDataStatusRef.current === 1
								}
								error={postQCResponse.length > 2}
							/>
						</div>
					</>
				) : (
					<>
						<div className="row px-2 mt-5">
							<div className="row mb-3">
								<div className="col d-flex align-items-center">
									{postQCStatusRef.current === 2 && renderToggleFill()}
								</div>

								<div className="col d-flex align-items-center justify-content-center">
									{postQCStatusRef.current === 2 && renderPagination()}
								</div>

								<div className="col d-flex align-items-center justify-content-end">
									{postQCStatusRef.current === 2 && (
										<>
											Total captures:{' '}
											{postQCDataFiltered && postQCDataFiltered.length} / sites
											: {uniqueSites}
										</>
									)}
								</div>
							</div>

							<div>
								{!runIndexCalc.loading && !postQCData && !socketUrl && (
									<>
										{formValue.manuallySelectSites ? (
											<div className="col-4 offset-4">
												<div
													className="table-responsive"
													style={{
														maxWidth: '100%',
														maxHeight: '500px'
													}}
												>
													<h5></h5>
													<table className="table">
														<tbody className="table-light">
															<tr>
																<td>Site ID (s)</td>
																<td>
																	<ul>
																		{formValue.formSiteId.map((item) => (
																			<li>{item}</li>
																		))}
																	</ul>
																</td>
															</tr>
															<tr>
																<td>Start Date</td>
																<td>2016-01-01</td>
															</tr>
															<tr>
																<td>End Date</td>
																<td>{formValue.formEndDate}</td>
															</tr>

															<tr>
																<td>Overwrite existing data</td>
																<td>
																	{overwriteOnPostQC ? <>yes</> : <>no</>}
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										) : (
											<div className="col-4 offset-4">
												<div
													className="table-responsive"
													style={{
														maxWidth: '100%',
														maxHeight: '500px'
													}}
												>
													<h5></h5>
													<table className="table">
														<tbody className="table-light">
															<tr>
																<td>Commodity (s)</td>
																<td>
																	<ul>
																		{formValue.formCommodities.map((item) => (
																			<li>
																				{
																					exportConfig.commodities.filter(
																						(comm) => comm.index === item
																					)[0].id
																				}
																			</li>
																		))}
																	</ul>
																</td>
															</tr>
															<tr>
																<td>Production State (s)</td>
																<td>
																	<ul>
																		{formValue.formProdStates.map(
																			(item, key) => (
																				<>
																					<li data-tooltip-id={`key${key}`}>
																						{item}
																					</li>
																					<Tooltip
																						id={`key${key}`}
																						place="bottom"
																						effect="solid"
																					>
																						{
																							exportConfig.production_states.filter(
																								(prod_state) =>
																									prod_state.state === item
																							)[0].descript
																						}
																					</Tooltip>
																				</>
																			)
																		)}
																	</ul>
																</td>
															</tr>

															<tr>
																<td>Lookback</td>
																<td>{formValue.formLookback}</td>
															</tr>

															<tr>
																<td>Start Date</td>
																<td>{formValue.formStartDate}</td>
															</tr>

															<tr>
																<td>End Date</td>
																<td>{formValue.formEndDate}</td>
															</tr>

															<tr>
																<td>Overwrite existing data</td>
																<td>
																	{overwriteOnPostQC ? <>yes</> : <>no</>}
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										)}
									</>
								)}

								<PostQCDataTable
									tablesData={paginatedData}
									loading={
										runIndexCalc.loading ||
										indexCalcStatusRef.current === 1 ||
										websocketStatusRef.current === 1 ||
										fetchWSDataStatusRef.current === 1
									}
									error={postQCResponse.length > 2}
								/>
							</div>
						</div>
					</>
				)}

				<Tooltip id="overwrite-post-QC" place="bottom" effect="solid">
					If overwrite is true, the index value on captures already QC'd will be
					updated and overwritten
				</Tooltip>

				<Tooltip id="indexCalcErrorTT" place="bottom" effect="solid">
					<i className="fa-solid fa-triangle-exclamation pe-2"></i> There has
					been an error performing the Index Calculation.
					<br />
					Attempts: {postQCResponse.length} <br />
					{postQCResponse.length < 2 && (
						<>Please try again, or Exit QC and try again later.</>
					)}
					<br />
					{postQCResponse.length >= 2 && (
						<>Please Exit QC and try again later.</>
					)}
					<br />
					You can show this error to the admin.
					<br /> <br />
					{postQCResponse.length > 0 &&
						postQCResponse[postQCResponse.length - 1].hasOwnProperty(
							'user'
						) && (
							<>User: {postQCResponse[postQCResponse.length - 1]['user']}</>
						)}{' '}
					<br />
					{postQCResponse.length > 0 &&
						postQCResponse[postQCResponse.length - 1].hasOwnProperty(
							'time'
						) && (
							<>Time: {postQCResponse[postQCResponse.length - 1]['time']}</>
						)}{' '}
					<br />
					{postQCResponse.length > 0 &&
						postQCResponse[postQCResponse.length - 1].hasOwnProperty(
							'date'
						) && (
							<>Date: {postQCResponse[postQCResponse.length - 1]['date']}</>
						)}{' '}
					<br />
					{postQCResponse.length > 0 &&
						postQCResponse[postQCResponse.length - 1].hasOwnProperty(
							'failed_at'
						) && (
							<>
								Failed at:{' '}
								{postQCResponse[postQCResponse.length - 1]['failed_at']}
							</>
						)}{' '}
					<br />
					{postQCResponse.length > 0 &&
						postQCResponse[postQCResponse.length - 1].hasOwnProperty('error') &&
						postQCResponse[postQCResponse.length - 1].error.hasOwnProperty(
							'message'
						) && (
							<>
								Error:{' '}
								{postQCResponse[postQCResponse.length - 1]['error']['message']}{' '}
								<br />
								Status:{' '}
								{postQCResponse[postQCResponse.length - 1]['error']['status']}
							</>
						)}{' '}
					<br />
					{postQCResponse.length > 0 &&
						postQCResponse[postQCResponse.length - 1].hasOwnProperty(
							'execution_id'
						) && (
							<>
								Execution ID:{' '}
								{postQCResponse[postQCResponse.length - 1]['execution_id']}
							</>
						)}{' '}
					<br />
				</Tooltip>

				<Tooltip id="uploadErrorTT" place="bottom" effect="solid">
					<i className="fa-solid fa-triangle-exclamation pe-2"></i> There has
					been an error performing the Index Calculation.
					<br />
					Attempts: {uploadResponse.length} <br />
					{uploadResponse.length < 2 && (
						<>Please try again, or Exit QC and try again later.</>
					)}
					<br />
					{uploadResponse.length >= 2 && (
						<>Please Exit QC and try again later.</>
					)}
					<br />
					You can show this error to the admin.
					<br /> <br />
					{uploadResponse.length > 0 &&
						uploadResponse[uploadResponse.length - 1].hasOwnProperty(
							'user'
						) && (
							<>User: {uploadResponse[uploadResponse.length - 1]['user']}</>
						)}{' '}
					<br />
					{uploadResponse.length > 0 &&
						uploadResponse[uploadResponse.length - 1].hasOwnProperty(
							'time'
						) && (
							<>Time: {uploadResponse[uploadResponse.length - 1]['time']}</>
						)}{' '}
					<br />
					{uploadResponse.length > 0 &&
						uploadResponse[uploadResponse.length - 1].hasOwnProperty(
							'date'
						) && (
							<>Date: {uploadResponse[uploadResponse.length - 1]['date']}</>
						)}{' '}
					<br />
					{uploadResponse.length > 0 &&
						uploadResponse[uploadResponse.length - 1].hasOwnProperty(
							'failed_at'
						) && (
							<>
								Failed at:{' '}
								{uploadResponse[uploadResponse.length - 1]['failed_at']}
							</>
						)}{' '}
					<br />
					{uploadResponse.length > 0 &&
						uploadResponse[uploadResponse.length - 1].hasOwnProperty(
							'error'
						) && (
							<>
								Error:{' '}
								{uploadResponse[uploadResponse.length - 1]['error']['message']}{' '}
								<br />
								Status:{' '}
								{uploadResponse[uploadResponse.length - 1]['error']['status']}
							</>
						)}{' '}
					<br />
					{uploadResponse.length > 0 &&
						uploadResponse[uploadResponse.length - 1].hasOwnProperty(
							'execution_id'
						) && (
							<>
								Execution ID:{' '}
								{uploadResponse[uploadResponse.length - 1]['execution_id']}
							</>
						)}{' '}
					<br />
				</Tooltip>
			</div>
		</>
	);
};

export default QCUpload;
