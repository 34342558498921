import React, { useEffect } from 'react';
import StockpilesTableBasic from '../../StockpilesTableBasic';
import { useContext } from 'react';
import { pipelineContext } from '../../../contexts/pipelineContext';
import SmeltersTableBasic from '../../SmeltersTableBasic';
import { SkeletonTable } from '../../SkeletonTable';
import CapturesTable from '../../CapturesTable';
import { smelterFields, stockpileFields } from '../tableFields';
import PostQCDataTable from '../../PostQCDataTable';

const QCReport = ({ props, manualQCCaptures, qcInProgess, qcCat, qcTimings, setQCTimings }) => {
	const pipelineContextQCReport = useContext(pipelineContext);

	console.log(pipelineContextQCReport);

	return (
		<>
			<hr></hr>
			<div className=" ms-4">
				<h1>QC Finished</h1>
				<h5>See below information about the performed QC</h5>

				<div className="row d-flex justify-content-center mt-4">
					<div className="col-5 d-flex justify-content-center">
						<div className="table-responsive mt-5" style={{width: '100%'}}>
							<table className="captureTable">
								<thead className="thead-dark">
									<tr>
										<th colSpan={4}>QC Summary</th>
									</tr>
								</thead>
								
								<tbody>
									<tr>
										<td>Type</td>
										<td className="fw-bold">{pipelineContextQCReport.comm}</td>
										<td>Date</td>
										<td>{new Date().toLocaleString().slice(0, 10)}</td>
									</tr>

									<tr>
										<td>No. captures</td>
										<td>
											{pipelineContextQCReport.straightToUpload ? (
												<>upload only</>
											) : (
												pipelineContextQCReport.capsInPipeline.length
											)}
										</td>
										<td>Time</td>
										<td>{new Date().toLocaleString().slice(12, 17)}</td>
									</tr>

									<tr>
										<td>Captures QC'd</td>
										<td>
											{pipelineContextQCReport.straightToUpload ? (
												<>upload only</>
											) : (
												<>{pipelineContextQCReport.qcPerc}%</>
											)}
										</td>
										<td>User</td>
										<td>
											{props.authProps.userAttributes.givenName.toLowerCase()}.
											{props.authProps.userAttributes.familyName.toLowerCase()}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				{pipelineContextQCReport.postQCData === null && (
					<div className="row my-3">
						<h4 className="text-center">
							There was no data found upon performing Post QC.
							<br /> If you think this is wrong you can contact an admin.
							<br /> You can now exit QC.
						</h4>
					</div>
				)}

				{/* <div className="row my-3">
					<PostQCDataTable tablesData={pipelineContextQCReport.postQCData} />
				</div> */}

				{/* <SmeltersTableBasic
							props={props}
							// capturesToReset={pipelineContextQCReport.capsInPipeline.filter(
							// 	(cap) => cap.beenQCd === false
							// )}
							postQCPerformed={true}
							uploaded={true}
							uploadedToDev={true}
							uploadedToProd={true}
							allowJumpCapture={false}
							captures={pipelineContextQCReport.capsInPipeline}
							height={600}
						/> */}
			</div>
		</>
	);
};

export default QCReport;
