import { Tooltip } from 'react-tooltip';
import config from '../../../config';
import { useContext, useState, useEffect } from 'react';
import StockpilesTableBasic from '../../StockpilesTableBasic';
import { pipelineContext } from '../../../contexts/pipelineContext';

const QCProgressModal = ({ props, loading }) => {
	const {
		comm,
		capsInPipeline,
		qcPerc,
		setProgressBar,
		progressBar,
		qcInProgress,
		setQCInProgress,
		currentCaptureIndex,
		setCurrentCaptureIndex,
		autoQCCaps
	} = useContext(pipelineContext);

	const [capturesToReset, setCapturesToReset] = useState([]);
	const [capturesQCd, setCapturesQCd] = useState([]);
	const [finishQCButtonClicked, setFinishQCButtonClicked] = useState(false);

	useEffect(() => {
		setCapturesToReset(capsInPipeline.filter((cap) => cap.qc_state === 0));
		setCapturesQCd(capsInPipeline.filter((cap) => cap.qc_state !== 0));
	}, [currentCaptureIndex, progressBar, qcInProgress, loading]);

	const moveToUpload = () => {
		setQCInProgress(false);
		setProgressBar({
			width: 6 * (100 / 7),
			title: 'Upload Captures'
		});
	};

	if (comm === 'stockpiles') {
		return (
			<div
				class="modal modal-xl fade"
				id="exampleModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								QC Progress
							</h5>

							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div
							class="modal-body"
							style={{ maxHeight: '600px', overflowY: 'auto' }}
						>
							<div className="progress mb-4 mt-3" style={{ width: '100%' }}>
								<div
									className="progress-bar progress-bar-striped progress-bar-animated"
									role="progressbar"
									style={{ width: `${qcPerc}%` }}
									aria-valuenow={qcPerc}
									aria-valuemin="0"
									aria-valuemax="100"
								>
									{qcPerc}%
								</div>
							</div>

							<StockpilesTableBasic props={props} allowJumpCapture={true} />
						</div>
						<div class="modal-footer">
							{capturesQCd.length === capsInPipeline.length ? (
								<>
									<button
										type="button"
										class="btn interactButton"
										data-tooltip-id="qcProgressModalUpload"
										data-bs-dismiss="modal"
										onClick={(e) => moveToUpload()}
									>
										Finish QC{' '}
										<i className="ms-2 fa-regular fa-circle-check"></i>
									</button>
									<Tooltip
										id="qcProgressModalUpload"
										place="right"
										effect="solid"
									>
										Finish QCing and upload {capturesQCd.length} captures
									</Tooltip>
								</>
							) : capturesQCd.length > 0 &&
							  capturesQCd.length < capsInPipeline.length ? (
								<>
									<button
										type="button"
										class="btn interactButtonSecondary"
										data-tooltip-id="qcProgressModalUpload"
										data-bs-dismiss="modal"
										onClick={(e) => moveToUpload()}
									>
										Finish QC
									</button>
									<Tooltip
										id="qcProgressModalUpload"
										place="right"
										effect="solid"
									>
										Finish QCing and upload {capturesQCd.length} captures
									</Tooltip>
								</>
							) : (
								<>
									<button
										type="button"
										class="btn interactButtonSecondary"
										data-tooltip-id="qcProgressModalUpload"
										//onClick={(e) => moveToUpload()}
									>
										Finish QC
									</button>
									<Tooltip
										id="qcProgressModalUpload"
										place="right"
										effect="solid"
									>
										You have not QC'd any captures yet
									</Tooltip>
								</>
							)}

							<button
								type="button"
								class="btn interactButton"
								data-bs-dismiss="modal"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	} else if (comm === 'smelters') {
		return (
			<div
				class="modal modal-xl fade"
				id="exampleModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
				style={{ marginTop: '80px' }}
			>
				<div className="modal-dialog">
					<div className="modal-content" style={{ minHeight: '500px' }}>
						<div
							className="modal-header text-light"
							style={{ backgroundColor: 'rgb(48, 48, 48)' }}
						>
							<h4 className="modal-title" id="exampleModalLabel">
								QC Progress
							</h4>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={(e) => setFinishQCButtonClicked(false)}
							></button>
						</div>

						<div
							class="modal-body"
							style={{ maxHeight: '600px', overflowY: 'auto' }}
						>
							<div className="d-flex flex-row align-items-center justify-content-center">
								<div className="flashing-dot me-3"></div>{' '}
								<h5 className="py-0 my-0">QC in progress</h5>
							</div>

							<div className="progress mb-4 mt-3" style={{ width: '100%' }}>
								<div
									className="progress-bar progress-bar-striped progress-bar-animated"
									role="progressbar"
									style={{
										backgroundColor: 'rgba(80, 145, 39, 0.8)',
										width: qcPerc >= 0 && qcPerc < 1 ? '5%' : `${qcPerc}%`
									}}
									aria-valuenow={qcPerc}
									aria-valuemin="0"
									aria-valuemax="100"
								>
									{capturesQCd.length}/{capsInPipeline.length}
								</div>
							</div>

							{capturesQCd.length === 0 ? (
								<>
									<h5 className="mt-4">
										You have not QC'd any captures, but
										<span className="fw-bold">
											{' '}
											{capsInPipeline.length}
										</span>{' '}
										captures are assigned to you in the database.
									</h5>
									{autoQCCaps.length > 0 && (
										<h5 className="mt-4">
											<span className="fw-bold"> {autoQCCaps.length}</span>{' '}
											captures have already been Auto QC'd.
										</h5>
									)}
								</>
							) : (
								<div className="p-2">
									<h5 className="mt-2">
										{capturesQCd.length}/{capsInPipeline.length} captures have
										been QC'd.
									</h5>
									{autoQCCaps.length > 0 && (
										<h5 className="mt-4">
											<span className="fw-bold"> {autoQCCaps.length}</span>{' '}
											captures have already been Auto QC'd.
										</h5>
									)}

									<h5 className="mt-2">
										You can review the changes you have made to each capture.
									</h5>
								</div>
							)}

							<div className="table  mt-5 px-3">
								<table class="table">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Capture ID</th>
											<th scope="col">Site</th>
											<th scope="col">Sensor</th>
											<th scope="col">Capture Date</th>
											<th scope="col">Pre QC State</th>
											<th scope="col">Set State</th>
											<th scope="col">QC'd</th>
										</tr>
									</thead>
									<tbody>
										{capsInPipeline.map((capture, idx) => (
											<tr>
												{currentCaptureIndex === idx ? (
													<>
														<th
															scope="row"
															className="table-secondary text-success"
															data-tooltip-id="current-capture"
														>
															<i className="fa-solid fa-arrow-right me-1 text-success"></i>{' '}
															{idx + 1}
														</th>
														<td
															className="table-secondary"
															data-bs-dismiss="modal"
															data-tooltip-id="current-capture"

															//onClick={(e) => setCurrentCaptureIndex(idx)}
														>
															<span id="qcprogressmodal-select-new-cap">
																{capture.id}
															</span>
														</td>
														<td
															className="table-secondary"
															data-tooltip-id="current-capture"
														>
															{capture.site_name.length > 25
																? capture.site_name.substring(0, 25) + '...'
																: capture.site_name}
														</td>
														<td
															className="table-secondary"
															data-tooltip-id="current-capture"
														>
															{capture.sensor}
														</td>
														<td
															className="table-secondary"
															data-tooltip-id="current-capture"
														>
															{capture.captured.slice(0, 16)}
														</td>

														{capture.initial_state === 0 ? (
															<td
																className="table-secondary inactiveCap"
																data-tooltip-id="current-capture"
															>
																{
																	config.qcStates.filter(
																		(qstate) =>
																			qstate.stateInt === capture.initial_state
																	)[0].stateString
																}
															</td>
														) : capture.initial_state === -1 ? (
															<td
																className="table-secondary invalidCap"
																data-tooltip-id="current-capture"
															>
																{
																	config.qcStates.filter(
																		(qstate) =>
																			qstate.stateInt === capture.initial_state
																	)[0].stateString
																}
															</td>
														) : (
															<td
																className="table-secondary activeCap"
																data-tooltip-id="current-capture"
															>
																{
																	config.qcStates.filter(
																		(qstate) =>
																			qstate.stateInt === capture.initial_state
																	)[0].stateString
																}
															</td>
														)}

														{config.qcStates
															.map((obj) => obj.stateInt)
															.includes(capture.newQC) ? (
															<>
																{capture.newQC === 0 ? (
																	<td
																		className="table-secondary inactiveCap"
																		data-tooltip-id="current-capture"
																	>
																		{
																			config.qcStates.filter(
																				(qstate) =>
																					qstate.stateInt === capture.newQC
																			)[0].stateString
																		}
																	</td>
																) : capture.newQC === -1 ? (
																	<td
																		className="table-secondary invalidCap"
																		data-tooltip-id="current-capture"
																	>
																		{
																			config.qcStates.filter(
																				(qstate) =>
																					qstate.stateInt === capture.newQC
																			)[0].stateString
																		}
																	</td>
																) : (
																	<td
																		className="table-secondary activeCap"
																		data-tooltip-id="current-capture"
																	>
																		{
																			config.qcStates.filter(
																				(qstate) =>
																					qstate.stateInt === capture.newQC
																			)[0].stateString
																		}
																	</td>
																)}
															</>
														) : (
															<td
																className="table-secondary"
																data-tooltip-id="current-capture"
															></td>
														)}
														{config.qcStates
															.map((obj) => obj.stateInt)
															.includes(capture.newQC) ? (
															<td
																className="table-secondary"
																data-tooltip-id="current-capture"
															>
																<i class="fa-solid fa-circle-check"></i>
															</td>
														) : capture.hasOwnProperty('error') ? (
															<>
																<td
																	className="table-secondary"
																	data-tooltip-id="progress-modal-failed-qc"
																>
																	<i className="fa-solid fa-circle-exclamation text-warning"></i>
																</td>
															</>
														) : (
															<td className="table-secondary"></td>
														)}
													</>
												) : (
													<>
														<th scope="row">{idx + 1}</th>
														<td
															data-bs-dismiss="modal"
															onClick={(e) => setCurrentCaptureIndex(idx)}
														>
															<span
																style={{
																	textDecoration: 'underline',
																	cursor: 'pointer'
																}}
																id="qcprogressmodal-select-new-cap"
																data-tooltip-id="jump-to-capture"
															>
																{capture.id}
															</span>
														</td>
														<td>
															{capture.site_name.length > 25
																? capture.site_name.substring(0, 25) + '...'
																: capture.site_name}
														</td>

														<td>{capture.sensor}</td>
														<td>{capture.captured.slice(0, 16)}</td>
														{capture.initial_state === 0 ? (
															<td className="inactiveCap">
																{
																	config.qcStates.filter(
																		(qstate) =>
																			qstate.stateInt === capture.initial_state
																	)[0].stateString
																}
															</td>
														) : capture.initial_state === -1 ? (
															<td className="invalidCap">
																{
																	config.qcStates.filter(
																		(qstate) =>
																			qstate.stateInt === capture.initial_state
																	)[0].stateString
																}
															</td>
														) : (
															<td className="activeCap">
																{
																	config.qcStates.filter(
																		(qstate) =>
																			qstate.stateInt === capture.initial_state
																	)[0].stateString
																}
															</td>
														)}
														{config.qcStates
															.map((obj) => obj.stateInt)
															.includes(capture.newQC) ? (
															<>
																{capture.newQC === 0 ? (
																	<td className="inactiveCap">
																		{
																			config.qcStates.filter(
																				(qstate) =>
																					qstate.stateInt === capture.newQC
																			)[0].stateString
																		}
																	</td>
																) : capture.newQC === -1 ? (
																	<td className="invalidCap">
																		{
																			config.qcStates.filter(
																				(qstate) =>
																					qstate.stateInt === capture.newQC
																			)[0].stateString
																		}
																	</td>
																) : (
																	<td className="activeCap">
																		{
																			config.qcStates.filter(
																				(qstate) =>
																					qstate.stateInt === capture.newQC
																			)[0].stateString
																		}
																	</td>
																)}
															</>
														) : (
															<td></td>
														)}
														{config.qcStates
															.map((obj) => obj.stateInt)
															.includes(capture.newQC) ? (
															<td>
																<i class="fa-solid fa-circle-check"></i>
															</td>
														) : capture.hasOwnProperty('error') ? (
															<>
																<td data-tooltip-id="progress-modal-failed-qc">
																	<i className="fa-solid fa-circle-exclamation text-warning"></i>
																</td>
															</>
														) : (
															<td></td>
														)}
													</>
												)}
											</tr>
										))}
									</tbody>
								</table>
								<Tooltip id="jump-to-capture" place="right" effect="solid">
									Jump to this capture
								</Tooltip>
							</div>
						</div>

						<div className="modal-footer">
							<div>
								<div className="row">
									{finishQCButtonClicked && qcPerc !== 100 && (
										<p className="fw-bold text-end text-warning">
											Confirm that you are moving to upload without QCing all
											captures
										</p>
									)}
								</div>

								<div className="row">
									<div className="d-flex flex-row justify-content-end">
										{parseFloat(qcPerc) === 100 ? (
											<>
												<button
													type="button"
													class="btn interactButton"
													data-tooltip-id="qcProgressModalUpload"
													data-bs-dismiss="modal"
													onClick={(e) => moveToUpload()}
												>
													<>
														Finish QC{' '}
														<i className="ms-2 fa-regular fa-circle-check"></i>
													</>
												</button>
												<Tooltip
													id="qcProgressModalUpload"
													place="right"
													effect="solid"
												>
													Finish QCing and upload {capturesQCd.length} captures
												</Tooltip>
											</>
										) : parseFloat(qcPerc) > 0 && parseFloat(qcPerc) < 100 ? (
											<>
												<button
													type="button"
													class="btn interactButtonSecondary"
													data-tooltip-id="qcProgressModalUpload"
													data-bs-dismiss={
														finishQCButtonClicked ? 'modal' : null
													}
													onClick={
														!finishQCButtonClicked
															? (e) => setFinishQCButtonClicked(true)
															: (e) => moveToUpload()
													}
												>
													{!finishQCButtonClicked ? (
														<>
															Finish QC{' '}
															<i className="ms-2 fa-regular fa-circle-check"></i>
														</>
													) : (
														<>
															Confirm finish QC{' '}
															<i className="ms-2 fa-regular fa-circle-check"></i>
														</>
													)}
												</button>
												<Tooltip
													id="qcProgressModalUpload"
													place="right"
													effect="solid"
												>
													Finish QC and upload {capturesQCd.length} captures
												</Tooltip>
											</>
										) : parseFloat(qcPerc) === 0 ? (
											<>
												<button
													type="button"
													className="btn interactButtonDisabled"
													data-tooltip-id="qcProgressModalUpload"
													onClick={(e) => moveToUpload()}
													disabled
												>
													Finish QC
												</button>
												<Tooltip
													id="qcProgressModalUpload"
													place="right"
													effect="solid"
												>
													You have not QC'd any captures yet
												</Tooltip>
											</>
										) : (
											<>
												<>
													<button
														type="button"
														className="btn interactButtonDisabled"
														data-tooltip-id="qcProgressModalUpload"
														disabled
													>
														Finish QC
													</button>
													<Tooltip
														id="qcProgressModalUpload"
														place="right"
														effect="solid"
													></Tooltip>
												</>
											</>
										)}

										<button
											type="button"
											className="btn interactButton ms-3"
											data-bs-dismiss="modal"
											onClick={(e) => setFinishQCButtonClicked(false)}
										>
											Close
										</button>

										<Tooltip id="current-capture" place="right" effect="solid">
											This is the current capture
										</Tooltip>

										<Tooltip
											id="progress-modal-failed-qc"
											place="right"
											effect="solid"
										>
											This capture was not QC'd properly
										</Tooltip>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default QCProgressModal;
