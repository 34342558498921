import React, { useEffect, useContext, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rastercoords';
import 'leaflet-easybutton/src/easy-button.css';
import 'leaflet-easybutton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import { pipelineContext } from '../../../contexts/pipelineContext';

// Override Leaflet deprecated _flat method with the recommended isFlat method
L.Polyline._flat = L.LineUtil.isFlat;

export const QCImageComparisonSmelter = ({
	imageData,
	modalId,
	imageUrl,
	openModal,
	loading,
	imageType
}) => {
	const pipelineContextQCImage = useContext(pipelineContext);
	const mapRef = useRef(null);

	useEffect(() => {
		if (
			pipelineContextQCImage.comm === 'smelters' &&
			imageData &&
			imageData.data
		) {
			// create map
			if (!mapRef.current) {
				let modalIdName = `#${modalId}`;
				const mapContainerId = `map-${modalId}`;
				//var map;

				// AOI
				if (imageData.data.aoi) {
					let aoi = JSON.parse(imageData.data.aoi);
					var geojsonFeature = {
						type: 'Feature',
						properties: {},
						geometry: {
							type: 'Polygon',
							coordinates: aoi.coordinates[0]
						}
					};

					var aoiLayer = L.geoJSON(geojsonFeature);
					aoiLayer.setStyle({
						color: 'yellow',
						weight: 3,
						fillOpacity: 0,
						opacity: 0.5
					});
					var bounds = aoiLayer.getBounds();
				}

				// PERMS
				let perms = [];
				if (imageData.data.perm_regions) {
					try {
						let permsJson = JSON.parse(imageData.data.perm_regions);

						for (let i in permsJson.features) {
							let geojsonPerms = {
								type: 'Feature',
								properties: {},
								geometry: {
									type: 'Polygon',
									coordinates: permsJson.features[i].geometry.coordinates
								}
							};
							let permLayer = L.geoJSON(geojsonPerms);
							permLayer.setStyle({
								color: 'orange',
								weight: 1,
								fillOpacity: 0
							});
							perms.push(permLayer);
						}
					} catch (e) {
						console.log(e);
					}
				}

				// HOTSPOTS
				let hotspotsList = [];
				if (imageData.data.hotspots && modalId === 'id1') {
					let hotspotsJson = JSON.parse(imageData.data.hotspots);

					for (let i in hotspotsJson.features) {
						let geojsonPerms = {
							type: 'Feature',
							properties: {},
							geometry: {
								type: 'Polygon',
								coordinates: hotspotsJson.features[i].geometry.coordinates
							}
						};
						let hotspotsLayer = L.geoJSON(geojsonPerms);
						hotspotsLayer.setStyle({
							color: 'red',
							weight: 1,
							fillOpacity: 1
						});
						hotspotsList.push(hotspotsLayer);
					}
				}

				var map = L.map(mapContainerId, {
					zoomControl: false
					//maxBounds: bounds,
					//maxBoundsViscosity: 1.0
				});

				map.setMaxBounds(bounds);

				// add layers to map
				aoiLayer.addTo(map);
				if (imageData.data.perm_regions) {
					for (let i in perms) {
						perms[i].addTo(map);
					}
				}

				if (imageData.data.hotspots) {
					for (let i in hotspotsList) {
						hotspotsList[i].addTo(map);
					}
				}

				aoiLayer
					.bindTooltip('site aoi', {
						direction: 'top'
					})
					.openTooltip();

				///console.log(bounds)
				const recenterButton = L.easyButton('fa-crosshairs', function () {
					map.setView([0, 0], 13);
				}).addTo(map);

				const imgUrl = imageUrl;
				var imageBounds = [
					[imageData.data.bbox[1], imageData.data.bbox[0]],
					[imageData.data.bbox[3], imageData.data.bbox[2]]
				];

				// Define individual image overlays
				const trueColourImageOverlay = L.imageOverlay(
					imageData.data['true_color'],
					imageBounds
				);
				const ratioImageOverlay = L.imageOverlay(
					imageData.data['ratio'],
					imageBounds
				);
				const compositeImageOverlay = L.imageOverlay(
					imageData.data['comp'],
					imageBounds
				);

				// Group them as base layers (ensures only one is shown at a time)
				const baseLayers = {
					'True Color': trueColourImageOverlay,
					Ratio: ratioImageOverlay,
					Composite: compositeImageOverlay
				};

				if (imageType === 'trueColourImageOverlay') {
					trueColourImageOverlay.addTo(map)
				} else if (imageType === 'ratioImageOverlay') {
					ratioImageOverlay.addTo(map)
				} else {
					compositeImageOverlay.addTo(map)
				}

				// Add a tile layer (e.g., OpenStreetMap)
				var mapLayer = L.tileLayer(
					'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
					{
						lang: 'en',
						opacity: 0.2
					}
				).addTo(map);
				mapLayer.addTo(map);

				var permGroups = L.layerGroup(perms);
				var hotspotsGroup = L.layerGroup(hotspotsList);

				//map.setView([0, 0], 13);
				map.fitBounds(bounds, { padding: [0, 0] });
			}

			return () => {
				map.remove();
			};
		}
	}, [imageUrl, openModal, pipelineContextQCImage.comm, imageData, imageType]);

	return (
		<div className="QCImage-map-general-div-smelters">
			<div className="QCImage-map-absolute-div">
				<div
					style={{ height: '900px' }}
					className="QCImage-leaflet-map-smelters"
					id={`map-${modalId}`}
				></div>
			</div>
		</div>
	);
};
