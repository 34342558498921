import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ClipLoader from 'react-spinners/ClipLoader';
import { Tooltip } from 'react-tooltip';
import { timeAgo } from '../../helpers/usersCaptures';

export const DataLoaded = ({ userCount, loading, error, data, timeNow }) => {
	const [captureData, setCaptureData] = useState();
	const [sitesData, setSitesData] = useState();

	const sumNumbers = (obj) => {
		let sum = 0;
		for (let key in obj) {
			if (typeof obj[key] === 'number') {
				sum += obj[key];
			} else if (typeof obj[key] === 'object' && obj[key] !== null) {
				sum += sumNumbers(obj[key]);
			}
		}
		return sum;
	};

	useEffect(() => {
		if (data && !error) {
			setCaptureData(
				sumNumbers(data.availableCapsSmelters) +
					sumNumbers(data.availableCapsStockpiles)
			);
			setSitesData(
				data.smelterSitesWithCaptures.length +
					data.stockpilesSitesWithCaptures.length
			);
		}
	}, [data]);

	return (
		<>
			<div class="dashboard-card">
				<div
					class="dashboard-card-header"
					data-tooltip-id="dashboard-system-status"
				>
					<div className="row">
						<div className="col">
							<i className="fa-solid fa-database me-2"></i> Database Status
						</div>
						<div className="col d-flex justify-content-end">
							{error ? <small className="text-warning">error loading data</small> : <small>last updated: {timeNow}</small>}
							
						</div>
					</div>
				</div>
				<div class="dashboard-card-body">
					<table style={{ width: '40%' }}>
						<tbody>
							<tr>
								<td className="text-center">
									<i className="fa-solid fa-images me-2"></i>{' '}
								</td>
								<td>Captures</td>
								{loading ? (
									<td className="text-end">
										<Skeleton width={50} />
									</td>
								) : (
									<td className="text-end fw-bold">{captureData}</td>
								)}
							</tr>

							<tr>
								<td className="text-center">
									<i className="fa-solid fa-location-dot me-2"></i>
								</td>
								<td> Sites</td>
								{loading ? (
									<td className="text-end">
										<Skeleton width={50} />
									</td>
								) : (
									<td className="text-end fw-bold">{sitesData}</td>
								)}
							</tr>

							<tr>
								<td className="text-center">
									<i className="fa-solid fa-users me-2"></i>
								</td>
								<td> QC Users</td>
								{loading ? (
									<td className="text-end">
										<Skeleton width={50} />
									</td>
								) : (
									<td className="text-end fw-bold">{userCount}</td>
								)}
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			{/* 
			<Tooltip id="dashboard-system-status" place="bottom" effect="solid">
				Connecting to database
			</Tooltip> */}
		</>
	);
};
