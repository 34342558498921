let compareImagesData = [
	{
		id: 1048583,
		stac_item_id:
			'S2A_MSIL1C_20231011T162211_N0509_R040_T17SKV_20231011T195809-1578',
		captured: '2023-10-11 16:33:30'
	},
	{
		id: 1048529,
		stac_item_id:
			'S2B_MSIL1C_20231006T162049_N0509_R040_T17SKV_20231006T193531-1578',
		captured: '2023-10-06 16:33:32'
	},
	{
		id: 1048475,
		stac_item_id:
			'S2A_MSIL1C_20231001T162101_N0509_R040_T17SKV_20231001T195854-1578',
		captured: '2023-10-01 16:33:34'
	},
	{
		id: 1048412,
		stac_item_id:
			'S2B_MSIL1C_20230926T161939_N0509_R040_T17SKV_20230926T200037-1578',
		captured: '2023-09-26 16:33:35'
	},
	{
		id: 1048305,
		stac_item_id:
			'S2B_MSIL1C_20230916T161829_N0509_R040_T17SKV_20230916T195730-1578',
		captured: '2023-09-16 16:33:35'
	},
	{
		id: 1048243,
		stac_item_id:
			'S2A_MSIL1C_20230911T161841_N0509_R040_T17SKV_20230911T210819-1578',
		captured: '2023-09-11 16:33:37'
	},
	{
		id: 1048194,
		stac_item_id:
			'S2B_MSIL1C_20230906T161829_N0509_R040_T17SKV_20230906T194450-1578',
		captured: '2023-09-06 16:33:37'
	},
	{
		id: 1048140,
		stac_item_id:
			'S2A_MSIL1C_20230901T161831_N0509_R040_T17SKV_20230901T212804-1578',
		captured: '2023-09-01 16:33:37'
	},
	{
		id: 1048087,
		stac_item_id:
			'S2B_MSIL1C_20230827T161829_N0509_R040_T17SKV_20230827T194453-1578',
		captured: '2023-08-27 16:33:37'
	},
	{
		id: 1048033,
		stac_item_id:
			'S2A_MSIL1C_20230822T161831_N0509_R040_T16SGE_20230822T210754-1578',
		captured: '2023-08-22 16:33:40'
	},
	{
		id: 1047980,
		stac_item_id:
			'S2B_MSIL1C_20230817T161829_N0509_R040_T17SKV_20230817T175658-1578',
		captured: '2023-08-17 16:33:38'
	},
	{
		id: 1047925,
		stac_item_id:
			'S2A_MSIL1C_20230812T161901_N0509_R040_T17SKV_20230812T212442-1578',
		captured: '2023-08-12 16:33:37'
	},
	{
		id: 1047809,
		stac_item_id:
			'S2A_MSIL1C_20230802T161831_N0509_R040_T17SKV_20230802T212412-1578',
		captured: '2023-08-02 16:33:37'
	},
	{
		id: 1047755,
		stac_item_id:
			'S2B_MSIL1C_20230728T161839_N0509_R040_T17SKV_20230728T182049-1578',
		captured: '2023-07-28 16:33:38'
	},
	{
		id: 1047701,
		stac_item_id:
			'S2A_MSIL1C_20230723T161831_N0509_R040_T17SKV_20230723T212242-1578',
		captured: '2023-07-23 16:33:37'
	},
	{
		id: 1047647,
		stac_item_id:
			'S2B_MSIL1C_20230718T161839_N0509_R040_T17SKV_20230719T131422-1578',
		captured: '2023-07-18 16:33:38'
	},
	{
		id: 1047593,
		stac_item_id:
			'S2A_MSIL1C_20230713T161901_N0509_R040_T17SKV_20230713T214027-1578',
		captured: '2023-07-13 16:33:37'
	},
	{
		id: 1047532,
		stac_item_id:
			'S2B_MSIL1C_20230708T161829_N0509_R040_T17SKV_20230708T181955-1578',
		captured: '2023-07-08 16:33:35'
	},
	{
		id: 1047480,
		stac_item_id:
			'S2A_MSIL1C_20230703T161831_N0509_R040_T17SKV_20230703T212420-1578',
		captured: '2023-07-03 16:33:37'
	},
	{
		id: 1047403,
		stac_item_id:
			'S2B_MSIL1C_20230628T161829_N0509_R040_T17SKV_20230628T182017-1578',
		captured: '2023-06-28 16:33:37'
	},
	{
		id: 1047342,
		stac_item_id:
			'S2A_MSIL1C_20230623T161831_N0509_R040_T17SKV_20230623T212427-1578',
		captured: '2023-06-23 16:33:36'
	},
	{
		id: 1047290,
		stac_item_id:
			'S2B_MSIL1C_20230618T161829_N0509_R040_T17SKV_20230618T181907-1578',
		captured: '2023-06-18 16:33:37'
	},
	{
		id: 1047229,
		stac_item_id:
			'S2A_MSIL1C_20230613T161831_N0509_R040_T17SKV_20230613T212429-1578',
		captured: '2023-06-13 16:33:36'
	},
	{
		id: 1047175,
		stac_item_id:
			'S2B_MSIL1C_20230608T161829_N0509_R040_T17SKV_20230608T181939-1578',
		captured: '2023-06-08 16:33:37'
	},
	{
		id: 1047120,
		stac_item_id:
			'S2A_MSIL1C_20230603T161831_N0509_R040_T17SKV_20230603T212338-1578',
		captured: '2023-06-03 16:33:36'
	},
	{
		id: 1047057,
		stac_item_id:
			'S2B_MSIL1C_20230529T161829_N0509_R040_T17SKV_20230529T182127-1578',
		captured: '2023-05-29 16:33:36'
	},
	{
		id: 1047012,
		stac_item_id:
			'S2A_MSIL1C_20230524T161831_N0509_R040_T17SKV_20230524T213754-1578',
		captured: '2023-05-24 16:33:35'
	},
	{
		id: 1046958,
		stac_item_id:
			'S2B_MSIL1C_20230519T161829_N0509_R040_T17SKV_20230519T182129-1578',
		captured: '2023-05-19 16:33:37'
	},
	{
		id: 1046895,
		stac_item_id:
			'S2A_MSIL1C_20230514T161831_N0509_R040_T17SKV_20230514T212416-1578',
		captured: '2023-05-14 16:33:33'
	},
	{
		id: 1046842,
		stac_item_id:
			'S2B_MSIL1C_20230509T161829_N0509_R040_T17SKV_20230509T182230-1578',
		captured: '2023-05-09 16:33:35'
	},
	{
		id: 1046780,
		stac_item_id:
			'S2A_MSIL1C_20230504T161831_N0509_R040_T17SKV_20230504T200429-1578',
		captured: '2023-05-04 16:33:32'
	},
	{
		id: 1046736,
		stac_item_id:
			'S2B_MSIL1C_20230429T161829_N0509_R040_T17SKV_20230429T195851-1578',
		captured: '2023-04-29 16:33:34'
	},
	{
		id: 1046675,
		stac_item_id:
			'S2A_MSIL1C_20230424T161831_N0509_R040_T17SKV_20230424T200518-1578',
		captured: '2023-04-24 16:33:32'
	},
	{
		id: 1046613,
		stac_item_id:
			'S2B_MSIL1C_20230419T161829_N0509_R040_T17SKV_20230419T182312-1578',
		captured: '2023-04-19 16:33:34'
	},
	{
		id: 1046568,
		stac_item_id:
			'S2A_MSIL1C_20230414T161831_N0509_R040_T17SKV_20230414T213739-1578',
		captured: '2023-04-14 16:33:32'
	},
	{
		id: 1048583,
		stac_item_id:
			'S2A_MSIL1C_20231011T162211_N0509_R040_T17SKV_20231011T195809-1578',
		captured: '2023-10-11 16:33:30'
	},
	{
		id: 1048647,
		stac_item_id:
			'S2B_MSIL1C_20231016T162159_N0509_R040_T17SKV_20231016T182048-1578',
		captured: '2023-10-16 16:33:34'
	},
	{
		id: 1048699,
		stac_item_id:
			'S2A_MSIL1C_20231021T162321_N0509_R040_T17SKV_20231021T195830-1578',
		captured: '2023-10-21 16:33:32'
	},
	{
		id: 1048761,
		stac_item_id:
			'S2B_MSIL1C_20231026T162349_N0509_R040_T17SKV_20231026T195823-1578',
		captured: '2023-10-26 16:33:31'
	},
	{
		id: 1048814,
		stac_item_id:
			'S2A_MSIL1C_20231031T162421_N0509_R040_T17SKV_20231031T195817-1578',
		captured: '2023-10-31 16:33:31'
	},
	{
		id: 1048859,
		stac_item_id:
			'S2B_MSIL1C_20231105T162449_N0509_R040_T17SKV_20231105T181938-1578',
		captured: '2023-11-05 16:33:35'
	},
	{
		id: 1048923,
		stac_item_id:
			'S2A_MSIL1C_20231110T162511_N0509_R040_T17SKV_20231110T182347-1578',
		captured: '2023-11-10 16:33:31'
	},
	{
		id: 1048976,
		stac_item_id:
			'S2B_MSIL1C_20231115T162539_N0509_R040_T17SKV_20231115T182026-1578',
		captured: '2023-11-15 16:33:32'
	},
	{
		id: 1049030,
		stac_item_id:
			'S2A_MSIL1C_20231120T162601_N0509_R040_T17SKV_20231120T182502-1578',
		captured: '2023-11-20 16:33:29'
	},
	{
		id: 1049092,
		stac_item_id:
			'S2B_MSIL1C_20231125T162609_N0509_R040_T17SKV_20231125T182030-1578',
		captured: '2023-11-25 16:33:28'
	},
	{
		id: 1049146,
		stac_item_id:
			'S2A_MSIL1C_20231130T162621_N0509_R040_T17SKV_20231130T182414-1578',
		captured: '2023-11-30 16:33:23'
	},
	{
		id: 1049209,
		stac_item_id:
			'S2B_MSIL1C_20231205T162639_N0509_R040_T17SKV_20231205T182033-1578',
		captured: '2023-12-05 16:33:29'
	},
	{
		id: 1049272,
		stac_item_id:
			'S2A_MSIL1C_20231210T162651_N0509_R040_T17SKV_20231210T181806-1578',
		captured: '2023-12-10 16:33:27'
	},
	{
		id: 1049316,
		stac_item_id:
			'S2B_MSIL1C_20231215T162659_N0510_R040_T17SKV_20231215T181958-1578',
		captured: '2023-12-15 16:33:31'
	},
	{
		id: 1049367,
		stac_item_id:
			'S2A_MSIL1C_20231220T162701_N0510_R040_T17SKV_20231220T181810-1578',
		captured: '2023-12-20 16:33:28'
	},
	{
		id: 1049420,
		stac_item_id:
			'S2B_MSIL1C_20231225T162709_N0510_R040_T17SKV_20231225T181832-1578',
		captured: '2023-12-25 16:33:34'
	},
	{
		id: 1049465,
		stac_item_id:
			'S2A_MSIL1C_20231230T162701_N0510_R040_T17SKV_20231230T182159-1578',
		captured: '2023-12-30 16:33:29'
	},
	{
		id: 1049519,
		stac_item_id:
			'S2B_MSIL1C_20240104T162659_N0510_R040_T17SKV_20240104T182041-1578',
		captured: '2024-01-04 16:33:32'
	},
	{
		id: 1049573,
		stac_item_id:
			'S2A_MSIL1C_20240109T162641_N0510_R040_T17SKV_20240109T182220-1578',
		captured: '2024-01-09 16:33:29'
	},
	{
		id: 1049625,
		stac_item_id:
			'S2B_MSIL1C_20240114T162629_N0510_R040_T17SKV_20240114T182137-1578',
		captured: '2024-01-14 16:33:31'
	},
	{
		id: 1049688,
		stac_item_id:
			'S2A_MSIL1C_20240119T162601_N0510_R040_T17SKV_20240119T182142-1578',
		captured: '2024-01-19 16:33:24'
	},
	{
		id: 1049738,
		stac_item_id:
			'S2B_MSIL1C_20240124T162549_N0510_R040_T17SKV_20240124T182047-1578',
		captured: '2024-01-24 16:33:31'
	},
	{
		id: 1049798,
		stac_item_id:
			'S2A_MSIL1C_20240129T162531_N0510_R040_T17SKV_20240129T181945-1578',
		captured: '2024-01-29 16:33:27'
	},
	{
		id: 1049862,
		stac_item_id:
			'S2B_MSIL1C_20240203T162459_N0510_R040_T17SKV_20240203T182156-1578',
		captured: '2024-02-03 16:33:30'
	},
	{
		id: 1049915,
		stac_item_id:
			'S2A_MSIL1C_20240208T162431_N0510_R040_T17SKV_20240208T195752-1578',
		captured: '2024-02-08 16:33:28'
	},
	{
		id: 1049970,
		stac_item_id:
			'S2B_MSIL1C_20240213T162409_N0510_R040_T17SKV_20240213T182411-1578',
		captured: '2024-02-13 16:33:33'
	},
	{
		id: 1050023,
		stac_item_id:
			'S2A_MSIL1C_20240218T162331_N0510_R040_T17SKV_20240218T182120-1578',
		captured: '2024-02-18 16:33:30'
	},
	{
		id: 1050059,
		stac_item_id:
			'S2B_MSIL1C_20240223T162159_N0510_R040_T17SKV_20240223T182255-1578',
		captured: '2024-02-23 16:33:32'
	},
	{
		id: 1050076,
		stac_item_id:
			'S2A_MSIL1C_20240228T162221_N0510_R040_T17SKV_20240228T184338-1578',
		captured: '2024-02-28 16:33:29'
	},
	{
		id: 1050085,
		stac_item_id:
			'S2B_MSIL1C_20240304T162049_N0510_R040_T17SKV_20240304T195814-1578',
		captured: '2024-03-04 16:33:28'
	},
	{
		id: 1050103,
		stac_item_id:
			'S2A_MSIL1C_20240309T162111_N0510_R040_T17SKV_20240309T195805-1578',
		captured: '2024-03-09 16:33:33'
	},
	{
		id: 1050121,
		stac_item_id:
			'S2B_MSIL1C_20240314T161939_N0510_R040_T17SKV_20240314T195737-1578',
		captured: '2024-03-14 16:33:30'
	},
	{
		id: 1050130,
		stac_item_id:
			'S2A_MSIL1C_20240319T162001_N0510_R040_T17SKV_20240319T200245-1578',
		captured: '2024-03-19 16:33:33'
	},
	{
		id: 1050148,
		stac_item_id:
			'S2B_MSIL1C_20240324T161829_N0510_R040_T17SKV_20240324T200059-1578',
		captured: '2024-03-24 16:33:33'
	},
	{
		id: 1050156,
		stac_item_id:
			'S2A_MSIL1C_20240329T161851_N0510_R040_T17SKV_20240329T201014-1578',
		captured: '2024-03-29 16:33:32'
	},
	{
		id: 1050174,
		stac_item_id:
			'S2B_MSIL1C_20240403T161829_N0510_R040_T17SKV_20240403T210951-1578',
		captured: '2024-04-03 16:33:30'
	},
	{
		id: 1050192,
		stac_item_id:
			'S2A_MSIL1C_20240408T161831_N0510_R040_T17SKV_20240408T201316-1578',
		captured: '2024-04-08 16:33:32'
	}
];

export default compareImagesData;
